import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';

import 'codemirror/mode/xml/xml';
import 'codemirror/addon/fold/xml-fold';

if (environment.name !== 'localDev') {
  Sentry.init({
    dsn: 'https://64039b31db4f47a4e4c9c1c485916af9@o4507076458381312.ingest.de.sentry.io/4507543030661200',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: environment.name,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      'dev.pulpo.tech',
      'staging.pulpo.tech',
      'pulpo.tech',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

import 'codemirror/mode/xml/xml';
import 'codemirror/addon/fold/xml-fold';

import 'codemirror/mode/xml/xml';
import 'codemirror/addon/fold/xml-fold';

import 'codemirror/mode/xml/xml';
import 'codemirror/addon/fold/xml-fold';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, { preserveWhitespaces: true })
  .then(() => console.log('Application started'))
  .catch((err) => console.error(err));
