import { Pipe, PipeTransform } from '@angular/core';
import { CountryService } from '@pulpo/pulpo-api';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'CountryPipe',
  standalone: true,
  pure: true,
})
export class CountryPipe implements PipeTransform {
  constructor(private countryService: CountryService) {}
  transform(id: number): Observable<string | undefined> {
    if (!id) return new Observable<string | undefined>();
    return this.countryService.findAllWithCache().pipe(
      map((res) => {
        const country = res.body?.find((c) => c.id === id);
        return country?.name;
      })
    );
  }
}
