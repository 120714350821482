import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { FormBuilder } from '@angular/forms';
import { ExportService } from '../../../../../src/lib/services/export.service';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { ILightBooking } from '@pulpo/pulpo-models';
import { BookingService, StorageService } from '@pulpo/pulpo-api';
import { BookingConsultFilterComponent } from '../booking-consult-filter/booking-consult-filter.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'booking-consult-list',
  templateUrl: './booking-consult-list.component.html',
  styleUrls: ['./booking-consult-list.component.scss'],
  standalone: true,
  imports: [
    BookingConsultFilterComponent,
    MatTableModule,
    MatCheckboxModule,
    DatePipe,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    CommonModule,
  ],
})
export class BookingConsultListComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'id',
    'status',
    'creationDate',
    'destination',
    'productReference',
    'product',
    'startCity',
    'departureDate',
    'nbNights',
    'categoryName',
    'firstPax',
    'total',
    'supplier',
    'seller',
    'distribution',
    'options',
    'consult',
  ];

  //List
  dataSource!: MatTableDataSource<ILightBooking>;
  @ViewChild(MatSort, { static: false })
  sort!: MatSort;
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  page!: number;
  filter: any = {};
  loading = true;
  nbBookings!: number;

  //Export
  canExport = false;
  allChecked = false;
  selectedBookings: any = {};

  @Input() showOpened = true;
  @Input() producerId?: number;
  @Input() itemsPerPage = 20;

  constructor(
    protected bookingService: BookingService,
    protected storageService: StorageService,
    protected formBuilder: FormBuilder,
    private exportService: ExportService
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.page = 0;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.refreshBookings();
  }

  private refreshBookings() {
    this.loading = true;
    const sort =
      this.sort && this.sort.active ? this.sort.active : 'creationDate';
    const order =
      this.sort && this.sort.direction ? this.sort.direction : 'desc';
    let filter = {
      page: this.page,
      size: this.itemsPerPage,
      sort: sort,
      direction: order,
      ...this.filter,
    };
    if (!this.showOpened) {
      filter = { ...filter, opened: false };
    }
    let subscription: any;
    if (this.producerId) {
      filter = {
        ...filter,
        producerCompanyId: this.producerId,
        supplierCompanyId: this.producerId,
      };
      subscription = this.bookingService.querySupplierPage(filter);
    } else subscription = this.bookingService.queryPage(filter);
    subscription.subscribe(
      (res: any) => {
        console.log(res.body.content);
        this.dataSource = new MatTableDataSource(res.body?.content);
        this.nbBookings = res.body?.totalElements;
        this.allChecked =
          this.dataSource.data.length > 0 &&
          this.dataSource.data.every(
            (booking) => this.selectedBookings[booking.id || '']
          );
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  toggleCheck(booking: any) {
    if (this.selectedBookings[booking.id]) {
      delete this.selectedBookings[booking.id];
    } else {
      this.selectedBookings[booking.id] = booking;
    }
    this.allChecked = this.dataSource.data.every(
      (booking) => this.selectedBookings[booking.id || '']
    );
    this.canExport = this.selectedBookings !== undefined;
  }

  checkAll() {
    this.dataSource.data.forEach((booking) => {
      if (this.allChecked) {
        delete this.selectedBookings[booking.id || ''];
      } else {
        this.selectedBookings[booking.id || ''] = booking;
      }
    });

    this.allChecked = !this.allChecked;
    this.canExport = this.selectedBookings !== undefined;
  }

  applyFilter(filter: any) {
    this.page = 0;
    this.filter = {};
    for (const key in filter) {
      if (filter[key]) {
        if (filter[key] instanceof Date) {
          console.log('date');
          this.filter[key] = dayjs(filter[key]).toISOString();
        } else if (dayjs.isDayjs(filter[key])) {
          this.filter[key] = dayjs(filter[key]).toISOString();
        } else {
          this.filter[key] = filter[key];
        }
      }
    }
    this.refreshBookings();
  }

  changePage() {
    this.page = this.paginator.pageIndex;
    this.refreshBookings();
  }

  sortChanged() {
    this.page = 0;
    this.paginator.pageIndex = 0;
    this.refreshBookings();
  }

  exportResa() {
    const blob = this.exportService.exportBookingsToExcel(
      this.storageService.getCompany().name || '',
      Object.keys(this.selectedBookings).map(
        (key) => this.selectedBookings[key]
      )
    );
    saveAs(blob, 'bookings_' + dayjs().format('DD-MM-YYYY') + '.xlsx');
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
