<table class="table table-striped">
  <thead>
    <tr>
      <th>Nom</th>
      <th>Informations</th>
      <th></th>
      <th>Date</th>
      <th>Statut</th>
      <th>ADT</th>
      <th>CHD</th>
      <th>BEBE</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{ insuranceRecord.insuranceName }}
      </td>
      <td>
        {{ insuranceRecord.insuranceTypeName }}
      </td>
      <td></td>
      <td>
        {{ insuranceRecord.insuranceConnectorTimeStamp | date: 'dd/MM/yyyy' }}
      </td>
      <td>
        {{ insuranceRecord.insuranceConnectorStatus }}
      </td>
      <td>{{ travelerRecord.adultNumber }}</td>
      <td>{{ travelerRecord.childNumber }}</td>
      <td>{{ travelerRecord.babyNumber }}</td>
    </tr>
  </tbody>
</table>