import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'simple-pulpo-dialog',
  templateUrl: './simple-pulpo-dialog.component.html',
  styleUrls: ['./simple-pulpo-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule],
})
export class SimplePulpoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SimplePulpoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      titleIcon: string;
      content: string;
      cancelButton: boolean;
      okButton: boolean;
    }
  ) {}
}
