import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { CarRentalService } from '@pulpo/pulpo-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class uniqueCarRentalRefValidator implements AsyncValidator {
  constructor(private carRentalService: CarRentalService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.carRentalService.checkReference(control.value).pipe(
      map((response) => {
        console.log('uniqueref', response);
        return response?.body === true ? { uniqueCarRentalRef: true } : null;
      })
    );
  }
}
