import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ICommissionCancellation,
  CommissionCancellation,
  Condition,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from '../api.service.abstract';

@Injectable({ providedIn: 'root' })
export class CommissionCancellationService extends ApiService<CommissionCancellation> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/commission-cancellations');
  }

  findCancellationByProfile(
    id: number
  ): Observable<HttpResponse<ICommissionCancellation[]>> {
    return this.http.get<ICommissionCancellation[]>(
      `${this.url}/${this.endpoint}/listByProfile/${id}`,
      { observe: 'response' }
    );
  }

  updateCancellations(
    cancellations: ICommissionCancellation[]
  ): Observable<HttpResponse<ICommissionCancellation[]>> {
    return this.http.put<ICommissionCancellation[]>(
      `${this.url}/${this.endpoint}/all`,
      cancellations,
      { observe: 'response' }
    );
  }

  retrieveConditions(id: number): Observable<HttpResponse<Condition[]>> {
    return this.http.get<Condition[]>(
      `${this.url}/${this.endpoint}/listConditionByProfile/${id}`,
      {
        observe: 'response',
      }
    );
  }
}
