import { Dayjs } from 'dayjs';
import { EnumStockStatus } from './enums/enum-stock-status.model';
import { IStock } from './stock.model';

export interface IStockValue {
  id?: number;
  date?: Dayjs;
  status?: EnumStockStatus;
  value?: number;
  available?: number;
  booked?: number;
  retro?: number;
  statusAfter?: EnumStockStatus;
  stock?: IStock;
  currentlyBooked?: number;
}

export class StockValue implements IStockValue {
  constructor(
    public id?: number,
    public date?: Dayjs,
    public status?: EnumStockStatus,
    public value?: number,
    public available?: number,
    public booked?: number,
    public retro?: number,
    public statusAfter?: EnumStockStatus,
    public stock?: IStock,
    public currentlyBooked?: number
  ) {}
}
