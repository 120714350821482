<form name="filterForm" (ngSubmit)="onSelectionChange()" class="w-100" role="form" novalidate [formGroup]="filterForm">
    <div class="row background justify-content-start filters w-100 h-25">
        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Référence</mat-label>
                <input formControlName="reference" matInput placeholder="Chercher une référence..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>

        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Pays</mat-label>
                <input formControlName="country" matInput placeholder="Chercher par pays" type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>

        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Destination</mat-label>
                <input formControlName="city" matInput placeholder="Chercher par ville" type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
    </div>
    <div class="row background justify-content-center filters w-100 h-25">
        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Type</mat-label>
                <input formControlName="type" matInput placeholder="Chercher par type" type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>

        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Categorie</mat-label>
                <input formControlName="category" matInput placeholder="Chercher par catégorie" type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>

        <div class="col-sm-6 d-flex flex-row-reverse align-items-center">
            <button type="button" mat-flat-button class="ml-1" color="warn" (click)="onResetFilters()"
                matTooltip="Réinitialiser les filtres">
                <mat-icon>clear_all</mat-icon>
            </button>
        </div>
    </div>
</form>