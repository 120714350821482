import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Supplier } from '@pulpo/pulpo-models';
import { StorageService, SupplierService } from '@pulpo/pulpo-api';

@Injectable()
export class SupplyFlightGuardService implements CanLoad {
  constructor(
    public supplierService: SupplierService,
    public storageService: StorageService,
    public router: Router
  ) {}
  canLoad(): boolean | Observable<boolean> {
    const company = this.storageService.getCompany();
    if (company && company.id) {
      return this.supplierService.findSupplierByPulpoId(company.id).pipe(
        map((res) => {
          return res.body &&
            res.body.filter((supplier: Supplier) => supplier.supplyFlight)
              .length > 0
            ? true
            : false;
        })
      );
    }
    return false;
  }
}
