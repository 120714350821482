import { Pipe, PipeTransform } from '@angular/core';
import { CarRentalType } from '@pulpo/pulpo-models';

@Pipe({
  name: 'CarRentalCategoryType',
  standalone: true,
})
export class CarRentalCategoryTypePipe implements PipeTransform {
  transform(value: string | CarRentalType): string {
    switch (value) {
      case 'CAR' || CarRentalType.CAR:
        return 'Voiture';
      default:
        return 'Inconnu';
    }
  }
}
