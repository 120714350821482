import { Inject, Injectable } from '@angular/core';
import { City, FlightReference } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FlightReferenceService extends ApiService<FlightReference> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'flight/api/flight-references', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:4300';
    }
  }

  getLinkableFlightReferences(filter: {
    arrivalAirportIata: string;
    destinationId: number;
    productId: number;
  }): Observable<HttpResponse<FlightReference[]>> {
    return this.http.get<FlightReference[]>(
      `${this.url}/${this.endpoint}/product/compatible`,
      {
        params: filter,
        observe: 'response',
      }
    );
  }

  duplicate(
    flightReferenceId: string,
    newName: string,
    newReference: string
  ): Observable<FlightReference> {
    return this.http.post<FlightReference>(
      `${this.url}/${this.endpoint}/duplicate/${flightReferenceId}`,
      {},
      { params: { reference: newReference, name: newName } }
    );
  }

  getByReference(
    reference: string
  ): Observable<HttpResponse<FlightReference | null>> {
    return this.http.get<FlightReference>(`${this.url}/${this.endpoint}`, {
      params: { reference },
      observe: 'response',
    });
  }

  getCities(): Observable<HttpResponse<City[]>> {
    return this.http.get<City[]>(`${this.url}/${this.endpoint}/city`, {
      observe: 'response',
    });
  }
}
