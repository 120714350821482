import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';
import { map } from 'rxjs/operators';
import ApiService from './api.service.abstract';
import {
  DATE_FORMAT,
  IPrice,
  RangePrice,
  UpdatePrices,
  UpdateRangePrice,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PriceService extends ApiService<IPrice> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/prices', routeReuse);
  }

  updateDates(
    prices: IPrice[],
    contractId: number,
    manual: boolean,
    discount: boolean,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<IPrice>> {
    const updatePrice = new UpdatePrices();
    const values = this.convertDateFromClientArray(prices);
    updatePrice.prices = values;
    updatePrice.contractId = contractId;
    updatePrice.manual = manual;
    updatePrice.discount = discount;
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.post(
      `${this.url}/${this.endpoint}` + '/updateDates',
      updatePrice,
      {
        observe: 'response',
      }
    );
  }

  updateRange(
    priceUpdateRangeRequest: UpdateRangePrice,
    routesToClear: string[] | undefined = undefined
  ) {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.post(
      `${this.url}/${this.endpoint}` + '/updateRange',
      priceUpdateRangeRequest,
      {
        observe: 'response',
      }
    );
  }

  queryForContract(
    id: string,
    year: string
  ): Observable<HttpResponse<IPrice[]>> {
    return this.http
      .get<IPrice[]>(
        `${this.url}/${this.endpoint}` +
          '/contract/year?contractId=' +
          id +
          '&year=' +
          year,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res: HttpResponse<IPrice[]>) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  queryAllForContract(
    id: any,
    promo: string | number | boolean
  ): Observable<HttpResponse<IPrice[]>> {
    const param = new HttpParams().set('promo', promo).set('contractId', id);
    return this.http
      .get<IPrice[]>(`${this.url}/${this.endpoint}` + '/contract/promo', {
        observe: 'response',
        params: param,
      })
      .pipe(
        map((res: HttpResponse<IPrice[]>) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  queryForContractBetweenDate(
    id: any,
    startDate: any,
    endDate: any,
    promo: any
  ) {
    const rangePrice = new RangePrice();
    rangePrice.contractId = id;
    rangePrice.startDate = this.convertDateFromClientDate(startDate);
    rangePrice.endDate = this.convertDateFromClientDate(endDate);
    rangePrice.promo = promo;
    return this.http
      .post(`${this.url}/${this.endpoint}` + '/range', rangePrice, {
        observe: 'response',
      })
      .pipe(map((res: any) => this.convertDateArrayFromServer(res)));
  }

  protected convertDateFromClient(price: IPrice): IPrice {
    const copy: IPrice = Object.assign({}, price, {
      date:
        price.date != null && price.date.isValid()
          ? price.date.format(DATE_FORMAT)
          : null,
    });
    return copy;
  }

  convertDateFromClientArray(prices: IPrice[]): IPrice[] {
    const copyList: IPrice[] = [];
    prices.forEach((price) => {
      const copy: IPrice = Object.assign({}, price, {
        date:
          price.date != null && price.date.isValid()
            ? price.date.format(DATE_FORMAT)
            : null,
      });
      copyList.push(copy);
    });

    return copyList;
  }

  protected convertDateFromClientDate(
    date: { isValid: () => any; format: (arg0: string) => any } | null
  ) {
    const date2 =
      date != null && date.isValid() ? date.format(DATE_FORMAT) : null;
    return date2;
  }

  protected convertDateFromServer(
    res: HttpResponse<IPrice>
  ): HttpResponse<IPrice> {
    if (res.body) {
      res.body.date = dayjs(res.body.date);
    }
    return res;
  }

  protected convertDateArrayFromServer(
    res: HttpResponse<IPrice[]>
  ): HttpResponse<IPrice[]> {
    if (res.body) {
      res.body.forEach((price: IPrice) => {
        price.date = dayjs(price.date);
      });
    }
    return res;
  }
}
