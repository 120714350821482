import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { CarRentalCorrespondenceService } from '@pulpo/pulpo-api';
import { CarRentalGender, CarRentalType } from '@pulpo/pulpo-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class carRentalDriverAgeValidator implements AsyncValidator {
  constructor(
    private carRentalCorrespondenceService: CarRentalCorrespondenceService,
    private type?: CarRentalType,
    private gender?: CarRentalGender
  ) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!this.type || !this.gender) {
      return new Observable<ValidationErrors | null>();
    }
    return this.carRentalCorrespondenceService
      .isUrbanCar(this.type, this.gender)
      .pipe(
        map((response) => {
          return response?.body === false && control.value <= 21
            ? { minDriverAge: true }
            : null;
        })
      );
  }
}
