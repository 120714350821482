<div class="table-header row">
    <div class="col-sm-9">
        <h4 class="m-4">
            Ventes spéciales
        </h4>
    </div>

    <div class="col-sm-3 d-flex justify-content-end">
        <button mat-raised-button class="m-4 btn-pulpo" (click)="openAddSaleDialog()">
            <mat-icon>add</mat-icon> Ajouter une vente
        </button>
    </div>
</div>
<table mat-table [dataSource]="dataSource" class="pulpo-table w-100" data-testid="product-special-sales-list">
    <ng-container matColumnDef="customerMail">
        <th mat-header-cell *matHeaderCellDef> <span>Mail</span> </th>
        <td mat-cell *matCellDef="let specialSale">
            <div class="d-flex flex-column"> <span>{{specialSale.customerMail}}</span>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> <span>Du</span> </th>
        <td mat-cell *matCellDef="let specialSale">{{specialSale.startDate | date:'dd/MM/yyyy'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> <span>Au</span> </th>
        <td mat-cell *matCellDef="let specialSale">{{specialSale.endDate | date:'dd/MM/yyyy'}}
        </td>
    </ng-container>
    <ng-container matColumnDef="deposit">
        <th mat-header-cell *matHeaderCellDef> <span>Acompte</span> </th>
        <td mat-cell *matCellDef="let specialSale">{{specialSale.deposit}} %</td>
    </ng-container>
    <ng-container matColumnDef="solde">
        <th mat-header-cell *matHeaderCellDef> <span>Solde</span> </th>
        <td mat-cell *matCellDef="let specialSale">{{ 100 - specialSale.deposit }} %</td>
    </ng-container>
    <ng-container matColumnDef="daysBeforeDeparture">
        <th mat-header-cell *matHeaderCellDef> <span>Jours avant départ</span> </th>
        <td mat-cell *matCellDef="let specialSale">{{specialSale.daysBeforeDeparture}}
        </td>
    </ng-container>
    <ng-container matColumnDef="dateSent">
        <th mat-header-cell *matHeaderCellDef> <span>Date envoi</span> </th>
        <td mat-cell *matCellDef="let specialSale">{{specialSale.dateSent | date:'dd/MM/yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="dateAccepted">
        <th mat-header-cell *matHeaderCellDef> <span>Dossier réservé</span> </th>
        <td mat-cell *matCellDef="let specialSale">{{specialSale.dateAccepted | date:'dd/MM/yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let specialSale">
            <div class="d-flex justify-content-end">
                <button matTooltip="Activer/Desactiver vente" mat-icon-button [class.green-colored]="specialSale.active"
                    [class.red-colored]="!specialSale.activated" (click)="triggerActivation(specialSale)"
                    data-testid="product-room-list-activate-button">
                    <mat-icon>check</mat-icon>
                </button>
                <button mat-icon-button class="colored" (click)="resendMail(specialSale)"
                    matTooltip="Supprimer la catégorie d'hébergement">
                    <mat-icon>mail</mat-icon>
                </button>
                <button mat-icon-button class="red-colored" (click)="deleteSpecialSale(specialSale)"
                    matTooltip="Supprimer la catégorie d'hébergement">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>