import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from '../api.service.abstract';
import {
  AccommodationCache,
  City,
  Country,
  PulpoRoomCachePricing,
  WHCachePrice,
  WHCacheProperty,
  WHCacheRate,
  WHCacheRoom,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { LocationService } from '@pulpo/pulpo-commons';

@Injectable({ providedIn: 'root' })
export class NewAccommodationCacheService extends ApiService<AccommodationCache> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    private locationService: LocationService,
    routeReuse: RouteReuseStrategy
  ) {
    super(
      environment,
      http,
      'accommodation/api/accommodation-cache',
      routeReuse
    );
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:8088';
    }
  }

  getPricingBetweenDates(
    cacheId: string,
    startDate: string,
    endDate: string,
    categoryName: string
  ): Observable<HttpResponse<PulpoRoomCachePricing[]>> {
    return this.http.get<PulpoRoomCachePricing[]>(
      `${this.url}/${this.endpoint}/pulpo/pricing`,
      {
        params: {
          cacheId,
          startDate,
          endDate,
          categoryName,
        },
        observe: 'response',
      }
    );
  }

  updatePricingRange(
    cacheId: string,
    startDate: string,
    endDate: string,
    categoryName: string,
    newPrices: {
      isEnabled: boolean;
      pulpoRoomCachePrice: { [key: string]: number };
    }
  ) {
    return this.http.post(
      `${this.url}/${this.endpoint}/pulpo/pricing/update-range`,
      newPrices,
      {
        params: {
          cacheId,
          startDate,
          endDate,
          categoryName,
        },
        observe: 'response',
      }
    );
  }

  updateManyPrices(
    cacheId: string,
    categoryName: string,
    newPrices: PulpoRoomCachePricing[]
  ): Observable<HttpResponse<PulpoRoomCachePricing[]>> {
    return this.http.post<PulpoRoomCachePricing[]>(
      `${this.url}/${this.endpoint}/pulpo/pricing/update`,
      newPrices,
      {
        params: {
          cacheId,
          categoryName,
        },
        observe: 'response',
      }
    );
  }

  getWebHotelierCachePropertiesByCity(
    city: City
  ): Observable<HttpResponse<WHCacheProperty[]>> {
    return this.http.get<WHCacheProperty[]>(
      `${this.url}/accommodation/api/accommodations/webhotelier/properties-by-city`,
      {
        params: {
          latitude: city.latitude,
          longitude: city.longitude,
        },
        observe: 'response',
      }
    );
  }

  getWebHotelierCacheProperties(
    country: Country
  ): Observable<HttpResponse<WHCacheProperty[]>> {
    return this.locationService.geocode(country.name).pipe(
      switchMap((res) => {
        console.log(res);
        return this.http.get<WHCacheProperty[]>(
          `${this.url}/accommodation/api/accommodations/webhotelier/properties`,
          {
            params: {
              bottom: res[0].boundingbox[0],
              left: res[0].boundingbox[2],
              top: res[0].boundingbox[1],
              right: res[0].boundingbox[3],
            },
            observe: 'response',
          }
        );
      })
    );
  }

  getWebHotelierCachePropertyRooms(
    propertyCode: string
  ): Observable<HttpResponse<WHCacheRoom[]>> {
    return this.http.get<WHCacheRoom[]>(
      `${this.url}/accommodation/api/accommodations/webhotelier/property-rooms`,
      {
        params: {
          'property-code': propertyCode,
        },
        observe: 'response',
      }
    );
  }

  getPropertyRoomRates(
    propertyCode: string,
    roomCode: string
  ): Observable<HttpResponse<WHCacheRate[]>> {
    return this.http.get<WHCacheRate[]>(
      `${this.url}/accommodation/api/accommodations/webhotelier/room-rates`,
      {
        params: {
          'property-code': propertyCode,
          'room-code': roomCode,
        },
        observe: 'response',
      }
    );
  }

  getPropertyRoomRateDetails(
    propertyCode: string,
    roomCode: string,
    rateId: string
  ): Observable<HttpResponse<WHCacheRate>> {
    return this.http.get<WHCacheRate>(
      `${this.url}/accommodation/api/accommodations/webhotelier/rate-details`,
      {
        params: {
          'property-code': propertyCode,
          'room-code': roomCode,
          'rate-id': rateId,
        },
        observe: 'response',
      }
    );
  }

  getPropertyCalendarAvailability(
    propertyCode: string,
    year: number,
    month: number,
    roomCode: string,
    rateId: number,
    cacheId: string
  ): Observable<HttpResponse<WHCachePrice[]>> {
    return this.http.get<WHCachePrice[]>(
      `${this.url}/accommodation/api/accommodations/webhotelier/calendar-availability`,
      {
        params: {
          'property-code': propertyCode,
          year: year.toString(),
          month: month.toString(),
          room: roomCode,
          rate: rateId,
          cacheId: cacheId,
        },
        observe: 'response',
      }
    );
  }
}
