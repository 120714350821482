import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PulpoSnackbarComponent } from '../components/pulpo-snackbar/pulpo-snackbar.component';
import { TranslocoService } from '@jsverse/transloco';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from '@pulpo/pulpo-api';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private snackBar: MatSnackBar,
    private translocoService: TranslocoService,
    private routeReuseStrategy: RouteReuseStrategy
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error &&
          error.status !== 404 &&
          !request.url.endsWith('/role') &&
          !request.url.endsWith('/api/account')
        ) {
          (
            this.routeReuseStrategy as CustomReuseStrategy
          ).clearAllSavedHandles();
          if (error?.error?.key) {
            this.translocoService
              .selectTranslate(error.error.key)
              .subscribe((res) => {
                this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                  data: {
                    title: 'Une erreur est survenue !',
                    message: res
                      ? res
                      : error?.error?.message
                      ? error?.error?.message
                      : 'Une erreur inconue est survenue',
                    backgroundColor: '#ec2b2b',
                    accentColor: 'white',
                  },
                  duration: 3000,
                  panelClass: ['snackbar'],
                });
              });
          } else {
            this.snackBar.openFromComponent(PulpoSnackbarComponent, {
              data: {
                title: 'Une erreur est survenue !',
                message: error?.error?.message
                  ? error?.error?.message
                  : 'Une erreur inconue est survenue',
                backgroundColor: '#ec2b2b',
                accentColor: 'white',
              },
              duration: 3000,
              panelClass: ['snackbar'],
            });
          }
        }
        return throwError(() => {
          return error;
        });
      })
    );
  }
}
