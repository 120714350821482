<div class="wrapper w-100">
    <div class="row justify-content-center colored text-center w-100" style="white-space: pre-wrap;">
        <div class="col-sm-12">
            <h5 class="w-100" style="white-space: pre-line">{{content}}</h5>
        </div>
    </div>
    <div class="row justify-content-center w-100">
        <div class="col-sm-4">

        </div>
        <div class="col-sm-4">
            <button mat-mini-fab class="btn-enabled-red m-2" (click)="this.dialogRef.close(id)"
                data-testid="dialog-button-delete">
                <mat-icon>done</mat-icon>
            </button>
            <button mat-mini-fab class="btn-pulpo m-2" (click)="this.dialogRef.close(-1)"
                data-testid="dialog-button-cancel">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="col-sm-4">

        </div>
    </div>

</div>