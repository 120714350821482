import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ICommission,
  ICommissionRequest,
  ICommissionResponse,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from '../api.service.abstract';

type EntityResponseType = HttpResponse<ICommission>;
type EntityArrayResponseType = HttpResponse<ICommission[]>;

@Injectable({ providedIn: 'root' })
export class CommissionService extends ApiService<ICommission> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/commissions');
  }

  findByProfile(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<ICommission[]>(
      `${this.url}/${this.endpoint}/listByProfile/${id}`,
      {
        observe: 'response',
      }
    );
  }

  calculateCommission(
    request: ICommissionRequest
  ): Observable<HttpResponse<ICommissionResponse>> {
    return this.http.post<ICommissionResponse>(
      `${this.url}/${this.endpoint}/calculateBySupplier`,
      request,
      { observe: 'response' }
    );
  }

  getCommission(
    supplierId: number,
    productId: number
  ): Observable<EntityResponseType> {
    return this.http.get<ICommission>(
      `${this.url}/${this.endpoint}/bySupplier/${supplierId}/${productId}`,
      { observe: 'response' }
    );
  }
}
