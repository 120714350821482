import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccommodationRecord } from '@pulpo/pulpo-models';
import { AccommodationRecordRoomCategoryPipe } from '../../../../pipes';

@Component({
  selector: 'booking-record-accommodation',
  templateUrl: './booking-record-accommodation.component.html',
  styleUrls: ['./booking-record-accommodation.component.scss'],
  standalone: true,
  imports: [CommonModule, AccommodationRecordRoomCategoryPipe],
})
export class BookingRecordAccommodationComponent {
  @Input() accommodationRecord: AccommodationRecord;
}
