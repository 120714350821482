import { Injectable } from '@angular/core';
import { EnumTypeProduct } from '@pulpo/pulpo-models';

@Injectable({
  providedIn: 'root',
})
export class TranslatorService {
  translateGender(sex: any): string {
    switch (sex) {
      case 'MALE':
        return 'Homme';
      case 'FEMALE':
        return 'Femme';
      default:
        return 'Indeterminé';
    }
  }

  translateType(type: EnumTypeProduct) {
    switch (type) {
      case EnumTypeProduct.CIRCUIT:
        return 'Circuit';
      case EnumTypeProduct.HOTEL:
        return 'Séjour';
      case EnumTypeProduct.AUTOTOUR:
        return 'Autotour';
      case EnumTypeProduct.COMBINED:
        return 'Combiné';
      case EnumTypeProduct.RESIDENCE:
        return 'Locatif';
    }
  }

  translateCategory(category: string) {
    if (category.includes('1')) {
      return '\u2605';
    } else if (category.includes('2')) {
      return '2 \u2605';
    } else if (category.includes('3')) {
      return '3 \u2605';
    } else if (category.includes('4')) {
      return '4 \u2605';
    } else if (category.includes('5')) {
      return '5 \u2605';
    } else {
      return null;
    }
  }
}
