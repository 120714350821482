import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  CarRentalCorrespondence,
  CarRentalGender,
  CarRentalType,
} from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CarRentalCorrespondenceService extends ApiService<CarRentalCorrespondence> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'car-rental/api/correspondence', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8083';
    }
  }

  public createOrUpdateAll(
    CarRentalCorrespondence: CarRentalCorrespondence[]
  ): Observable<HttpResponse<CarRentalCorrespondence[]>> {
    return this.http.post<CarRentalCorrespondence[]>(
      `${this.url}/${this.endpoint}`,
      CarRentalCorrespondence,
      {
        observe: 'response',
      }
    );
  }

  public getAll(): Observable<HttpResponse<CarRentalCorrespondence[]>> {
    return this.http.get<CarRentalCorrespondence[]>(
      `${this.url}/${this.endpoint}`,
      {
        observe: 'response',
      }
    );
  }

  public isUrbanCar(type: CarRentalType, gender: CarRentalGender) {
    return this.http.get<boolean>(
      `${this.url}/${this.endpoint}/is-urban-car?type=${type}&genre=${gender}`,
      {
        observe: 'response',
      }
    );
  }
}
