import { Pipe, PipeTransform } from '@angular/core';
import { DestinationService } from '@pulpo/pulpo-api';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'DestinationPipe',
  standalone: true,
  pure: true,
})
export class DestinationPipe implements PipeTransform {
  constructor(private destinationService: DestinationService) {}
  transform(id: number): Observable<string | undefined> {
    if (!id) return new Observable<string | undefined>();
    return this.destinationService.find(id).pipe(map((res) => res.body?.name));
  }
}
