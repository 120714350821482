export enum WHRoomAvailabilityStatusEnum {
  AVL = 'AVL',
  MAX = 'MAX',
  MIN = 'MIN',
  CHKIN = 'CHKIN',
  EARLY = 'EARLY',
  NA = 'NA',
  CLOUT = 'CLOUT',
  SS = 'SS',
  ALLOT = 'ALLOT',
  RATE = 'RATE',
  PAX = 'PAX',
}
