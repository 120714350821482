import { EnumStockStatus } from '@pulpo/pulpo-models';

export class StockUtils {
  static getStatusFromString(value: string): EnumStockStatus {
    switch (value) {
      case 'SS':
        return EnumStockStatus.STOPSALE;
      case 'RQ':
        return EnumStockStatus.REQUEST;
      case 'OK':
        return EnumStockStatus.AVAILABLE;
      default:
        return EnumStockStatus.STOPSALE;
    }
  }

  static getStatusFromFullString(value: string): EnumStockStatus {
    switch (value) {
      case 'STOPSALE':
        return EnumStockStatus.STOPSALE;
      case 'REQUEST':
        return EnumStockStatus.REQUEST;
      case 'AVAILABLE':
        return EnumStockStatus.AVAILABLE;
      default:
        return EnumStockStatus.STOPSALE;
    }
  }

  static getStringFromStatus(value: EnumStockStatus): string {
    switch (value) {
      case EnumStockStatus.STOPSALE:
        return 'SS';
      case EnumStockStatus.AVAILABLE:
        return 'OK';
      case EnumStockStatus.REQUEST:
        return 'RQ';
      default:
        return EnumStockStatus.STOPSALE;
    }
  }
}
