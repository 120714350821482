<div class="container-fluid w-85">
  <div class="mat-elevation-z2 corners mt-5">
    <div class="table-header row">
      <div class="col-sm-2">
        <button mat-raised-button [loading]="savePdf !== undefined" class="btn-white m-2 text-center"
          matTooltip="Télécharger un PDF" (click)="generatePdf(booking)" data-testid="booking-generate-pdf-button">
          <mat-icon class="colored scale-2 m-3">picture_as_pdf</mat-icon>
        </button>
        <button mat-raised-button [loading]="isLoading" class="btn-white m-2 text-center"
          matTooltip="Renvoyer la confirmation" (click)="resendBookingConfirmation()" [disabled]="isLoading">
          - <mat-icon class="colored scale-2 m-3">mail</mat-icon>
        </button>
      </div>
      <div class="col-sm-4">
        <h2 class="m-4">Réservation {{ booking.id }}</h2>
      </div>
      <div class="col-sm-1"></div>

      <div class="col-sm-3 justify-content-center">
        <mat-button-toggle-group class="white mt-3 text-center" [value]="this.isConsulted" (change)="onConsultChange()">
          <mat-button-toggle [value]="true" data-testid="booking-consult-button">Consulté</mat-button-toggle>
          <mat-button-toggle [value]="false" data-testid="booking-unconsult-button">Non Consulté</mat-button-toggle>
        </mat-button-toggle-group>
        <span class="font-weight-bold white">Dernière consultation :
          {{ dayjs(lastOpened).format('DD/MM/YYYY HH:mm') }}</span>
      </div>
      <div class="col-sm-2">
        <mat-card class="mt-3 text-center">
          <h4 class="font-weight-bold" style="color: black">
            {{
            booking.status === BookingStatusEnum.PENDING
            ? 'En attente'
            : booking.status === BookingStatusEnum.CANCELLED
            ? 'Annulée'
            : 'Confirmée'
            }}
          </h4>
        </mat-card>
      </div>
    </div>
    <div class="row white">
      <mat-divider></mat-divider>
    </div>
    <div class="row justify-content-center table-wrapper">
      <form name="editForm" role="form" class="w-100" novalidate [formGroup]="editForm">
        <div class="row w-100 mt-4">
          <div class="col-sm-4">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Produit</mat-label>
              <input matInput type="text" formControlName="product" readonly data-testid="booking-product" />
            </mat-form-field>
          </div>
          <div class="col-sm-2">
            <mat-form-field appearance="fill" class="w-75">
              <mat-label>ID Produit</mat-label>
              <input matInput type="text" formControlName="productId" readonly data-testid="booking-product-id" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Destination</mat-label>
              <input matInput type="text" formControlName="destination" readonly data-testid="booking-destination" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Date de création</mat-label>
              <input matInput type="text" formControlName="creationDate" readonly data-testid="booking-creation-date" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Dernière modification</mat-label>
              <input matInput type="text" formControlName="lastUpdate" readonly
                data-testid="booking-last-update-date" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <mat-label>Canal</mat-label>
              <input matInput type="text" formControlName="distribution" readonly data-testid="booking-distribution" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <span matPrefix *ngIf="
                  this.isCurrentlySupplier !== null &&
                  this.isCurrentlySupplier === false
                ">
                &rarr;</span>
              <mat-label>Producteur</mat-label>
              <input matInput type="text" formControlName="owner" readonly data-testid="booking-producer" />
            </mat-form-field>
          </div>
          <div class="col-sm-1">
            <mat-form-field appearance="fill" class="w-100">
              <span matPrefix *ngIf="
                  this.isCurrentlySupplier !== null &&
                  this.isCurrentlySupplier === true
                ">
                &rarr;</span>
              <mat-label>Distributeur</mat-label>
              <input matInput type="text" formControlName="supplier" readonly data-testid="booking-supplier" />
            </mat-form-field>
          </div>
        </div>
        <div class="row w-100 mt-4">
          <div class="col-sm-4">
            <div class="timeline w-100 h-100">
              <div class="container left">
                <div class="date">{{ this.startDate }}</div>
                <mat-icon>{{
                  booking.startCity ? 'flight_takeoff' : 'directions_walk'
                  }}</mat-icon>
                <div class="content">
                  <h2>
                    Voyage vers la destination :
                    {{ this.booking.product?.destination?.name }}
                  </h2>
                  <p>
                    {{
                    booking.startCity
                    ? 'Au départ de ' + booking.startCity
                    : 'Terrestre Seul'
                    }}{{ additionalStartInfo ? additionalStartInfo : '' }}
                  </p>
                </div>
              </div>
              <div class="container right">
                <div class="date"></div>
                <mat-icon>hotel</mat-icon>
                <div class="content">
                  <h2>{{ this.nbnights }} - {{ booking.product?.name }}</h2>
                  <p>
                    {{
                    booking.product?.type
                    ? this.translateType(booking.product?.type)
                    : 'Circuit'
                    }}
                    {{
                    booking.categoryName
                    ? this.translateCategory(booking.categoryName)
                    : ''
                    }}
                  </p>
                  <p>Voir détail de la prestation.</p>
                </div>
              </div>
              <div class="container left">
                <div class="date">{{ this.endDate }}</div>
                <mat-icon class="mirror">{{
                  booking.startCity ? 'flight_takeoff' : 'directions_walk'
                  }}
                </mat-icon>
                <div class="content">
                  <h2>
                    Voyage retour
                    {{
                    booking.startCity
                    ? 'vers ' + booking.startCity
                    : 'Non inclus'
                    }}
                  </h2>
                  <p>
                    Depuis la destination :
                    {{ this.booking.product?.destination?.name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-7">
            <mat-card class="corners mat-elevation-z2 bordered m-5 w-100">
              <mat-card-content>
                <booking-consult-bill class="w-100 mr-5" [selection]="this.selection" [newSelection]="this.newSelection"
                  [booking]="booking"></booking-consult-bill>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>
      <mat-divider> </mat-divider>
      <div class="w-100">
        <mat-tab-group mat-stretch-tabs="false" class="tab-group-500">
          <mat-tab label="Passagers">
            <div class="row col-12 fixed-height-300">
              <table class="col-8 table table-striped" aria-describedby="page-heading">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Date de naissance</th>
                    <th>Titre</th>
                  </tr>
                </thead>
                <ng-container *ngFor="let traveler of travelers">
                  <tbody>
                    <tr>
                      <td>{{ traveler.lastName }}</td>
                      <td>{{ traveler.firstName }}</td>
                      <td>{{ this.parseDate(traveler.birthDate) }}</td>
                      <td>
                        {{
                        this.passagerTitleList[travelers.indexOf(traveler)]
                        }}
                      </td>
                    </tr>
                  </tbody>
                </ng-container>
              </table>
              <div class="col-4"></div>
              <mat-divider> </mat-divider>
              <div class="row w-100 mt-4 mb-2" *ngIf="this.selection && this.selection.person">
                <div class="col-sm-12 background corners">
                  <h5><mat-icon>person</mat-icon> Coordonnées de contact :</h5>
                </div>
              </div>
              <div class="row m-2 w-100 mt-2 mb-4" *ngIf="this.selection && this.selection.person">
                <div class="col-sm-1"></div>
                <div class="col-sm-11">
                  <mat-card class="w-85 background corners mat-elevation-z4">
                    <div class="row w-100 m-2">
                      <div class="col-sm-12">
                        <span class="font-weight-bold"> Passager associé:</span>
                        <br />
                        <span> {{ this.getName() }}</span>
                      </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="row w-100 mt-4">
                      <div class="col-sm-6">
                        <span class="font-weight-bold"> Addresse(s) :</span>
                        <br />
                        <span style="white-space: pre-line">
                          {{ this.getAddress() }}</span>
                      </div>
                      <div class="col-sm-3">
                        <span class="font-weight-bold">
                          <mat-icon>call</mat-icon> Téléphone(s) :
                        </span>
                        <br />
                        <span style="white-space: pre-line">
                          {{ this.getPhone() }}
                        </span>
                      </div>
                      <div class="col-sm-3">
                        <span class="font-weight-bold">
                          <mat-icon>mail_outline</mat-icon> Email :
                        </span>
                        <br />
                        <span style="white-space: pre-line">{{
                          this.selection &&
                          this.selection.person &&
                          this.selection.person.email
                          ? this.selection.person.email
                          : 'NC'
                          }}
                        </span>
                      </div>
                    </div>
                  </mat-card>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Vols" *ngIf="this.flightOffer">
            <div class="fixed-height-300">
              <div class="row">
                <div class="col-sm-8">
                  <div *ngFor="
                      let itinerary of this.enlistItinerary(this.flightOffer)
                    ">
                    <div class="row background m-1 p-1 corners font-weight-bold">
                      {{ getFlightTitle(itinerary) }}
                    </div>
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>Décollage</th>
                          <th>Atterrissage</th>
                          <th>Vol</th>
                          <th>Bagages</th>
                          <th>ADT</th>
                          <th>CHD</th>
                          <th>BB</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let segment of itinerary.segments">
                          <td>
                            {{
                            getDay(segment.departureTime || '') +
                            ' ' +
                            segment.origin +
                            ' ' +
                            getTime(segment.departureTime || '')
                            }}
                          </td>
                          <td>
                            {{
                            getDay(segment.arrivalTime || '') +
                            ' ' +
                            segment.destination +
                            ' ' +
                            getTime(segment.arrivalTime || '')
                            }}
                          </td>
                          <td>
                            {{
                            segment.airlineIata + ' ' + segment.flightNumber
                            }}
                          </td>
                          <td>
                            <ng-container *ngIf="this.luggageSummary">
                              <span>
                                {{ this.luggageSummary }}
                              </span>
                            </ng-container>
                            <ng-container *ngIf="!this.luggageSummary">
                              <span>
                                {{ this.luggageSummary }}
                              </span>
                            </ng-container>
                          </td>
                          <td>{{ this.adult }}</td>
                          <td>{{ this.child }}</td>
                          <td>{{ this.baby }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="this.luggageDetails">
                    <div class="row background m-1 p-1 corners font-weight-bold">
                      Informations bagages
                    </div>
                    <table class="table table-striped">
                      <tbody>
                        <tr *ngFor="let luggageDetail of this.luggageDetails">
                          <td>{{ luggageDetail }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-sm-4">
                  <table class="table table-striped" *ngIf="this.selection">
                    <thead>
                      <tr>
                        <th>Source</th>
                        <th>PNR Provider</th>
                        <th>Statut</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{
                          this.flightOffer && this.flightOffer.source
                          ? this.flightOffer.source
                          : 'N/A'
                          }}
                        </td>
                        <td>{{ this.getLocatorCode() }}</td>
                        <td>
                          {{
                          booking.status === BookingStatusEnum.PENDING
                          ? 'RQ'
                          : booking.status === BookingStatusEnum.CANCELLED
                          ? 'NC'
                          : 'OK'
                          }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Total vol</th>
                        <th>Dont taxes</th>
                        <th>Supplément bagages</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ this.getFlightPrice(false) }}</td>
                        <td>
                          {{
                          this.flightTaxesMap.get(this.flightOffer.relatedId)
                          ? this.flightTaxesMap.get(
                          this.flightOffer.relatedId
                          ) + ' €'
                          : 'N/A'
                          }}
                        </td>
                        <td>{{ this.getLuggagePrice() }}</td>
                        <td>{{ this.getFlightPrice(true) }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Montant</th>
                        <th>Emis</th>
                        <th>Mode</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ this.getTotalPrice() }}</td>
                        <td *ngIf="
                            this.flightOffer &&
                            this.flightOffer.source &&
                            this.flightOffer.source.toLowerCase() === 'pyton'
                          ">
                          {{
                          dayjs(this.booking.creationDate).format(
                          'dd/MM/yyyy'
                          )
                          }}
                        </td>
                        <td *ngIf="
                            !(
                              this.flightOffer &&
                              this.flightOffer.source &&
                              this.flightOffer.source.toLowerCase() === 'pyton'
                            )
                          "></td>
                        <td>
                          {{
                          this.flightOffer && this.flightOffer.paymentMode
                          ? this.flightOffer.paymentMode
                          : 'N/A'
                          }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-striped"
                    *ngIf="newSelection.locatorCode || newSelection.supplierLocatorCode">
                    <thead>
                      <tr>
                        <th>Source</th>
                        <th>PNR Fournisseur</th>
                        <th>PNR Cie</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{
                          this.flightOffer && this.flightOffer.source
                          ? this.flightOffer.source
                          : 'N/A'
                          }}
                        </td>
                        <td>{{ this.getSupplierLocatorCode() }}</td>
                        <td>{{ this.getLocatorCode() }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <ng-container *ngIf="
                      this.newSelection &&
                      this.newSelection.flightOffer &&
                      this.newSelection.flightOffer.source &&
                      this.newSelection.flightOffer.source
                        .toLowerCase()
                        .includes('travelport')
                    ">
                    <ng-container *ngIf="this.newSelection.flightTicket; else ticketButton">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Ticket</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ this.newSelection.flightTicket }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </ng-container>

                    <ng-template #ticketButton>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th>Procéder au ticketing</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <button mat-raised-button [class.btn-disabled]="
                                  this.isTicketingBookingEnabled()
                                " [loading]="isTicketingProcess" [class.btn-pulpo]="
                                  !this.isTicketingBookingEnabled()
                                " matTooltip="Ticketing" (click)="processTicketing()">
                                <mat-icon class="colored scale-2 m-3">confirmation_number</mat-icon>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Hébergement" *ngIf="this.rooms">
            <div class="fixed-height-300">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th><span class="font-weight-bold">Type</span></th>
                    <th><span class="font-weight-bold">Catégorie</span></th>
                    <th>Qté</th>
                    <th>ADT</th>
                    <th>CHD</th>
                    <th>BEBE</th>
                    <th></th>
                    <th *ngIf="this.selection">Prix</th>
                  </tr>
                </thead>
                <tbody *ngFor="let room of this.rooms; let i = index">
                  <tr>
                    <td>
                      <span style="font-size: small">{{ room.type }}</span>
                    </td>
                    <td>
                      <span style="font-size: small">{{
                        this.parseCategory(room.category || '')
                        }}</span>
                    </td>
                    <td>{{ 1 }}</td>
                    <td>{{ room.adult }}</td>
                    <td>{{ room.child }}</td>
                    <td>{{ room.baby }}</td>
                    <td></td>
                    <td *ngIf="this.selection">
                      {{ room.price.toFixed(2) + '€' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Assurances" *ngIf="getSelection.insurance">
            <div class="fixed-height-300">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th></th>
                    <th>Date</th>
                    <th>Statut</th>
                    <th>ADT</th>
                    <th>CHD</th>
                    <th>BEBE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{
                      getSelection.insurance.insuranceName || ''
                      }}
                    </td>
                    <td></td>
                    <td>
                      {{
                      getSelection.insurance?.insuranceConnectorTimeStamp
                      ? this.parseInsuranceDate(
                      getSelection.insurance.insuranceConnectorTimeStamp || ''
                      )
                      : ''
                      }}
                    </td>
                    <td>
                      {{
                      getSelection.insurance.insuranceConnectorStatus
                      ? this.parseStatus(
                      getSelection.insurance.insuranceConnectorStatus || ''
                      )
                      : ''
                      }}
                    </td>
                    <td>{{ this.adult }}</td>
                    <td>{{ this.child }}</td>
                    <td>{{ this.baby }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Locations de voiture" *ngIf="getSelection.carRentalName">
            <div class="fixed-height-300">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Référence</th>
                    <th></th>
                    <th>Prix de base</th>
                    <th>Supplément</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{
                      getSelection.carRentalProvider
                      ? getSelection.carRentalProvider: ''
                      }}
                    </td>
                    <td>
                      {{
                      getSelection.carRentalBookingReference
                      ? getSelection.carRentalBookingReference: ''
                      }}
                    </td>
                    <td>
                    </td>
                    <td>
                      {{
                      getSelection.carRentalReferencePrice
                      ? (!getSelection.carRentalMandatory
                      ? (getSelection.carRentalReferencePrice.toFixed(2)+ ' €'): ' inclus '): ' - '
                      }}
                    </td>
                    <td>
                      {{
                      getSelection.carRentalSupplement
                      ? (!getSelection.carRentalMandatory
                      ? (getSelection.carRentalSupplement.toFixed(2)+ ' €'): ' inclus '): ' - '
                      }}
                    </td>
                    <td>
                      {{
                      getSelection.carRentalTotalPrice
                      ? (!getSelection.carRentalMandatory
                      ? (getSelection.carRentalTotalPrice.toFixed(2)+ ' €'): ' inclus '): ''
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Options" *ngIf="this.getSelection && this.getSelection.options">
            <div class="fixed-height-300">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>ADT / U</th>
                    <th>CHD</th>
                    <th>BEBE</th>
                    <th *ngIf="this.selection">Prix</th>
                  </tr>
                </thead>
                <tbody *ngFor="
                    let option of getSelection.options
                      ? getSelection.options
                      : [];
                    let i = index
                  ">
                  <tr *ngIf="option.occupationType === 'OPTION'">
                    <td>
                      <span style="font-size: small" [matTooltip]="
                          option.name.length > 10 ? option.name || '' : ''
                        ">{{ option.name }}</span>
                    </td>
                    <td>{{ getOptionNumber(i, null) }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr *ngIf="option.occupationType === 'PERSON'">
                    <td>
                      <span style="font-size: small" [matTooltip]="
                          option.name.length > 10 ? option.name || '' : ''
                        ">{{ option.name }}</span>
                    </td>
                    <td>{{ getOptionNumber(i, 'adult') }}</td>

                    <td>{{ getOptionNumber(i, 'child') }}</td>
                    <td>{{ getOptionNumber(i, 'baby') }}</td>
                    <td *ngIf="this.selection && option.id">
                      {{ this.getOptionPrice(option.id, i) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-tab>
          <mat-tab label="Détails">
            <div class="row col-12 fixed-height-300">
              <div class="row w-100 text-center background m-1 p-1 corners font-weight-bold">
                Le prix comprend
              </div>
              <div class="col-12" [innerHTML]="booking.product?.priceInDescription"></div>
              <br />
              <div class="row w-100 text-center background m-1 p-1 corners font-weight-bold">
                Le prix ne comprend pas
              </div>
              <div class="col-12" [innerHTML]="booking.product?.priceOffDescription"></div>
            </div>
          </mat-tab>
          <mat-tab label="Conditions d'annulation" *ngIf="this.getSelection && this.getSelection.conditions">
            <div class="fixed-height-500">
              <div class="row justify-content-center w-100 m-3" *ngFor="let condition of this.getSelection.conditions">
                <div class="col-9">
                  <p>{{ condition }}</p>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Remarques" *ngIf="getSelection">
            <div class="row m-4">
              <span class="col-sm-2 centered-span">Commentaire client : </span>
              <span class="col-sm-10">{{ this.getDescription() }}</span>
            </div>
          </mat-tab>
          <mat-tab label="Paiements" *ngIf="getSelection && getSelection.payments">
            <div class="row m-2">
              <div class="col-sm-1"></div>
              <div class="col-sm-11">
                <mat-card *ngFor="let payment of getSelection.payments?.payments"
                  class="w-85 background corners mat-elevation-z4">
                  <div class="row w-100">
                    <div class="col-sm-3">
                      <h5>Date :</h5>
                      <h5 class="font-weight-bold">
                        {{
                        payment.date
                        ? this.parseDate(payment.date)
                        : this.parseDate(booking.creationDate)
                        }}
                      </h5>
                    </div>
                    <div class="col-sm-3">
                      <h5>Etat :</h5>
                      <h5 class="font-weight-bold">{{ payment.state }}</h5>
                    </div>
                    <div class="col-sm-3">
                      <h5>Statut :</h5>
                      <h5 class="font-weight-bold">{{ payment.role }}</h5>
                    </div>
                    <div class="col-sm-3">
                      <h5>Montant :</h5>
                      <h5 class="font-weight-bold">
                        {{ getPaymentPrice(payment) }} €
                      </h5>
                    </div>
                  </div>
                </mat-card>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </mat-tab>
          <mat-tab label="Finances" *ngIf="
              this.newSelection && !this.isCurrentlySupplier && !producerId
            ">
            <div class="fixed-height-1000">
              <div class="row background m-1 p-1 corners font-weight-bold">
                <div class="col-sm-11">
                  <h5>TOTAL ACHATS</h5>
                </div>
                <div class="col-sm-1">
                  <h5>{{ this.getTotalPriceBought() + ' €' }}</h5>
                </div>
              </div>
              <div class="row col-sm-12">
                <div class="row background m-1 p-1 corners font-weight-bold">
                  <div class="col-sm-11">
                    <h5>CONTRATS</h5>
                  </div>
                  <div class="col-sm-1">
                    <h5>{{ this.getContractAndMealPrice() }}</h5>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Catégorie</th>
                      <th>Statut</th>
                      <th></th>
                      <th></th>
                      <th>Qté</th>
                      <th>ADT</th>
                      <th>CHD</th>
                      <th>BEBE</th>
                      <th></th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let room of rooms">
                      <td>
                        <span style="font-size: small">{{ room.type }}</span>
                      </td>
                      <td>
                        <span style="font-size: small">{{
                          this.parseCategory(room.category || '')
                          }}</span>
                      </td>
                      <td>
                        <span [class.green-colored]="
                            this.getRoomStatus(room) === 'OK'
                          " [class.red-colored]="
                            this.getRoomStatus(room) === 'KO'
                          " [class.orange-colored]="
                            this.getRoomStatus(room) === 'NO'
                          ">{{ this.getRoomStatus(room) }}</span>
                      </td>
                      <td></td>
                      <td></td>
                      <td>{{ 1 }}</td>
                      <td>{{ room.adult }}</td>
                      <td>{{ room.child }}</td>
                      <td>{{ room.baby }}</td>
                      <td></td>
                      <td>{{ this.getRoomPrice(room) }}</td>
                    </tr>

                    <tr>
                      <td>
                        <span style="font-size: small">{{
                          this.newSelection.board
                          }}</span>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ this.newSelection.adultNumber }}</td>
                      <td>{{ this.newSelection.childNumber }}</td>
                      <td>{{ this.newSelection.babyNumber }}</td>
                      <td></td>
                      <td>
                        {{
                        this.newSelection.prices.mealPrice?.toFixed(2) + ' €'
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row col-sm-12" *ngIf="this.flightOffer">
                <div class="row background m-1 p-1 corners font-weight-bold">
                  <div class="col-sm-11">
                    <h5>VOLS</h5>
                  </div>
                  <div class="col-sm-1">
                    <h5>{{ this.getRealFlightPrice(true) }}</h5>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>Référence</th>
                      <th *ngIf="this.newSelection.flightTicket">Ticket</th>
                      <th>Statut</th>
                      <th>Vols TTC</th>
                      <th>Bagages</th>
                      <th></th>
                      <th>Emis</th>
                      <th>Mode</th>
                      <th>Total HT</th>
                      <th>Taxes</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                        this.flightOffer && this.flightOffer.source
                        ? this.flightOffer.source
                        : '-'
                        }}
                      </td>
                      <td>{{ this.getLocatorCode() }}</td>
                      <td *ngIf="this.newSelection.flightTicket">
                        {{ this.newSelection.flightTicket }}
                      </td>
                      <td>
                        <span [class.green-colored]="
                            this.getFlightStatus() === 'OK'
                          " [class.orange-colored]="
                            this.getFlightStatus() === 'NO'
                          " [class.red-colored]="this.getFlightStatus() === 'KO'">{{ this.getFlightStatus() }}</span>
                      </td>
                      <td>{{ this.getRealFlightPrice(false) }}</td>
                      <td>{{ this.getLuggagePrice() }}</td>
                      <td></td>
                      <td>{{ this.getEmissionDate() }}</td>
                      <td *ngIf="
                          !(
                            this.flightOffer &&
                            this.flightOffer.paymentMode &&
                            this.flightOffer.paymentMode !== 'BSP'
                          )
                        "></td>
                      <td *ngIf="
                          this.flightOffer && !this.flightOffer.paymentMode
                        "></td>
                      <td>
                        {{
                        this.flightOffer && this.flightOffer.paymentMode
                        ? this.flightOffer.paymentMode
                        : '-'
                        }}
                      </td>
                      <td>{{ this.getRealFlightPriceWithoutTax(true) }}</td>
                      <td>
                        {{
                        this.flightTaxesMap.get(this.flightOffer.relatedId)
                        ? this.flightTaxesMap.get(
                        this.flightOffer.relatedId
                        ) + ' €'
                        : '-'
                        }}
                      </td>
                      <td>{{ this.getRealFlightPrice(true) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row col-sm-12" *ngIf="getSelection.insurance">
                <div class="row background m-1 p-1 corners font-weight-bold">
                  <div class="col-sm-11">
                    <h5>ASSURANCES</h5>
                  </div>
                  <div class="col-sm-1">
                    <h5>{{ this.getInsuranceTotalPrice() }} €</h5>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>ID</th>
                      <th>Informations</th>
                      <th>Référence</th>
                      <th></th>
                      <th>Statut</th>
                      <th>Date</th>
                      <th>ADT</th>
                      <th>CHD</th>
                      <th>BEBE</th>
                      <th></th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                        getSelection.insurance.insuranceName || ''
                        }}
                      </td>
                      <td>
                        {{ getSelection.insurance?.insuranceId
                        || ''
                        }}
                      </td>
                      <td>
                        {{getSelection.insurance?.insuranceTypeName || ''
                        }}
                      </td>
                      <td>
                        {{
                        getSelection.insurance.insuranceConnectorRef || ''
                        }}
                      </td>
                      <td></td>
                      <td>
                        <span [class.green-colored]="
                            this.getInsuranceStatus() === 'OK'
                          " [class.orange-colored]="
                            this.getInsuranceStatus() === 'NO'
                          " [class.red-colored]="
                            this.getInsuranceStatus() === 'KO'
                          ">{{ this.getInsuranceStatus() }}</span>
                      </td>
                      <td>
                        {{
                        getSelection.insurance.insuranceConnectorTimeStamp
                        ? this.parseInsuranceDate(
                        getSelection.insurance.insuranceConnectorTimeStamp || ''
                        )
                        : ''
                        }}
                      </td>
                      <td>{{ this.newSelection.adultNumber }}</td>
                      <td>{{ this.newSelection.childNumber }}</td>
                      <td>{{ this.newSelection.babyNumber }}</td>
                      <td></td>
                      <td>
                        {{
                        getSelection.insurance.insuranceConnectorPrice
                        ? this.parseInsurancePrice(
                        getSelection.insurance.insuranceConnectorPrice || ''
                        )
                        : ''
                        }}
                        €
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row col-sm-12" *ngIf="getSelection.carRentalName">
                <div class="row background m-1 p-1 corners font-weight-bold">
                  <div class="col-sm-11">
                    <h5>LOCATIONS DE VOITURE</h5>
                  </div>
                  <div class="col-sm-1">
                    <h5>{{ getSelection.carRentalOfferPrice }} €</h5>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>ID</th>
                      <th></th>
                      <th>Référence</th>
                      <th></th>
                      <th>Statut</th>
                      <th></th>
                      <th>ADT</th>
                      <th>CHD</th>
                      <th>BEBE</th>
                      <th></th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{
                        getSelection.carRentalProvider
                        ? getSelection.carRentalProvider
                        : ''
                        }}
                      </td>
                      <td>
                        {{
                        getSelection.carRentalOfferToken
                        ? getSelection.carRentalOfferToken
                        : ''
                        }}
                      </td>
                      <td>
                      </td>
                      <td>
                        {{
                        getSelection.carRentalBookingReference
                        ? getSelection.carRentalBookingReference
                        : ''
                        }}
                      </td>
                      <td></td>
                      <td>
                        <span [class.green-colored]="
                                    getSelection.carRentalProvider
                                  " [class.red-colored]="
                                    !getSelection.carRentalProvider
                                  ">{{ this.getCarRentalStatus() }}</span>
                      </td>
                      <td>

                      </td>
                      <td>{{ this.newSelection.adultNumber }}</td>
                      <td>{{ this.newSelection.childNumber }}</td>
                      <td>{{ this.newSelection.babyNumber }}</td>
                      <td></td>
                      <td>
                        {{
                        getSelection.carRentalOfferPrice
                        ? getSelection.carRentalOfferPrice
                        : ''
                        }}
                        €
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row col-sm-12" *ngIf="getSelection.options && getSelection.options.length > 0">
                <div class="row background m-1 p-1 corners font-weight-bold">
                  <div class="col-sm-11">
                    <h5>OPTIONS</h5>
                  </div>
                  <div class="col-sm-1">
                    <h5>
                      {{
                      this.newSelection.prices.totalOptionPrice?.toFixed(2) +
                      ' €'
                      }}
                    </h5>
                  </div>
                </div>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Référence</th>
                      <th>ID</th>
                      <th>Statut</th>
                      <th></th>
                      <th></th>
                      <th>Qté</th>
                      <th>ADT</th>
                      <th>CHD</th>
                      <th>BEBE</th>
                      <th></th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tbody *ngFor="
                      let option of getSelection.options || [];
                      let i = index
                    ">
                    <tr *ngIf="option.occupationType === 'OPTION'">
                      <td>
                        <span style="font-size: small" [matTooltip]="
                            option.name!.length > 10 ? option.name || '' : ''
                          ">{{
                          option.name!.length > 10
                          ? option.name.substring(0, 9) + '...'
                          : option.name
                          }}</span>
                      </td>
                      <td>{{ option.id }}</td>
                      <td>OK</td>
                      <td></td>
                      <td></td>
                      <td>{{ getOptionNumber(i, null) }}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td *ngIf="option.id">
                        {{ this.getOptionPrice(option.id, i) }}
                      </td>
                    </tr>
                    <tr *ngIf="option.occupationType === 'PERSON'">
                      <td>
                        <span style="font-size: small" [matTooltip]="
                            option.name.length && option.name.length! > 10
                              ? option.name || ''
                              : ''
                          ">{{
                          option.name.length && option.name.length! > 10
                          ? option.name.substring(0, 9) + '...'
                          : option.name
                          }}</span>
                      </td>
                      <td>{{ option.id }}</td>
                      <td>OK</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ getOptionNumber(i, 'adult') }}</td>
                      <td>{{ getOptionNumber(i, 'child') }}</td>
                      <td>{{ getOptionNumber(i, 'baby') }}</td>
                      <td></td>
                      <td *ngIf="option.id">
                        {{ getOptionPrice(option.id, i) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Données Orchestra"
            [disabled]="!newSelection.originalRequest && !newSelection.originalQuoteReply && !newSelection.originalReply">
            <ng-template matTabContent>
              <mat-tab-group class="tab-group-500">

                <mat-tab label="Requête Booking" [disabled]="!newSelection.originalRequest">
                  <ng-template matTabContent>
                    <ngx-codemirror class="h-100" [ngModel]="newSelection.originalRequest | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse Quote" [disabled]="!newSelection.originalQuoteReply">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="newSelection.originalQuoteReply | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse Booking" [disabled]="!newSelection.originalReply">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="newSelection.originalReply | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
          <mat-tab label="Données Vols"
            [disabled]="!newSelection.originalFlightRequest && !newSelection.originalFlightReply">
            <ng-template matTabContent>
              <mat-tab-group>
                <mat-tab label="Requête API Vol (Travelport)" [disabled]="!newSelection.originalFlightRequest">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="newSelection.originalFlightRequest | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse API Vol (Travelport)" [disabled]="!newSelection.originalFlightReply">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="newSelection.originalFlightReply | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
          <mat-tab label="Données Assurances"
            [disabled]="!newSelection.originalInsuranceRequest && !newSelection.originalInsuranceReply">
            <ng-template matTabContent>
              <mat-tab-group>
                <mat-tab label="Requête API Assurance (Presence XML)"
                  [disabled]="!newSelection.originalInsuranceRequest">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="newSelection.originalInsuranceRequest | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
                <mat-tab label="Réponse API Assurance (Presence XML)" [disabled]="!newSelection.originalInsuranceReply">
                  <ng-template matTabContent>
                    <ngx-codemirror [ngModel]="newSelection.originalInsuranceReply | XmlFormatPipe"
                      [options]="options"></ngx-codemirror>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>