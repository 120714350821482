import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Formality } from '@pulpo/pulpo-models';

@Injectable({
  providedIn: 'root',
})
export class FormalityService extends ApiService<Formality> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/formalities');
  }
}
