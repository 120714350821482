import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PriceSnapshot } from '@pulpo/pulpo-models';

@Component({
  selector: 'booking-calendar-day',
  templateUrl: './booking-calendar-day.component.html',
  styleUrls: ['./booking-calendar-day.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BookingCalendarDayComponent {
  @Input() price?: PriceSnapshot;
  @Input() isDisplayedPrice?: boolean = false;
  @Input() showAdditionnalInfos?: boolean = false;
  @Output() changeDisplayDay = new EventEmitter<any>();
}
