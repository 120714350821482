import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ICity } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { CacheBucket, withCache } from '@ngneat/cashew';

@Injectable({ providedIn: 'root' })
export class CityService extends ApiService<ICity> {
  citiesChangedSubject: Subject<ICity[]> = new Subject<ICity[]>();
  cityBucket = new CacheBucket();

  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/cities');
  }

  public findAllWithCache(): Observable<HttpResponse<ICity[]>> {
    return this.http.get<ICity[]>(`${this.url}/${this.endpoint}`, {
      observe: 'response',
      context: withCache({
        bucket: this.cityBucket,
        key: 'cities',
        ttl: 15 * 60 * 1000,
      }),
    });
  }
}
