export interface IRangePrice {
  contractId?: any;
  startDate?: any;
  endDate?: any;
}

export class RangePrice implements IRangePrice {
  constructor(
    public contractId?: any,
    public startDate?: any,
    public endDate?: any,
    public promo?: any
  ) {}
}
