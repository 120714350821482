import { InsuranceProviderEnum } from './enums/insurance-provider-enum.model';
import { InsuranceRange } from './insurance-range.model';
import { InsuranceType } from './insurance-type.model';

export class Insurance {
  id?: string;
  name: string;
  internalReference: string;
  insuranceCompany: string;
  pulpoCompanyId?: string;
  insuranceProvider?: InsuranceProviderEnum;
  information?: string;
  maximumDays?: number;
  minimumPassengers?: number;
  maximumPassengers?: number;
  isDeletable?: boolean;
  ranges?: InsuranceRange[];
  types?: InsuranceType[];
}
