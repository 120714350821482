import { Pipe, PipeTransform } from '@angular/core';
import { CarRentalGender } from '@pulpo/pulpo-models';

@Pipe({
  name: 'CarRentalCategoryGender',
  standalone: true,
})
export class CarRentalCategoryGenderPipe implements PipeTransform {
  transform(value: string | CarRentalGender): string {
    switch (value) {
      case 'CITY_CAR' || CarRentalGender.CITY_CAR:
        return 'Citadine';
      case 'COMPACT' || CarRentalGender.COMPACT:
        return 'Compacte';
      case 'SPACIOUS' || CarRentalGender.SPACIOUS:
        return 'Spacieuse';
      case 'NONE' || CarRentalGender.NONE:
        return 'Sans Préférence';
      default:
        return 'Inconnu';
    }
  }
}
