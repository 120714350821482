import { Directive, forwardRef, Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { AccommodationService, StorageService } from '@pulpo/pulpo-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class uniqueAccomRefValidator implements AsyncValidator {
  constructor(
    private accommodationService: AccommodationService,
    private storageService: StorageService
  ) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.accommodationService
      .getAllRefs(this.storageService.getCompany().id)
      .pipe(
        map((data: any) =>
          data.body.indexOf(control.value) !== -1 ? { existingRef: true } : null
        )
      );
  }
}

@Directive({
  selector: '[uniqueAccomRef]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => UniqueAccomRefValidatorDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class UniqueAccomRefValidatorDirective implements AsyncValidator {
  constructor(private validator: uniqueAccomRefValidator) {}
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.validator.validate(control);
  }
}
