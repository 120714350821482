import { Pipe, PipeTransform } from '@angular/core';
import { CarRentalKileage } from '@pulpo/pulpo-models';

@Pipe({
  name: 'CarRentalCategoryKileage',
  standalone: true,
})
export class CarRentalCategoryKileagePipe implements PipeTransform {
  transform(value: string | CarRentalKileage): string {
    console.log('transform kielage', value);
    switch (value) {
      case 'ULLIMITED' || CarRentalKileage.UNLIMITED:
        return 'ILL';
      case 'LIMITED' || CarRentalKileage.LIMITED:
        return 'L';
      case 'NONE' || CarRentalKileage.NONE:
        return 'Sans Préférence';
      default:
        return 'Inconnu';
    }
  }
}
