import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';
import { map } from 'rxjs/operators';
import { IStock } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class StockService extends ApiService<IStock> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/stocks', routeReuse);
  }

  protected convertDateArrayFromServer(
    res: HttpResponse<IStock[]>
  ): HttpResponse<IStock[]> {
    if (res.body) {
      res.body.forEach((stock: IStock) => {
        stock.startDate = dayjs(stock.startDate);
        stock.endDate = dayjs(stock.endDate);
        stock.updated = dayjs(stock.updated);
      });
    }
    return res;
  }

  findAllByCompany(id: number): Observable<HttpResponse<IStock[]>> {
    return this.http
      .get<IStock[]>(`${this.url}/${this.endpoint}/company?companyId=${id}`, {
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<IStock[]>) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }
}
