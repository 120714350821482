import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'pulpo-pulpo-snackbar',
  templateUrl: './pulpo-snackbar.component.html',
  styleUrls: ['./pulpo-snackbar.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, MatDividerModule, MatIconModule],
})
export class PulpoSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<PulpoSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}
}
