import { Company } from '../company';
import { CommissionDeposit } from './commission-deposit.model';
import { CommissionPricingEnumModel } from './enums/commission-pricing-enum.model';

export class CommissionProfile {
  id!: number;
  name!: string;
  rank!: number;
  pricing!: CommissionPricingEnumModel;
  removable!: boolean;
  description!: string;
  company!: Company;
  deposits?: CommissionDeposit[];
}
