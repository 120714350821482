import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BookingPrice,
  BookingSubStatusEnum,
  FlightRecord,
  TravelerRecord,
} from '@pulpo/pulpo-models';

@Component({
  selector: 'booking-record-flight',
  templateUrl: './booking-record-flight.component.html',
  styleUrls: ['./booking-record-flight.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BookingRecordFlightComponent {
  @Input() flightRecord: FlightRecord;
  @Input() travelerRecord: TravelerRecord;
  @Input() bookingPrice: BookingPrice;
  @Input() creationDate: Date;
  BookingSubStatusEnum = BookingSubStatusEnum;
  Number = Number;

  get luggageSummary() {
    if (this.flightRecord.offer.isLuggageIncluded === true) {
      return 'inclus';
    }
    if (this.flightRecord.bags && this.flightRecord.bags.length > 0) {
      let luggageSummary = '';
      this.flightRecord.bags.forEach((bg) => {
        const quantity = bg.quantity ? bg.quantity : '1';
        if (!bg.weight) {
          luggageSummary += quantity + ' x 23 kg. ';
        } else {
          luggageSummary += quantity + ' x ' + bg.weight + ' kg. ';
        }
      });
      return luggageSummary;
    } else {
      return '';
    }
  }

  getFlightPrice(withLuggage: boolean) {
    let price = this.bookingPrice.referenceFlightPrice
      ? Number(this.bookingPrice.referenceFlightPrice)
      : 0;
    if (this.bookingPrice.flightPrice && this.bookingPrice.flightPrice > 0) {
      price += Number(this.bookingPrice.flightPrice);
    }

    if (
      withLuggage &&
      this.flightRecord.luggageNumber &&
      this.flightRecord.luggageNumber > 0
    ) {
      price += Number(this.bookingPrice.luggagePrice);
    }

    return price.toFixed(2);
  }

  getTotalPrice() {
    if (this.bookingPrice?.paidFlightPrice) {
      return this.bookingPrice.paidFlightPrice;
    }
    if (this.bookingPrice?.referenceFlightPrice) {
      let price = this.bookingPrice.referenceFlightPrice
        ? Number(this.bookingPrice.referenceFlightPrice)
        : 0;
      if (this.bookingPrice.flightPrice) {
        price += Number(this.bookingPrice.flightPrice);
      }
      if (this.bookingPrice.luggagePrice) {
        price += Number(this.bookingPrice.luggagePrice);
      }
      return price.toFixed(2);
    }
    return 'N/A';
  }
}
