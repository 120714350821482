import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'libs/pulpo-commons/src/lib/guards/auth.guard';
import { RoleGuard } from 'libs/pulpo-commons/src/lib/guards/role.guard';
import { UserRightEnum } from '@pulpo/pulpo-models';
import { NavbarComponent, NotFoundComponent } from '@pulpo/pulpo-commons';

const routes: Routes = [
  { path: '', component: NavbarComponent, outlet: 'navbar' },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'option',
    loadChildren: () =>
      import('./features/option/option.module').then((m) => m.OptionModule),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerOption',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'flight',
    loadChildren: () =>
      import('./features/flight/flight.module').then((m) => m.FlightModule),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerFlight',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'flight-booking-consult',
    loadChildren: () =>
      import(
        './features/flight-booking-consult/flight-booking-consult.module'
      ).then((m) => m.FlightBookingConsultModule),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerFlight',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'insurance',
    loadChildren: () =>
      import('./features/insurance/insurance.module').then(
        (m) => m.InsuranceModule
      ),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerInsurance',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'booking',
    loadChildren: () =>
      import('./features/booking-consult/booking.module').then(
        (m) => m.BookingModule
      ),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodBookingRecord',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'booking-record',
    loadChildren: () =>
      import('./features/booking-record/booking.module').then(
        (m) => m.BookingModule
      ),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodBookingRecord',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'destination',
    loadChildren: () =>
      import('./features/destination/destination.module').then(
        (m) => m.DestinationModule
      ),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerDestination',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'tag',
    loadChildren: () =>
      import('./features/tag/tag.module').then((m) => m.TagModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'distribution',
    loadChildren: () =>
      import('./features/distribution/distribution.module').then(
        (m) => m.DistributionModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'formality',
    loadChildren: () =>
      import('./features/formality/formality.module').then(
        (m) => m.FormalityModule
      ),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerFormality',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'accommodation',
    loadChildren: () =>
      import('./features/accommodation/accommodation.module').then(
        (m) => m.AccommodationModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'new-accommodation',
    loadChildren: () =>
      import('./features/new-accommodation/new-accommodation.module').then(
        (m) => m.NewAccommodationModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'car-rental',
    loadChildren: () =>
      import('./features/car-rental/car-rental.module').then(
        (m) => m.CarRentalModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./features/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodAdministrationParameter',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'stock',
    loadChildren: () =>
      import('./features/stock/stock.module').then((m) => m.StockModule),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerStock',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'product',
    loadChildren: () =>
      import('./features/product/product.module').then((m) => m.ProductModule),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodProducerProduct',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./features/account/account.module').then((m) => m.AccountModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'role',
    loadChildren: () =>
      import('./features/role/role.module').then((m) => m.RoleModule),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodAdministrationRole',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./features/company/company.module').then((m) => m.CompanyModule),
    canLoad: [
      AuthGuard,
      RoleGuard.forPermissions({
        name: 'prodAdministrationCompany',
        right: UserRightEnum.READ,
      }),
    ],
  },
  {
    path: '404',
    component: NotFoundComponent,
    data: {
      pageTitle: 'error.title',
      error404: true,
    },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
