import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CarRentalProviderAccount } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CarRentalProviderAccountService extends ApiService<CarRentalProviderAccount> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'car-rental/api/provider-account');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8083';
    }
  }

  public getCredentialsByCompanyId(
    companyId: string
  ): Observable<HttpResponse<CarRentalProviderAccount[]>> {
    return this.http.get<CarRentalProviderAccount[]>(
      `${this.url}/${this.endpoint}/by-company-id/${companyId}`,
      {
        observe: 'response',
      }
    );
  }

  public override updateAll(credentials: CarRentalProviderAccount[]) {
    return this.http.post<CarRentalProviderAccount[]>(
      `${this.url}/${this.endpoint}`,
      credentials,
      {
        observe: 'response',
      }
    );
  }
}
