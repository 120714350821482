import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  requests: HttpRequest<unknown>[] = [];

  constructor(private loadingService: LoadingService) {}

  checkRequestCount(req: HttpRequest<unknown>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
      this.loadingService.isLoading.next(this.requests.length > 0);
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      (request.method === 'POST' ||
        request.method === 'PUT' ||
        request.method === 'DELETE') &&
      !request.url.includes('authenticate') &&
      !request.url.includes('refreshtoken') &&
      !request.url.includes('displayProductCache') &&
      !request.url.includes('calendar/price')
    ) {
      this.requests.push(request);
      this.loadingService.isLoading.next(true);
      return Observable.create((observer: any) => {
        const subscription = next.handle(request).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              this.checkRequestCount(request);
              observer.next(event);
            }
          },
          (err) => {
            this.checkRequestCount(request);
            observer.error(err);
          },
          () => {
            this.checkRequestCount(request);
            observer.complete();
          }
        );
        // teardown logic in case of cancelled requests
        return () => {
          this.checkRequestCount(request);
          subscription.unsubscribe();
        };
      });
    } else {
      return next.handle(request);
    }
  }
}
