<mat-card [style.background-color]="data.backgroundColor || 'white'" class="snackbar-card">
    <mat-card-header>
        <mat-card-title>
            <h4 *ngIf="data.title" [style.color]="data.accentColor || 'black'">{{data.title}}</h4>
        </mat-card-title>
    </mat-card-header>
    <mat-divider [style.border-top-color]="data.accentColor || 'rgba(0,0,0,.12)'"></mat-divider>
    <mat-card-content class="d-flex flex-column justify-content-around align-items-center text-center h-100 m-4">
            <mat-icon *ngIf="data.icon" [style.color]="data.iconColor || 'black'">{{data.icon}}</mat-icon>
            <h5 *ngIf="data.message" [style.color]="data.accentColor || 'black'">{{data.message}}</h5>
    </mat-card-content>
</mat-card>