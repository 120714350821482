export class CarRentalCategory {
  id?: string;
  name?: string;
  group?: string;
  model?: string;
  type?: CarRentalType;
  gender?: CarRentalGender;
  kileage?: CarRentalKileage;
  numberOfDoors?: number;
  numberOfPersons?: number;
  numberOfLuggage?: number;
  gearbox?: CarRentalGearbox;
  airConditioning?: CarRentalAirConditioning;
  status?: boolean;
  isDeletable?: boolean;
}

export enum CarRentalType {
  CAR = 'CAR',
}

export enum CarRentalGender {
  CITY_CAR = 'CITY_CAR',
  COMPACT = 'COMPACT',
  SPACIOUS = 'SPACIOUS',
  NONE = 'NONE',
}

export enum CarRentalKileage {
  LIMITED = 'LIMITED',
  UNLIMITED = 'ULLIMITED',
  NONE = 'NONE',
}

export enum CarRentalGearbox {
  A = 'A',
  M = 'M',
  NONE = 'NONE',
}

export enum CarRentalAirConditioning {
  YES = 'YES',
  NO = 'NO',
  NONE = 'NONE',
}

export enum CarRentalCanBeCanceled {
  YES = 'YES',
  NO = 'NO',
  NONE = 'NONE',
}
