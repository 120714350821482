import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import ApiService from './api.service.abstract';
import {
  FlightSearch,
  FlightValidation,
  FlightOrder,
  FlightTicketing,
  FlightTicketingOutput,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class FlightEngineService extends ApiService<any> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/engine/flight');
  }

  search(query: FlightSearch): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.url}/${this.endpoint}/search`, query, {
      observe: 'response',
    });
  }

  validate(query: FlightValidation): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.url}/${this.endpoint}/validate`, query, {
      observe: 'response',
    });
  }

  order(query: FlightOrder): Observable<HttpResponse<any>> {
    return this.http.post<any>(`${this.url}/${this.endpoint}/order`, query, {
      observe: 'response',
    });
  }

  ticketing(
    query: FlightTicketing
  ): Observable<HttpResponse<FlightTicketingOutput>> {
    return this.http.post<any>(
      `${this.url}/${this.endpoint}/ticketing`,
      query,
      {
        observe: 'response',
      }
    );
  }
}
