import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IExcelImportMapping } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';

type EntityResponseType = HttpResponse<IExcelImportMapping>;

@Injectable({ providedIn: 'root' })
export class ExcelImportMappingService extends ApiService<IExcelImportMapping> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/excel-import-mappings');
  }

  findByCompanyId(companyId: number): Observable<EntityResponseType> {
    return this.http.get<IExcelImportMapping>(
      `${this.url}/${this.endpoint}/${companyId}`,
      { observe: 'response' }
    );
  }
}
