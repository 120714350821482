<div *ngIf="images" class="w-100 h-100 slider-wrapper p-4">
    <div class="d-flex flex-column align-items-center h-100">
        <h6 *ngIf="images && images.length === 0">Aucune image disponible</h6>
        <button [class.add-image-button-empty]="images && images.length === 0" (click)="onAddButtonClicked()"
            class="btn-pulpo add-image-button">+</button>
    </div>
    <div class="slider-container">
        <button class="slide-button-left" (click)="onSlideButtonClicked('left')">
        </button>
        <div cdkDropList [cdkDropListAutoScrollStep]="20" cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dropImage($event)"
            class="d-flex flex-row justify-content-start align-items-center slider-content" id=slider>
            <div cdkDrag *ngFor="let picture of images; let i = index" class="picture-container m-2"
                [ngClass]="{'highlight-border': picture.productCover}" (click)="onAddButtonClicked(picture)">
                <div class="w-100 h-100 d-flex flex-column justify-content-between align-items-start position-relative">
                    <span class="rank">{{i}}</span>
                    <img class="picture m-2" [src]="picture.url" />
                    <mat-chip-set class="tag-list m-2" aria-label="picture tags">
                        <mat-chip disabled *ngFor="let tag of picture.tags">{{tag}}</mat-chip>
                    </mat-chip-set>
                    <h6 class="description m-2">{{picture.description}}</h6>
                    <!-- <h6>{{picture.url}}</h6> -->
                    <div class="m-2 d-flex flex-row justify-content-between align-items-center w-100">
                        <mat-checkbox [(ngModel)]="picture.productCover"
                            (ngModelChange)="changeImageValue($event,picture)">Couverture Produit</mat-checkbox>
                        <!-- delete button -->
                        <button mat-icon-button class="red-colored" matTooltip="Supprimer l'image"
                            (click)="onDeleteButtonClicked($event, picture)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button class="slide-button-right" (click)="onSlideButtonClicked('right')"> ></button>
    </div>
</div>