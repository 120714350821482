import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from '../api.service.abstract';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@pulpo/app-config';
import { AccommodationRoomCategory, DATE_FORMAT } from '@pulpo/pulpo-models';
import { Dayjs } from 'dayjs';

@Injectable({ providedIn: 'root' })
export class AccommodationCacheService extends ApiService<any> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'accom/api/cache');
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:9090';
    }
  }

  public getPricesByRoomCategory(
    accomId: string,
    roomCategories: AccommodationRoomCategory[],
    mealId: string,
    startDate: Dayjs,
    endDate: Dayjs
  ): Observable<HttpResponse<any>> {
    const roomCategoriesParam = roomCategories.reduce(
      (acc, curr) => acc + '&roomCategories=' + curr,
      '&roomCategories='
    );
    return this.http.get<any>(
      `${this.url}/${
        this.endpoint
      }/price?accomId=${accomId}${roomCategoriesParam}&mealId=${mealId}&startDate=${startDate.format(
        DATE_FORMAT
      )}&endDate=${endDate.format(DATE_FORMAT)}`,
      {
        observe: 'response',
      }
    );
  }

  public getCheapestPricesByRoomCategory(
    accomId: string
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.url}/${this.endpoint}/cheapest?accomId=${accomId}`,
      {
        observe: 'response',
      }
    );
  }
}
