import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'accommodation-filter',
  templateUrl: './accommodation-filter.component.html',
  styleUrls: ['./accommodation-filter.component.scss'],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
  ],
  standalone: true,
})
export class AccommodationFilterComponent implements OnInit {
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  filterForm: FormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initFilterForm();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      reference: [''],
      country: [''],
      city: [''],
      category: [''],
      type: [''],
    });
  }

  onSelectionChange() {
    this.filterEvent.emit(this.filterForm.value);
  }

  onResetFilters(): void {
    this.filterForm.reset();
    this.filterEvent.emit(this.filterForm.value);
  }
}
