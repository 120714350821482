import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  standalone: true,
  imports: [MatIconModule],
})
export class SectionHeaderComponent {
  @Input() icon!: string;
  @Input() name!: string;
}
