import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IAirlineMargin } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';

type EntityArrayResponseType = HttpResponse<IAirlineMargin[]>;

@Injectable({ providedIn: 'root' })
export class AirlineMarginService extends ApiService<IAirlineMargin> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/airline-margins');
  }

  queryMine(): Observable<EntityArrayResponseType> {
    return this.http.get<IAirlineMargin[]>(
      `${this.url}/${this.endpoint}` + '/mine',
      { observe: 'response' }
    );
  }
}
