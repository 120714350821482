import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsuranceRecord, TravelerRecord } from '@pulpo/pulpo-models';

@Component({
  selector: 'booking-record-insurance',
  templateUrl: './booking-record-insurance.component.html',
  styleUrls: ['./booking-record-insurance.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BookingRecordInsuranceComponent {
  @Input() insuranceRecord: InsuranceRecord;
  @Input() travelerRecord: TravelerRecord;
}
