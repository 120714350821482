export const compare = (
  a: number | string,
  b: number | string,
  isAsc: boolean
) => {
  // if (typeof a === 'string') {
  //   a = removeAccentsAndTurnToLowerCase(a);
  // }
  // if (typeof b === 'string') {
  //   b = removeAccentsAndTurnToLowerCase(b);
  // }

  const multiplier = isAsc ? 1 : -1;
  let result: number;

  if (a === b) {
    result = 0;
  } else if (!a) {
    result = 1;
  } else if (!b) {
    result = -1;
  } else {
    result = a > b ? 1 : -1;
  }

  return result * multiplier;
};

export const compareWithIds = (a: any, b: any) => {
  return a && b && a.id === b.id;
};

export const compareAsStringWith = (a: any, b: any) => {
  return a && b && a.toString() === b.toString();
};
