import { Dayjs } from 'dayjs';
import { AccommodationCache } from './accommodation-cache.model';
import { AccommodationBooking } from './accommodation-booking.model';
import { AccommodationProviderEnum } from './enums/accommodation-provider.enum';

export class NewAccommodation {
  id?: string;
  name: string;
  internalReference: string;
  countryId: number;
  isDeletable: boolean;
  cache: AccommodationCache;
  booking: AccommodationBooking;
  modifiedAt: Dayjs;
  bookingProvider: AccommodationProviderEnum;
  cacheProvider: AccommodationProviderEnum;
}
