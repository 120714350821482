import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { FileSettings } from '@pulpo/pulpo-models';
import { Observable } from 'rxjs';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class FileSettingsService extends ApiService<FileSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/file-settings');
  }

  findByCompanyId(companyId: number): Observable<HttpResponse<FileSettings>> {
    return this.http.get<FileSettings>(
      `${this.url}/${this.endpoint}/createOrUpdate?companyId=${companyId}`,
      { observe: 'response' }
    );
  }
}
