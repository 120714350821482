import { Company } from '../company';
import { ZoneCommissionEnum } from '../distribution/enums/zone-commission-enum.model';
import { ICountry } from './country.model';

export interface IDestination {
  id?: number;
  name?: string;
  isStartDestination?: boolean;
  countries?: ICountry[];
  company?: Company;
  zone?: ZoneCommissionEnum;
  removable?: boolean;
  pictureUrl?: string;
}

export class Destination implements IDestination {
  company!: Company;
  countries!: ICountry[];
  id!: number;
  isStartDestination!: boolean;
  name!: string;
  removable!: boolean;
  zone!: ZoneCommissionEnum;
  pictureUrl!: string;
}
