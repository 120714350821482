import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TimezoneBinding } from '@pulpo/pulpo-models';

@Component({
  selector: 'pulpo-clock',
  templateUrl: './pulpo-clock.component.html',
  styleUrls: ['./pulpo-clock.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PulpoClockComponent implements OnInit, OnChanges {
  @Input() timezone: TimezoneBinding;
  currentDate?: Date;
  transformHours = 'rotate(0deg)';
  //transformSecs: string = "rotate(0deg)";
  transformMins = 'rotate(0deg)';
  //x: number = 0;
  y = 0;
  z = 0;

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.currentDate = this.changeTimezone(
      new Date(),
      this.timezone.timezoneName
    );

    const currentHour = this.currentDate.getHours();
    const currentMinute = this.currentDate.getMinutes();
    const currentSecond = this.currentDate.getSeconds();
    //this.x = currentSecond * 6;
    this.y = currentMinute * 6 + (6 / 60) * currentSecond;
    this.z =
      currentHour * 30 +
      ((30 / 60) * currentMinute + (30 / 60 / 60) * currentSecond);
    //this.transformSecs = "rotate(" + this.x + "deg)";
    this.transformMins = 'rotate(' + this.y + 'deg)';
    this.transformHours = 'rotate(' + this.z + 'deg)';
    // 'x' is the degree value of the secondHand 'Transform[Rotate]'
    // 'y' is the degree value of the minuteHand 'Transform[Rotate]'
    // 'z' is the degree value of the hourHand 'Transform[Rotate]'
    setInterval(() => {
      this.currentDate = this.changeTimezone(
        new Date(),
        this.timezone.timezoneName
      );

      const currentHour = this.currentDate.getHours();
      const currentMinute = this.currentDate.getMinutes();
      const currentSecond = this.currentDate.getSeconds();
      //this.x = currentSecond * 6;
      this.y = currentMinute * 6 + (6 / 60) * currentSecond;
      this.z =
        currentHour * 30 +
        ((30 / 60) * currentMinute + (30 / 60 / 60) * currentSecond);
      //this.transformSecs = "rotate(" + this.x + "deg)";
      this.transformMins = 'rotate(' + this.y + 'deg)';
      this.transformHours = 'rotate(' + this.z + 'deg)';
    }, 60000);
  }

  ngOnChanges() {
    this.currentDate = this.changeTimezone(
      new Date(),
      this.timezone.timezoneName
    );

    const currentHour = this.currentDate.getHours();
    const currentMinute = this.currentDate.getMinutes();
    const currentSecond = this.currentDate.getSeconds();
    //this.x = currentSecond * 6;
    this.y = currentMinute * 6 + (6 / 60) * currentSecond;
    this.z =
      currentHour * 30 +
      ((30 / 60) * currentMinute + (30 / 60 / 60) * currentSecond);
  }

  changeTimezone(date: Date, ianatz: string) {
    // suppose the date is 12:00 UTC
    const invdate = new Date(
      date.toLocaleString('en-US', { timeZone: ianatz })
    );
    // then invdate will be 07:00 in Toronto
    // and the diff is 5 hours
    const diff = date.getTime() - invdate.getTime();
    // so 12:00 in Toronto is 17:00 UTC
    return new Date(date.getTime() - diff);
  }
}
