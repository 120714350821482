import { Contract } from '../booking';
import { Company } from '../company';
import { Destination } from '../destination';
import { EnumTypeProduct } from '../distribution/enums/enum-type-product.model';
import { IAirport } from '../flight/airport.model';
import { Formality } from '../formality';
import { ProductDescription } from './product-description.model';

export class Product {
  id!: number;
  name!: string;
  reference!: string;
  language?: string;
  startPoint?: string;
  secondDestinationId?: number;
  catchPhrase?: string;
  subtitle?: string;
  shortDescription?: string;
  priceInDescription?: string;
  priceOffDescription?: string;
  goodToKnow?: string;
  formality?: Formality;
  conditions?: string;
  transportDescription?: string;
  type?: EnumTypeProduct;
  bestDeal?: boolean;
  nbPicture?: number;
  active?: boolean;
  destination?: Destination;
  company?: Company;
  contracts?: Contract[];
  productDescriptions?: ProductDescription[];
  orchestraContent?: boolean;
  airportId?: number;
  airport?: IAirport;
  nbContractWithStock?: number;
  nbContract?: number;
  nbProductRooms?: number;
  gpOrder?: string;
  nbOption?: number;
  nbFlight?: number;

  landOnly?: boolean;
}
