import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class PdfService {
  constructor(
    @Inject(APP_CONFIG) private environment: any,
    protected http: HttpClient
  ) {}

  generate(id: number): Observable<Blob> {
    return this.http.get(this.environment.apiUrl + 'api/pdf/product/' + id, {
      responseType: 'blob',
    });
  }

  generateBooking(id: number | string): Observable<Blob> {
    return this.http.get(this.environment.apiUrl + 'api/pdf/booking/' + id, {
      responseType: 'blob',
    });
  }
}
