import { Dayjs } from 'dayjs';

export class PriceSnapshot {
  id!: number;
  contractId!: number;
  originId!: number;
  date!: Dayjs;
  lastUpdateDate!: Dayjs;
  category!: string;
  roomCategory!: string;
  duration!: number;
  price!: number | null;
  promoPrice!: number;
  endPromoDate!: Dayjs;
  status!: string;
  lastUpdateTime!: Dayjs;
  summary!: string;

  contractPrice?: number;
  accommodationPrice?: number;
  flightPrice?: number;
  mandatoryOptionPrice?: number;
  mandatoryCarRentalPrice?: number;
}
