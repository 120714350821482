import { FlightProviderEnum } from '../flight/enums/enum-flight-provider.model';

export interface FlightTicketing {
  transactionId?: string;
  reference?: string;
  bookingId?: number;
  companyId?: number;
  provider: FlightProviderEnum;
}

export class FlightTicketingImpl implements FlightTicketing {
  bookingId?: number;
  companyId?: number;
  reference?: string;
  transactionId?: string;
  provider: FlightProviderEnum;
}
