import { Dayjs } from 'dayjs';
import { EnumStockType } from './enums/enum-stock-type.model';
import { EnumStockStayType } from './enums/enum-stock-stay-type.model';
import { ICountry } from '../destination';
import { Product } from '../product';
import { Contract } from '../booking';
import { StockValue } from './stock-value.model';

export interface IStock {
  id?: number | null;
  reference?: string;
  name?: string;
  startDate?: Dayjs;
  endDate?: Dayjs;
  type?: EnumStockType;
  stayType?: EnumStockStayType;
  available?: boolean;
  updated?: Dayjs;
  country?: ICountry;
  products?: Product[];
  contracts?: Contract[];
  stockValues?: StockValue[];
  contractNames?: string[];
  productNames?: string[];
  companyId?: number;
  productInfos?: string[];
}

export class Stock implements IStock {
  constructor(
    public id?: number,
    public reference?: string,
    public name?: string,
    public startDate?: Dayjs,
    public endDate?: Dayjs,
    public type?: EnumStockType,
    public stayType?: EnumStockStayType,
    public available?: boolean,
    public updated?: Dayjs,
    public country?: ICountry,
    public products?: Product[],
    public contracts?: Contract[],
    public stockValues?: StockValue[],
    public removable?: boolean,
    public numberContract?: number,
    public numberProduct?: number,
    public companyId?: number,
    public productInfos?: string[]
  ) {
    this.available = this.available || false;
  }
}
