import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';
import { IAirport } from '@pulpo/pulpo-models';
import { CacheBucket, withCache } from '@ngneat/cashew';

@Injectable({ providedIn: 'root' })
export class AirportService extends ApiService<IAirport> {
  parametersChangedSubject = new Subject<IAirport[]>();
  airportBucket = new CacheBucket();
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/airports');
  }

  querywithName(name: string): Observable<HttpResponse<IAirport[]>> {
    return this.http.get<IAirport[]>(`${this.url}/${this.endpoint}/search`, {
      params: {
        value: name,
      },
      observe: 'response',
    });
  }

  public findAllWithCache(): Observable<HttpResponse<IAirport[]>> {
    return this.http.get<IAirport[]>(`${this.url}/${this.endpoint}`, {
      observe: 'response',
      context: withCache({
        bucket: this.airportBucket,
        key: 'airport',
        ttl: 15 * 60 * 1000,
      }),
    });
  }
}
