import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SpecialSaleService } from '@pulpo/pulpo-api';
import { SpecialSale } from '@pulpo/pulpo-models';
import { PulpoSnackbarComponent } from '../../pulpo-snackbar/pulpo-snackbar.component';
import { AddSpecialSaleDialogComponent } from '../add-special-sale-dialog/add-special-sale-dialog.component';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import dayjs from 'dayjs';

@Component({
  selector: 'special-sales-dialog',
  templateUrl: './special-sales-dialog.component.html',
  styleUrls: ['./special-sales-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTableModule, MatIconModule, MatButtonModule],
})
export class SpecialSalesDialogComponent implements OnInit {
  displayedColumns: string[] = [
    'customerMail',
    'startDate',
    'endDate',
    'deposit',
    'solde',
    'daysBeforeDeparture',
    'dateSent',
    'dateAccepted',
    'options',
  ];
  dataSource: MatTableDataSource<SpecialSale> =
    new MatTableDataSource<SpecialSale>([]);

  constructor(
    private specialSaleService: SpecialSaleService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: { contractId: number }
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.specialSaleService
      .getSalesByContractId(this.data.contractId)
      .subscribe((response) => {
        this.dataSource.data = response.body || [];
      });
  }

  deleteSpecialSale(specialSale: SpecialSale) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '50%',
      panelClass: 'pulpo-dialog-container',
      data: {
        title: 'Suppression',
        message: dayjs().isBefore(specialSale.endDate)
          ? "Cette vente spéciale n'est pas temrinée, voulez vous vraiment la supprimer ?"
          : 'Voulez-vous vraiment supprimer cette vente spéciale ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== -1) {
        this.specialSaleService.delete(specialSale.id).subscribe(() => {
          this.snackBar.openFromComponent(PulpoSnackbarComponent, {
            data: {
              title: 'Suppression',
              message: 'La vente spéciale a été supprimée',
            },
            duration: 4000,
            panelClass: ['snackbar'],
          });
          this.refresh();
        });
      }
    });
  }

  openAddSaleDialog() {
    const dialogRef = this.dialog.open(AddSpecialSaleDialogComponent, {
      width: '50%',
      panelClass: 'pulpo-dialog-container',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.specialSaleService
          .create({ ...result, contractId: this.data.contractId })
          .subscribe(
            () => {
              this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                data: {
                  title: 'Ajout',
                  message: 'La vente spéciale a été ajoutée',
                },
                duration: 4000,
                panelClass: ['snackbar'],
              });
              this.refresh();
            },
            (error) => {
              if (error.status === 404) {
                this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                  data: {
                    title: 'Impossible de créer la vente spéciale',
                    message: 'Produit non distribué par traveltribe',
                    backgroundColor: '#ec2b2b',
                    accentColor: 'white',
                  },
                  duration: 4000,
                  panelClass: ['snackbar'],
                });
                this.dialog.closeAll();
              }
            }
          );
      }
    });
  }

  resendMail(specialSale: SpecialSale) {
    this.specialSaleService.sendMail(specialSale).subscribe(() => {
      this.snackBar.openFromComponent(PulpoSnackbarComponent, {
        data: {
          title: 'Email envoyé',
          message: "L'email a été renvoyé",
        },
        duration: 4000,
        panelClass: ['snackbar'],
      });
    });
  }

  triggerActivation(specialSale: SpecialSale) {
    this.specialSaleService
      .update({ ...specialSale, active: specialSale.active ? false : true })
      .subscribe(() => {
        this.snackBar.openFromComponent(PulpoSnackbarComponent, {
          data: {
            title: 'Activation',
            message:
              'La vente spéciale a été ' + specialSale.active
                ? 'desactivée'
                : 'activée',
          },
          duration: 4000,
          panelClass: ['snackbar'],
        });
        this.refresh();
      });
  }
}
