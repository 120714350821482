import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  CarRental,
  CarRentalOffer,
  CarRentalProviderAccount,
  CarRentalRenter,
  CarRentalSimulationFilters,
  DATE_FORMAT,
} from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CarRentalService extends ApiService<CarRental> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'car-rental/api/allowance', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8083';
    }
  }

  public checkReference(reference: string): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(
      `${this.url}/${this.endpoint}/exists?internalReference=${reference}`,
      {
        observe: 'response',
      }
    );
  }

  public override update(
    item: CarRental,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<CarRental>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    this.convertPriceList(item);
    return super.update(item);
  }

  public deleteWithStringId(id: string) {
    return this.http.delete(`${this.url}/${this.endpoint}/${id}`, {
      observe: 'response',
    });
  }

  public override queryPage(req?: any): Observable<HttpResponse<any>> {
    const options = this.createRequestOption(req);
    return this.http.get<any>(`${this.url}/${this.endpoint}`, {
      params: options,
      observe: 'response',
    });
  }

  public getRenters(): Observable<HttpResponse<CarRentalRenter[]>> {
    return this.http.get<CarRentalRenter[]>(
      `${this.url}/${this.endpoint}/renters`,
      {
        observe: 'response',
      }
    );
  }

  public simulate(
    req: CarRentalSimulationFilters
  ): Observable<HttpResponse<CarRentalOffer[]>> {
    return this.http.post<CarRentalOffer[]>(
      `${this.url}/${this.endpoint}/simulation`,
      req,
      {
        observe: 'response',
      }
    );
  }

  public getByCountry(): Observable<
    HttpResponse<{ country: string; carRentals: CarRental[] }[]>
  > {
    return this.http.get<{ country: string; carRentals: CarRental[] }[]>(
      `${this.url}/${this.endpoint}/by-country`,
      {
        observe: 'response',
      }
    );
  }

  public getCredentialsByCompanyId(
    companyId: string
  ): Observable<HttpResponse<CarRentalProviderAccount[]>> {
    return this.http.get<CarRentalProviderAccount[]>(
      `${this.url}/${this.endpoint}/provider-credentials?companyId=${companyId}`,
      {
        observe: 'response',
      }
    );
  }

  public createOrUpdateCredentials(credentials: CarRentalProviderAccount[]) {
    return this.http.post<CarRentalProviderAccount[]>(
      `${this.url}/${this.endpoint}/provider-credentials`,
      credentials,
      {
        observe: 'response',
      }
    );
  }

  private convertPriceList(item: CarRental) {
    if (item.cachePrice) {
      item.cachePrice.categoryCachePriceList?.forEach((category) => {
        category.pricesList?.forEach((price) => {
          price.startDate = dayjs(price.startDate).format(DATE_FORMAT);
          price.endDate = dayjs(price.endDate).format(DATE_FORMAT);
        });
      });
    }
  }
}
