import { Inject, Injectable } from '@angular/core';
import { ProductFlightReference } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductFlightReferenceService extends ApiService<ProductFlightReference> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'flight/api/product-flights', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:4300';
    }
  }

  getProductFlightReferences(
    productId: number
  ): Observable<HttpResponse<ProductFlightReference[]>> {
    return this.http.get<ProductFlightReference[]>(
      `${this.url}/${this.endpoint}?productId=${productId}`,
      {
        observe: 'response',
      }
    );
  }

  createMany(productFlightReferences: {
    productId: number;
    productFlightReferenceDTO: ProductFlightReference[];
  }): Observable<HttpResponse<ProductFlightReference[]>> {
    return this.http.post<ProductFlightReference[]>(
      `${this.url}/${this.endpoint}/multiple`,
      productFlightReferences,
      {
        observe: 'response',
      }
    );
  }
}
