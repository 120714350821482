import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StockTag } from '@pulpo/pulpo-models';
import { StockTagService } from '@pulpo/pulpo-api';
import { CommonModule, DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StockTagDateListPipe } from '../../pipes';

@Component({
  selector: 'last-stock-tag-list',
  templateUrl: './last-stock-tag-list.component.html',
  styleUrls: ['./last-stock-tag-list.component.scss'],
  standalone: true,
  imports: [
    MatTableModule,
    DatePipe,
    MatProgressSpinnerModule,
    CommonModule,
    StockTagDateListPipe,
  ],
})
export class LastStockTagListComponent implements OnInit {
  displayedColumns: string[] = [
    'date',
    'stockReference',
    'productReferences',
    'relatedDateList',
  ];

  //List
  dataSource!: MatTableDataSource<StockTag>;
  loading = true;

  constructor(private stockTagService: StockTagService) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.refreshReports();
  }

  private refreshReports() {
    this.loading = true;
    this.stockTagService.getLastStockTags().subscribe(
      (res: any) => {
        this.loading = false;
        this.dataSource = new MatTableDataSource(res.body || []);
      },
      () => {
        this.loading = false;
      }
    );
  }
}
