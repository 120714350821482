import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommissionDeposit } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from '../api.service.abstract';

@Injectable({ providedIn: 'root' })
export class CommissionDepositService extends ApiService<CommissionDeposit> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/commission-deposits');
  }

  findByProfile(id: number): Observable<HttpResponse<CommissionDeposit[]>> {
    return this.http.get<CommissionDeposit[]>(
      `${this.url}/${this.endpoint}/profile/${id}`,
      {
        observe: 'response',
      }
    );
  }

  createOrUpdateAll(profileId: number, deposits: CommissionDeposit[]) {
    return this.http.post(
      `${this.url}/${this.endpoint}/profile/${profileId}`,
      deposits,
      {
        observe: 'response',
      }
    );
  }
}
