import { Inject, Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { APP_CONFIG } from '../../../../app-config';
import { AuthService } from '@pulpo/pulpo-api';

@Injectable()
export class AuthGuard implements CanLoad {
  constructor(
    @Inject(APP_CONFIG) private environment: any,
    public authService: AuthService,
    public router: Router
  ) {}
  canLoad(): boolean {
    if (
      !(this.authService.isLoggedIn() || this.authService.canRefreshToken())
    ) {
      this.router.navigate([this.environment.loginRoute]);
      return false;
    }
    return true;
  }
}
