<div *ngIf="price" class="cursor-pointer m-2 card  corners" [class.small-card]="showAdditionnalInfos" [class.zoom]="!showAdditionnalInfos"
    [class.m-1]="showAdditionnalInfos"
    [class.activeDay]="price?.status && isDisplayedPrice && price?.status ==='AVAILABLE'"
    [class.request]="price?.status && isDisplayedPrice &&  price?.status ==='REQUEST'"
    [class.stop-sale]="!price || price?.status === null || price?.status ==='STOPSALE'"
    (click)="changeDisplayDay.emit(price);">
    <div *ngIf="price?.status || price.price">
        <div class="d-flex justify-content-between align-items-center p-2" [class.p-1]="showAdditionnalInfos">
            <div class="small text" [class.date-circle]="price?.status !== null && price?.status !=='REQUEST'"
                [class.date-circle-request]="price?.status !== null && price?.status ==='REQUEST'"
                [class.date-circle-white]="price?.status === null">
                {{price?.date?.format('DD')}}
            </div>
        </div>
        <ng-container *ngIf="price && price?.status !== null && price?.status !== 'STOPSALE'">
            <ng-container *ngIf="!price?.promoPrice">
                <div class="date-text text-center font-weight-bold text">
                    {{price?.price}} €
                </div>
                <div class="transparent text">

                </div>
            </ng-container>
            <ng-container *ngIf="price?.promoPrice">
                <div class="date-text text-center font-weight-bold text">
                    <s>{{price?.price}} €</s>
                </div>
                <div style="height: 25px!important;" class="date-text text-center font-weight-bold text">
                    {{price?.promoPrice}} €
                </div>
            </ng-container>
        </ng-container>



        <div *ngIf="price?.status === null || price?.price === null"
            class="date-text text-center font-weight-bold white text">
            {{price?.price}}
        </div>

        <div *ngIf="price?.status !== null && price?.status ==='STOPSALE'" class="text-center date-text text">
            N/D
        </div>

        <div *ngIf="price?.status === null" class="white text">
            N/D
        </div>
    </div>
    <div class="position-absolute flex-column justify-content-center align-items-start additional-infos background corners p-4 text-nowrap"
        *ngIf="showAdditionnalInfos">
        <h6 class="white">Vol : {{price.flightPrice}}</h6>
        <h6 class="white">Auto : {{price.mandatoryCarRentalPrice}}</h6>
        <h6 class="white">Option : {{price.mandatoryOptionPrice}}</h6>
        <h6 class="white">Hébergement : {{price.accommodationPrice}}</h6>
        <h6 class="white">Contrat : {{price.contractPrice}}</h6>
    </div>
</div>