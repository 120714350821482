import { Pipe, PipeTransform } from '@angular/core';
import { CityService } from '@pulpo/pulpo-api';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'CityPipe',
  standalone: true,
  pure: true,
})
export class CityPipe implements PipeTransform {
  constructor(private cityService: CityService) {}
  transform(id: number): Observable<string | undefined> {
    if (!id) return new Observable<string | undefined>();
    return this.cityService.find(id).pipe(map((res) => res.body?.name));
  }
}
