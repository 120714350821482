import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from '../api.service.abstract';
import { BookingRecord } from '@pulpo/pulpo-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingRecordService extends ApiService<BookingRecord> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'booking/api/record');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8086';
    }
  }

  generatePdf(businessId: number | string): Observable<Blob> {
    return this.http.get(`${this.url}/booking/api/pdf/${businessId}`, {
      responseType: 'blob',
    });
  }

  resendConfirmation(businessId: number | string): Observable<BookingRecord> {
    return this.http.get<BookingRecord>(
      `${this.url}/${this.endpoint}/mail/${businessId}`
    );
  }

  findIdsForStockByDate(
    stockId: number
  ): Observable<HttpResponse<{ [key: string]: string[] }>> {
    return this.http.get<{ [key: string]: string[] }>(
      `${this.url}/${this.endpoint}/stock`,
      { observe: 'response', params: { stockId: stockId.toString() } }
    );
  }
}
