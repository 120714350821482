import { Dayjs } from 'dayjs';
import { BookingStatusEnum } from '../booking/enums/bookingStatusEnum.model';
import { DistributionRecord } from './distribution-record.model';
import { OptionRecord } from './option-record.model';
import { FlightRecord } from './flight/flight-record.model';
import { InsuranceRecord } from './insurance-record.model';
import { CarRentalRecord } from './car-rental-record.model';
import { AccommodationRecord } from './accommodation-record.model';
import { TravelerRecord } from './traveler-record.model';
import { AgencyInfo } from './agency-info.model';
import { PaymentRecord } from './payment-record.model';
import { MealRecord } from './meal-record.model';
import { CustomerInfo } from './customer-info.model';
import { ProductRecord } from './product-record.model';
import { BookingPrice } from './booking-price.model';
import { RawRecord } from './raw-record.model';

export class BookingRecord {
  id?: string;
  businessId: string;
  status: BookingStatusEnum;
  creationDate: Date;
  lastUpdateDate: Dayjs;
  lastUpdateUser: string;
  opened: boolean;
  openTimeRecord: Dayjs;
  startCityName: string;
  startDate: Dayjs;
  effectiveStartDate: Dayjs;
  endDate: Dayjs;
  customerId: number;
  nbNights: number;
  remark: string;
  originalRequest: string;

  //Complex objects
  distributionRecord: DistributionRecord;
  optionRecord?: OptionRecord;
  flightRecord?: FlightRecord;
  insuranceRecord?: InsuranceRecord;
  carRentalRecord?: CarRentalRecord;
  accommodationRecord?: AccommodationRecord;
  travelerRecord: TravelerRecord;
  agencyInfo?: AgencyInfo;
  paymentRecords: PaymentRecord[];
  mealRecords?: MealRecord[];
  productRecord?: ProductRecord;
  customerInfo: CustomerInfo;
  bookingPrice: BookingPrice;

  //Raw requests
  rawRecord?: RawRecord;
}
