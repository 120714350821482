import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { StorageService } from './storage.service';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './customRouteReuse.strategy';

@Injectable({ providedIn: 'root' })
export class AuthService {
  onLogged$!: Subject<void>;

  constructor(
    @Inject(APP_CONFIG) private environment: any,
    private http: HttpClient,
    private storageService: StorageService,
    private routeReuseStrategy: RouteReuseStrategy
  ) {
    this.onLogged$ = new Subject();
  }

  login(username: string, password: string): Observable<string> {
    return this.http
      .post(
        this.environment.apiUrl + 'api/authenticate',
        {
          username,
          password,
          rememberMe: true,
        },
        { observe: 'response' }
      )
      .pipe(
        map((res: any) => {
          if (res && res.body && res.body.id_token) {
            this.storageService.setToken(res.body.id_token);
            this.storageService.setRefreshToken(res.body.token_refresh);
            this.storageService.setUser(res.body.user);
            (
              this.routeReuseStrategy as CustomReuseStrategy
            ).clearAllSavedHandles();
            this.onLogged$.next();
            return res.body.id_token;
          } else {
            return null;
          }
        })
      );
  }

  refreshToken(): Observable<string> {
    const refreshToken = this.storageService.getRefreshToken();
    if (refreshToken) {
      return this.http
        .post(
          this.environment.apiUrl + 'api/refreshtoken',
          {
            refreshToken,
          },
          { observe: 'response' }
        )
        .pipe(
          map((res: any) => {
            if (res && res.body && res.body.accessToken) {
              this.storageService.setToken(res.body.accessToken);
              this.storageService.setRefreshToken(res.body.refreshToken);
              this.storageService.setUser(res.body.user);
              this.onLogged$.next();
              return res.body.accessToken;
            } else {
              return null;
            }
          })
        );
    } else {
      return new Observable((observer) => {
        observer.next(undefined);
      });
    }
  }

  isLoggedIn() {
    return (
      this.storageService.getToken() != null &&
      this.storageService.getTokenExpirationTime() >
        new Date().getTime() / 1000 + 600
    );
  }

  canRefreshToken() {
    return this.storageService.getRefreshToken() != null;
  }

  logout() {
    this.storageService.clear();
    (this.routeReuseStrategy as CustomReuseStrategy).clearAllSavedHandles();
  }
}
