import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Insurance } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class InsuranceService extends ApiService<Insurance> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'insurance/api/insurances', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8084';
    }
  }
}
