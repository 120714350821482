import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProductInsurance } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductInsuranceService extends ApiService<ProductInsurance> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'insurance/api/product-insurances', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8084';
    }
  }

  public getByProductId(
    productId: string | number
  ): Observable<HttpResponse<ProductInsurance[]>> {
    return this.http.get<ProductInsurance[]>(
      `${this.url}/${this.endpoint}/${productId}`,
      {
        observe: 'response',
      }
    );
  }

  public addOrUpdate(
    productCarRental: ProductInsurance,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<ProductInsurance>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.post<ProductInsurance>(
      `${this.url}/${this.endpoint}`,
      { ...productCarRental },
      {
        observe: 'response',
      }
    );
  }
}
