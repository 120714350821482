import { CarRental } from './car-rental.model';

export class ProductCarRental {
  id!: string;
  productId!: number;
  carRental?: CarRental;
  title!: string;
  activeCategories!: number[];
  firstelectedCar!: FirstSelectedCarEnum;
  durationPolicy!: DurationPolicyEnum;
  companyId!: string;
}

export enum FirstSelectedCarEnum {
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
}

export enum DurationPolicyEnum {
  BY_PRODUCT_NIGHT = 'BY_PRODUCT_NIGHT',
  BY_PRODUCT_DAY = 'BY_PRODUCT_DAY',
}
