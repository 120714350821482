import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { BookingRecord, BookingStatusEnum } from '@pulpo/pulpo-models';
import {
  BookingRecordService,
  CommissionCancellationService,
} from '@pulpo/pulpo-api';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonLoadingDirective } from '../../../directives';
import { MatInputModule } from '@angular/material/input';
import { BookingRecordFlightComponent } from './booking-record-flight/booking-record-flight.component';
import { BookingRecordTravelerComponent } from './booking-record-traveler/booking-record-traveler.component';
import { BookingRecordAccommodationComponent } from './booking-record-accommodation/booking-record-accommodation.component';
import { BookingRecordInsuranceComponent } from './booking-record-insurance/booking-record-insurance.component';
import { BookingRecordCarRentalComponent } from './booking-record-car-rental/booking-record-car-rental.component';
import { BookingRecordPaymentComponent } from './booking-record-payment/booking-record-payment.component';
import { BookingRecordOptionComponent } from './booking-record-option/booking-record-option.component';
import dayjs from 'dayjs';
import { BookingRecordBillComponent } from './booking-record-bill/booking-record-bill.component';
import { PulpoSnackbarComponent } from '../../pulpo-snackbar/pulpo-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { XmlFormatPipe } from '../../../pipes';

@Component({
  selector: 'booking-record-details',
  templateUrl: './booking-record-details.component.html',
  styleUrls: ['./booking-record-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatCardModule,
    MatButtonModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTabsModule,
    MatTooltipModule,
    MatButtonLoadingDirective,
    MatInputModule,
    MatDialogModule,
    BookingRecordFlightComponent,
    BookingRecordTravelerComponent,
    BookingRecordAccommodationComponent,
    BookingRecordInsuranceComponent,
    BookingRecordCarRentalComponent,
    BookingRecordPaymentComponent,
    BookingRecordOptionComponent,
    BookingRecordBillComponent,
    CodemirrorModule,
    XmlFormatPipe,
    FormsModule,
  ],
})
export class BookingRecordDetailsComponent implements OnInit {
  bookingRecord: BookingRecord;
  editForm: FormGroup;
  BookingStatusEnum = BookingStatusEnum;
  dayjs = dayjs;
  isLoading = false;
  isCurrentlySupplier: boolean;
  producerId: number;
  options = {
    lineNumbers: false,
    theme: 'material',
    mode: 'application/xml',
    readOnly: true,
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      'CodeMirror-linenumbers',
      'CodeMirror-foldgutter',
      'CodeMirror-lint-markers',
    ],
    autoCloseBrackets: true,
    matchBrackets: true,
    background: '#f5f5f5',
  };

  constructor(
    protected activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private bookingRecordService: BookingRecordService,
    protected commissionCancellationService: CommissionCancellationService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ bookingRecord }) => {
      this.bookingRecord = bookingRecord as BookingRecord;
      this.editForm = this.fb.group({
        product: [this.bookingRecord.productRecord?.productName],
        productId: [this.bookingRecord.productRecord?.productId],
        destination: [this.bookingRecord.productRecord?.destinationName],
        creationDate: [this.bookingRecord.creationDate],
        lastUpdate: [this.bookingRecord.lastUpdateDate],
        distribution: [this.bookingRecord.distributionRecord.distribution],
        producer: [this.bookingRecord.distributionRecord.producerCompanyName],
        supplier: [this.bookingRecord.distributionRecord.supplierCompanyName],
      });
      this.isCurrentlySupplier =
        Number(bookingRecord.distributionRecord.supplierId) ===
        Number(bookingRecord.distributionRecord.producerCompanyId);
      this.producerId = this.activatedRoute.snapshot.params['producerId'];

      if (!this.bookingRecord.opened && this.bookingRecord.businessId) {
        this.bookingRecord.openTimeRecord = dayjs();
        this.onConsultChange(true);
      }
    });
  }

  generatePdf(bookingRecord: BookingRecord) {
    this.isLoading = true;
    this.bookingRecordService.generatePdf(bookingRecord.businessId).subscribe(
      (data: any) => {
        const blob = new Blob([data], { type: 'application/pdf' });

        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'Res_' + bookingRecord.id + '.pdf';
        link.click();
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  onConsultChange(value: boolean) {
    this.bookingRecord.opened = value;
    this.bookingRecordService.update(this.bookingRecord).subscribe((res) => {
      if (res) {
        this.snackBar.openFromComponent(PulpoSnackbarComponent, {
          data: {
            title: 'Modification',
            message:
              'Vous venez de changer le statut de consultation de cette réservation',
          },
          duration: 2000,
          panelClass: ['snackbar'],
        });
        this.bookingRecord = res.body as BookingRecord;
      }
    });
  }

  get startDelay() {
    if (this.bookingRecord.flightRecord?.offer) {
      const wayTrip = this.bookingRecord.flightRecord.offer.way?.segments || [];
      const wayArrival = dayjs(wayTrip[wayTrip.length - 1].arrivalTime);
      let startDate = dayjs(this.bookingRecord.startDate, 'DD/MM/YYYY');
      if (!startDate.isValid()) {
        startDate = dayjs(this.bookingRecord.startDate, 'YYYY-MM-DD');
      }
      if (startDate.date() !== wayArrival.date() && wayArrival.hour() > 6) {
        return '  ( Arrivée +1 )';
      }
    }
    return '';
  }

  resendBookingConfirmation() {
    this.isLoading = true;
    if (this.bookingRecord && this.bookingRecord.businessId) {
      this.bookingRecordService
        .resendConfirmation(this.bookingRecord.businessId)
        .subscribe(
          (res: any) => {
            this.isLoading = false;
            if (res.body) {
              this.snackBar.openFromComponent(PulpoSnackbarComponent, {
                data: {
                  title: 'Message envoyé',
                  message: 'Les mails de confirmation ont bien été renvoyés',
                },
                duration: 4000,
                panelClass: ['snackbar'],
              });
            }
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }
}
