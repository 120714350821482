<booking-consult-filter *ngIf="showOpened" (filterEvent)="applyFilter($event)" (exportResa)="exportResa()">
</booking-consult-filter>

<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged()" class="w-100 h-100 table-wrapper"
  data-testid="booking-list">

  <ng-container matColumnDef="id">

    <th mat-header-cell *matHeaderCellDef class="d-flex align-items-center h-100">
      <mat-checkbox *ngIf="showOpened" [checked]="allChecked" color="primary" (change)="checkAll()">

      </mat-checkbox>
      <div mat-sort-header class="pl-2">N° Réservation</div>
    </th>
    <td mat-cell *matCellDef="let booking">
      <mat-checkbox *ngIf="showOpened" [checked]="selectedBookings[booking.id]" color="primary"
        (change)="toggleCheck(booking)" data-testid="booking-list-check-export">
        {{ booking.id }}
      </mat-checkbox>
      <span *ngIf="!showOpened">{{ booking.id }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
    <td mat-cell *matCellDef="let booking">{{ booking.status }}</td>
  </ng-container>
  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Réservation</th>
    <td mat-cell *matCellDef="let booking">{{ booking.bookingDate | date: 'dd/MM/yyyy': '+0200'}}</td>
  </ng-container>
  <ng-container matColumnDef="destination">
    <th mat-header-cell *matHeaderCellDef>Destination</th>
    <td mat-cell *matCellDef="let booking">{{ booking.destination }}</td>
  </ng-container>
  <ng-container matColumnDef="productReference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Code Produit</th>
    <td mat-cell *matCellDef="let booking">{{ booking.productReference }}</td>
  </ng-container>
  <ng-container matColumnDef="product">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Produit</th>
    <td mat-cell *matCellDef="let booking">{{ booking.productName }}</td>
  </ng-container>
  <ng-container matColumnDef="startCity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Départ</th>
    <td mat-cell *matCellDef="let booking">
      {{ booking.startCity }}
    </td>
  </ng-container>
  <ng-container matColumnDef="departureDate">
    <th mat-header-cell *matHeaderCellDef>Date voyage</th>
    <td mat-cell *matCellDef="let booking">{{ booking.startDate | date: 'dd/MM/yyyy': '+0200' }}</td>
  </ng-container>
  <ng-container matColumnDef="nbNights">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nuits</th>
    <td mat-cell *matCellDef="let booking" class="centre">{{ booking.nbNight }}</td>
  </ng-container>
  <ng-container matColumnDef="categoryName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="centre">Catégorie</th>
    <td mat-cell *matCellDef="let booking" class="centre">{{ booking.category }}</td>
  </ng-container>
  <ng-container matColumnDef="firstPax">
    <th mat-header-cell *matHeaderCellDef>1er pax</th>
    <td mat-cell *matCellDef="let booking">{{ booking.firstPax }}</td>
  </ng-container>
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>Total dossier</th>
    <td mat-cell *matCellDef="let booking">{{ booking.totalPrice + '€' }}</td>
  </ng-container>
  <ng-container matColumnDef="supplier">
    <th mat-header-cell *matHeaderCellDef>Producteur</th>
    <td mat-cell *matCellDef="let booking" class="centre">{{ booking.producer }}</td>
  </ng-container>
  <ng-container matColumnDef="seller">
    <th mat-header-cell *matHeaderCellDef>Distributeur</th>
    <td mat-cell *matCellDef="let booking">{{ booking.supplier }}</td>
  </ng-container>
  <ng-container matColumnDef="distribution">
    <th mat-header-cell *matHeaderCellDef>Canal</th>
    <td mat-cell *matCellDef="let booking">{{ booking.canal }}</td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let booking">
      <button *ngIf="showOpened" mat-icon-button matTooltip="Afficher les détails"
        data-testid="booking-list-open-details" [routerLink]="['/booking', booking.id, 'view']"
        [queryParams]="producerId ? {producerId: producerId} : {}">
        <mat-icon class="colored">zoom_in</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="consult">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let booking"><span>
        <mat-icon *ngIf="showOpened" [class.orange-colored]="!booking.opened"
          [class.transparent-colored]="booking.opened">album
        </mat-icon>
      </span></td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <mat-spinner diameter="50" class="centered-spinner" style="margin-top: 20px;" *ngIf="loading"></mat-spinner>

    <td *ngIf="!loading" class="mat-cell">Aucune réservation à afficher</td>
  </tr>
</table>

<mat-paginator *ngIf="showOpened" class="col-sm-12 w-100" [length]="nbBookings" [pageSize]="itemsPerPage"
  (page)="changePage()" aria-label="Select page of bookings"></mat-paginator>