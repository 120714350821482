import { Pipe, PipeTransform } from '@angular/core';
import { ProductUtils } from '../utils';

@Pipe({
  name: 'AccommodationRecordRoomCategoryPipe',
  standalone: true,
})
export class AccommodationRecordRoomCategoryPipe implements PipeTransform {
  transform(value: any): string {
    return ProductUtils.translateFromCategory(value);
  }
}
