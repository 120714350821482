import { Dayjs } from 'dayjs';
import { AccommodationDescription } from './accommodation-description.model';
import { AccommodationMeal } from './accommodation-meal.model';
import { AccommodationRoomCategory } from './accommodation-room-category.model';

export class Accommodation {
  id!: string;
  reference!: string;
  name!: string;
  active!: boolean;
  lastupdate!: Date;
  updatedBy!: string;
  companyId!: string;
  type!: string;
  categories!: string[];

  descriptions!: AccommodationDescription[];
  roomCategories!: AccommodationRoomCategory[];
  mealPlans!: AccommodationMeal[];

  countryCode!: string;
  countryName!: string;
  address!: string;
  destinationCode!: string;
  destinationName!: string;
  source!: string;
  hotelCode!: number;

  startCachePeriod?: Dayjs | string;
  endCachePeriod?: Dayjs | string;
  userId?: string;
}
