import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';
import { VersionUpdateSnackbarComponent } from '../components/version-update-snackbar/version-update-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private snackbar: MatSnackBar
  ) {}

  setup() {
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everyHours$ = interval(1 * 60 * 1000);
    const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHours$);

    console.log('Setting up update service', this.updates.isEnabled);

    this.updates.versionUpdates.subscribe((evt) => {
      console.log('Version update event:', evt);
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${evt.latestVersion.hash}`
          );
          this.snackbar.openFromComponent(VersionUpdateSnackbarComponent, {
            panelClass: ['update-snackbar'],
          });
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(
            `Failed to install app version '${evt.version.hash}': ${evt.error}`
          );
          break;
      }
    });

    everyHoursOnceAppIsStable$.subscribe(() =>
      this.updates
        .checkForUpdate()
        .then(() => console.log('Checking for updates'))
    );
  }
}
