<mat-grid-list cols="10" rowHeight="150px" class="header">
    <mat-grid-tile colspan="3" rowspan="1">
        <div class="ribbon" *ngIf="this.ribbonEnv">
            <span *ngIf="this.ribbonEnv">{{ ribbonEnv }}</span>
        </div>
        <div class="row w-100 justify-content-start" *ngIf="this.currentUser">
            <div class="col-sm-6 w-50 d-flex justify-content-center align-items-center">
                <img class="m-4 photo-logo corners" *ngIf="currentUser.company && currentUser.company.logoUrl" alt=""
                    [src]="currentUser.company.logoUrl" />
                <img class="m-4 photo-logo corners" *ngIf="!currentUser.company || !currentUser.company.logoUrl"
                    src="assets/img/pulpo_logo.JPG" alt="" />
            </div>
            <div class="col-sm-6 w-50 d-flex justify-content-center align-items-center">
                <img class="m-4 photo-logo corners" *ngIf="this.accountImage" [src]="this.accountImage.url" alt="" />
            </div>
        </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="1">
        <div class="row w-100 justify-content-start">
            <div>
                <div *ngIf="currentUser && currentUser.role && currentUser.role.activated" class="m-1">
                    <span>
                        <mat-icon class="white pb-1">badge</mat-icon> {{currentUser.role.name}}
                    </span>
                </div>
                <div *ngIf="currentUser && (currentUser.pseudo || currentUser.lastName)" class="m-1">
                    <span *ngIf="currentUser">
                        <mat-icon class="white pb-1">person</mat-icon> {{currentUser.pseudo}}
                    </span>
                </div>
                <div *ngIf="currentUser && currentUser.phone" class="m-1">
                    <span *ngIf="currentUser">
                        <mat-icon class="white pb-1">call</mat-icon> {{currentUser.phone}}
                    </span>
                </div>
                <div *ngIf="currentUser && currentUser.email" class="m-1">
                    <span *ngIf="currentUser">
                        <mat-icon class="white pb-1" style="padding-top:-15px!important;">mail_outline</mat-icon>
                        {{currentUser.email}}
                    </span>
                </div>
            </div>
        </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="5" rowspan="1">
        <div class="w-100 justify-content-end" style="display: flex;margin-top:100px!important;">
            <!-- Home page -->
            <button style="padding:0" matTooltip="Accueil" class="button-menu" mat-icon-button routerLink="/home">
                <mat-icon>home</mat-icon>
            </button>
            <!-- Booking -->
            <button mat-button *ngIf="currentUser?.company" class="button-menu"
                [matMenuTriggerFor]="booking">Réservations</button>
            <mat-menu #booking="matMenu" class="background">
                <button mat-menu-item class="white" routerLink="/booking"
                    *hasPermissions="[{name:'prodBookingRecord', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">list</mat-icon><span> Dossiers de réservations </span>
                </button>
                <button mat-menu-item class="white" routerLink="/booking-record"
                    *hasPermissions="[{name:'prodBookingRecord', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">list</mat-icon><span> Nouveaux dossiers de réservations </span>
                </button>
            </mat-menu>

            <!-- Supplier production -->
            <button *ngIf="currentUser?.company" mat-button class="button-menu"
                [matMenuTriggerFor]="production">Production</button>
            <mat-menu #production="matMenu" class="background">
                <button mat-menu-item class="white" routerLink="/insurance"
                    *hasPermissions="[{name:'prodProducerInsurance', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">description</mat-icon><span> Assurances</span>
                </button>
                <button mat-menu-item class="white" routerLink="/destination"
                    *hasPermissions="[{name:'prodProducerDestination', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">explore</mat-icon><span> Destinations</span>
                </button>
                <button mat-menu-item class="white" routerLink="/formality"
                    *hasPermissions="[{name:'prodProducerFormality', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">recent_actors</mat-icon><span> Formalités</span>
                </button>
                <!-- <button mat-menu-item class="white" routerLink="/accommodation"
                    *hideForEnvs="['prod', 'staging', 'dev']">
                    <mat-icon class="white-all">hotel</mat-icon><span> Hébergements</span>
                </button> -->
                <button mat-menu-item class="white" routerLink="/new-accommodation">
                    <mat-icon class="white-all">hotel</mat-icon><span>Nouveaux Hébergements</span>
                </button>
                <button mat-menu-item class="white" routerLink="/car-rental">
                    <mat-icon class="white-all">car_rental</mat-icon><span> Location de véhicule</span>
                </button>
                <button mat-menu-item class="white" routerLink="/option"
                    *hasPermissions="[{name:'prodProducerOption', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">style</mat-icon><span> Options</span>
                </button>
                <button mat-menu-item class="white" routerLink="/product"
                    *hasPermissions="[{name:'prodProducerProduct', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">widgets</mat-icon><span> Produits</span>
                </button>
                <button mat-menu-item class="white" routerLink="/stock"
                    *hasPermissions="[{name:'prodProducerStock', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">view_module</mat-icon><span> Stocks</span>
                </button>
                <button mat-menu-item class="white" routerLink="/tag" *hideForEnvs="['prod']">
                    <mat-icon class="white-all">local_offer</mat-icon><span> Tags</span>
                </button>
                <button mat-menu-item class="white" routerLink="/flight"
                    *hasPermissions="[{name:'prodProducerFlight', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">flight</mat-icon><span> Vols</span>
                </button>


            </mat-menu>
            <!-- Client distribution  -->
            <button mat-button class="button-menu"
                *hasPermissions="[{name:'prodSupplierHome', right:UserRightEnum.READ}]"
                [matMenuTriggerFor]="distribution">
                Distribution</button>
            <mat-menu #distribution="matMenu" class="background">
                <button mat-menu-item class="white" routerLink="/distribution">
                    <mat-icon class="white-all">storefront</mat-icon><span> Distributeurs</span>
                </button>
            </mat-menu>
            <!-- user administration  -->
            <button mat-button class="button-menu" [matMenuTriggerFor]="administration"
                *ngIf="currentUser">Administration</button>
            <mat-menu #administration="matMenu" class="background">
                <button class="white-all" mat-menu-item routerLink="/account/panel">
                    <mat-icon class="white-all">person</mat-icon><span> Espace personnel</span>
                </button>
                <button class="white-all" mat-menu-item routerLink="/role"
                    *hasPermissions="[{name:'prodAdministrationRole', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">person</mat-icon><span> Droits Utilisateurs</span>
                </button>
                <button class="white-all" mat-menu-item routerLink="/company"
                    *hasPermissions="[{name:'prodAdministrationCompany', right:UserRightEnum.READ}]">
                    <mat-icon class="white-all">person</mat-icon><span>Pulpo Companie</span>
                </button>
                <ng-container *hasPermissions="[{name:'prodAdministrationParameter', right:UserRightEnum.READ}]">
                    <button class="white-all" mat-menu-item routerLink="/settings">
                        <mat-icon class="white-all">settings</mat-icon><span> Paramétrage</span>
                    </button>
                </ng-container>
                <button class="red-all corners" mat-menu-item (click)="logout()">
                    <mat-icon class="white-all">exit_to_app</mat-icon><span> Déconnexion</span>
                </button>
            </mat-menu>
        </div>
    </mat-grid-tile>
</mat-grid-list>