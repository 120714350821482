<flight-booking-consult-filter (filterEvent)="applyFilter($event)"></flight-booking-consult-filter>

<div class="row justify-content-center w-100 table-wrapper">
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged()" class="pulpo-table w-100">
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> N° réservation </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.id}} </td>
        </ng-container>
        <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PNR </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.reference}} </td>
        </ng-container>
        <ng-container matColumnDef="originCity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville de depart </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.originCity}} </td>
        </ng-container>
        <ng-container matColumnDef="destinationCity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ville d'arrivée </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.destinationCity}} </td>
        </ng-container>

        <ng-container matColumnDef="pax">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Pax </th>
            <td mat-cell *matCellDef="let flightBooking"> {{getPax(flightBooking)}} </td>
        </ng-container>
        <ng-container matColumnDef="firstPassenger">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Premier passager </th>
            <td mat-cell *matCellDef="let flightBooking"> {{getFirstPassengerName(flightBooking)}} </td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de création </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.creationDate | date:'dd/MM/yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="channel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Canal </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.channel}} </td>
        </ng-container>
        <ng-container matColumnDef="supplierName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Producteur </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.supplierName}} </td>
        </ng-container>
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Source </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.source}} </td>
        </ng-container>
        <ng-container matColumnDef="totalTtc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Total TTC </th>
            <td mat-cell *matCellDef="let flightBooking"> {{flightBooking.totalTtc}} €</td>
        </ng-container>
        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let flightBooking">
                <button mat-icon-button matTooltip="Afficher les détails">
                    <mat-icon class="colored">zoom_in</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="consult">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let flightBooking"><span>
                    <mat-icon [class.orange-colored]="!flightBooking.opened"
                        [class.transparent-colored]="flightBooking.opened">album
                    </mat-icon>
                </span></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openFlightBookingDetails(row.id)"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row w-100" *matNoDataRow>
            <mat-spinner diameter="50" class="centered-spinner" style="margin-top: 20px;" *ngIf="loading"></mat-spinner>
        </tr>
    </table>
    <mat-paginator class="w-100" [length]="nbFlightBooking" (page)="changePage()" [pageSize]="itemsPerPage"
        aria-label="Select page of flights booking"></mat-paginator>
</div>