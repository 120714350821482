import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAgency } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';

@Injectable({ providedIn: 'root' })
export class AgencyService extends ApiService<IAgency> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/agencies');
  }

  findWithSupplier(id: number): Observable<HttpResponse<IAgency[]>> {
    return this.http.get<IAgency[]>(`${this.url}/${this.endpoint}/supplier`, {
      params: { supplierId: id },
      observe: 'response',
    });
  }
}
