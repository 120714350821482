<div class="container-fluid  " style="overflow: hidden;">
   <div class="w-100">
       <div class="row justify-content-center mt-2 w-100">
           <h4>{{this.message}}</h4>
       </div>
       <div class="row justify-content-center mt-2 w-100">
           <p> En validant votre demande, votre réservation sera actualisée.</p>
       </div>
       <div class="row justify-content-center mt-lg-5 w-100">
           <div class="col-sm-1">
           </div>
           <div class="col-sm-4 text-right ">
               <span style="color:darkred!important;" > Confirmer cette décision </span>
           </div>
           <div class="col-sm-1">
               <button mat-mini-fab class="btn-enabled-red" (click)="changeStatus()"><mat-icon>check</mat-icon></button>
           </div>
           <div class="col-sm-1">
               <button mat-mini-fab class="btn-pulpo"  (click)="dismissDialog()"><mat-icon>close</mat-icon></button>
           </div>
           <div class="col-sm-4">
               <span style="color:darkred!important;" > Annuler la décision </span>
           </div>
           <div class="col-sm-1">
           </div>
       </div>
   </div>
</div>
