import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Booking,
  BookingListInput,
  BookingListOutput,
  BookingQuoteInput,
  BookingQuoteOutput,
  BookingBookInput,
  BookingBookOutput,
  FlightTicketing,
  FlightTicketingOutput,
} from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class BookingBookService extends ApiService<Booking> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/engine/booking');
  }

  list(
    listInput: BookingListInput
  ): Observable<HttpResponse<BookingListOutput>> {
    console.log(this.endpoint);
    return this.http.post<BookingListOutput>(
      `${this.url}/${this.endpoint}/list`,
      {
        ...listInput,
      },
      {
        observe: 'response',
        headers: new HttpHeaders({ timeout: `${999000}` }),
      }
    );
  }

  quote(
    quoteInput: BookingQuoteInput
  ): Observable<HttpResponse<BookingQuoteOutput>> {
    return this.http.post<BookingQuoteOutput>(
      `${this.url}/${this.endpoint}/quote`,
      {
        ...quoteInput,
      },
      { observe: 'response' }
    );
  }

  book(
    bookInput: BookingBookInput
  ): Observable<HttpResponse<BookingBookOutput>> {
    return this.http.post<BookingBookOutput>(
      `${this.url}/${this.endpoint}/book`,
      {
        ...bookInput,
      },
      { observe: 'response' }
    );
  }

  ticketing(
    query: FlightTicketing
  ): Observable<HttpResponse<FlightTicketingOutput>> {
    return this.http.post<any>(
      `${this.url}/${this.endpoint}/flight/ticketing`,
      query,
      {
        observe: 'response',
      }
    );
  }
}
