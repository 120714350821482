import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export function emailListValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      control.value === '' ||
      !control.value ||
      validateEmails(control.value)
    ) {
      return null;
    }
    return { emails: true };
  };
}

function validateEmails(emails: string) {
  return (
    emails
      .split(';')
      .map((email) => Validators.email(<AbstractControl>{ value: email }))
      .find((_) => _ !== null) === undefined
  );
}
