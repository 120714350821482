export enum EnumXFTType {
  ALL_INCLUSIVE = 'ALL_INCLUSIVE',
  AMERICAN = 'AMERICAN',
  BREAKFAST = 'BREAKFAST',
  CARRIBEAN = 'CARRIBEAN',
  CONTINENTAL = 'CONTINENTAL',
  EUROPEAN = 'EUROPEAN',
  FAMILY = 'FAMILY',
  FULLBOARD = 'FULLBOARD',
  HALFBOARD = 'HALFBOARD',
  UNKNOWN = 'UNKNOWN',
  NO_MEAL_INCLUDED = 'NO_MEAL_INCLUDED',
  PROGRAM = 'PROGRAM',
  SNACK = 'SNACK'
}
