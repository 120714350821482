<div class="row m-2">
  <div class="col-sm-1"></div>
  <div class="col-sm-11">
    <mat-card *ngFor="let payment of paymentRecords" class="w-85 background corners mat-elevation-z4">
      <div class="row w-100">
        <!--TODO when data available
        <div class="col-sm-3">
          <h5>Date :</h5>
          <h5 class="font-weight-bold">
            {{
            payment.date
            ? this.parseDate(payment.date)
            : this.parseDate(booking.creationDate)
            }}
          </h5>
        </div>
        
        <div class="col-sm-3">
          <h5>Etat :</h5>
          <h5 class="font-weight-bold">{{ payment.state }}</h5>
        </div>
        <div class="col-sm-3">
          <h5>Statut :</h5>
          <h5 class="font-weight-bold">{{ payment.role }}</h5>
        </div> -->
        <div class="col-sm-3">
          <h5>Montant :</h5>
          <h5 class="font-weight-bold">
            {{ payment.totalPrice }}&nbsp;€
          </h5>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="col-sm-1"></div>
</div>