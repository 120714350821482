import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import {
  AuthService,
  ParameterService,
  PictureService,
  StorageService,
} from '@pulpo/pulpo-api';
import {
  IPiwigoImage,
  PictureTypeEnum,
  User,
  UserRightEnum,
} from '@pulpo/pulpo-models';
import {
  HasPermissionsDirective,
  HideForEnvsDirective,
} from '../../directives';
import { APP_CONFIG } from '@pulpo/app-config';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['navbar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    HasPermissionsDirective,
    HideForEnvsDirective,
    RouterModule,
  ],
})
export class NavbarComponent implements OnInit {
  UserRightEnum = UserRightEnum;
  isNavbarCollapsed: boolean;
  languages: any[];
  version: string;
  currentUser: User | undefined;
  logo: IPiwigoImage;
  isCompanyMember = false;
  accountImage: any;
  ribbonEnv: string;
  environment: any;
  constructor(
    @Inject(APP_CONFIG) environment: any,
    private pictureService: PictureService,
    private changeDetectorRef: ChangeDetectorRef,
    private parameterService: ParameterService,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService
  ) {
    this.isNavbarCollapsed = true;
    this.environment = environment;
  }

  ngOnInit() {
    if (
      this.authService.isLoggedIn() &&
      (this.currentUser === null || this.currentUser === undefined)
    ) {
      this.setupRibbon();
      this.logged();
    }
    this.authService.onLogged$.subscribe(() => {
      console.log('authService.onLogged$');
      this.setupRibbon();
      this.logged();
    });
  }

  setupRibbon() {
    console.log(this.environment);
    if (this.environment.name === 'localdev') {
      this.parameterService.search('RIBBON_LOCAL').subscribe(
        (insRes: any) => {
          if (insRes.body && insRes.body.value) {
            this.ribbonEnv = insRes.body.value;
            this.changeDetectorRef.detectChanges();
          } else {
            this.ribbonEnv = 'local - development';
            this.changeDetectorRef.detectChanges();
          }
        },
        () => (this.ribbonEnv = 'local - development')
      );
    }
    if (this.environment.name === 'dev') {
      this.parameterService.search('RIBBON_DEV').subscribe(
        (insRes: any) => {
          if (insRes.body && insRes.body.value) {
            this.ribbonEnv = insRes.body.value;
            this.changeDetectorRef.detectChanges();
          } else {
            this.ribbonEnv = 'development';
            this.changeDetectorRef.detectChanges();
          }
        },
        () => (this.ribbonEnv = 'development')
      );
    }
    if (this.environment.name === 'staging') {
      this.parameterService.search('RIBBON_STAGING').subscribe(
        (insRes: any) => {
          if (insRes.body && insRes.body.value) {
            this.ribbonEnv = insRes.body.value;
            this.changeDetectorRef.detectChanges();
          } else {
            this.ribbonEnv = 'staging';
            this.changeDetectorRef.detectChanges();
          }
        },
        () => (this.ribbonEnv = 'staging')
      );
    }
  }
  logged() {
    this.currentUser = this.storageService.getUser();

    if (this.currentUser) {
      this.pictureService
        .getPicturesByTypeAndId(PictureTypeEnum.USER, this.currentUser.id)
        .subscribe((res: any) => {
          if (res.body) {
            this.accountImage = res.body[0];
            this.changeDetectorRef.detectChanges();
          }
        });
    }
  }

  collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  login() {
    this.router.navigate(['/login']);
  }

  logout() {
    this.authService.logout();
    this.currentUser = undefined;
    this.router.navigate(['/login']);
    this.changeDetectorRef.detectChanges();
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }
}
