import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  EnumOptionStatus,
  EnumStockStatus,
  IStock,
  Stock,
  TypeBaseEnum,
  WHRoomAvailabilityStatusEnum,
} from '@pulpo/pulpo-models';
import {
  CellClickedEvent,
  CellStyle,
  ColDef,
  GridApi,
  GridReadyEvent,
  RowClassParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';

import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AgGridModule } from 'ag-grid-angular';
import { MatButtonLoadingDirective } from '../../directives';
import { StockUtils } from '../../utils';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

@Component({
  selector: 'pulpo-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatButtonLoadingDirective,
    MatProgressSpinnerModule,
    AgGridModule,
  ],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() data: any;
  @Input() columns: ColDef[];
  @Input() columnNames: (string | number)[];
  @Input() isSaving = false;
  @Input() type:
    | 'STOCK'
    | 'PRODUCT'
    | 'FLIGHT'
    | 'NEW-FLIGHT'
    | 'ACCOMHB'
    | TypeBaseEnum
    | 'NEW-ACCOM'
    | 'NEW-ACCOM-WH';
  @Input() stock: Stock | IStock;
  @Input() byMonth = false;
  @Input() promo = false;
  @Input() readonly = false;
  @Input() bookingsByDate: { [key: string]: string[] } = {};
  @Input() showButtons = true;
  @Input() showBack = true;
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() previous: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any[]> = new EventEmitter();
  @Output() deleteResa: EventEmitter<any> = new EventEmitter();
  currentBookings: any[] | null = null;
  modalLeft: number | null = null;
  modalTop: number | null = null;
  isTableValid = true;
  currentDate = dayjs();
  dayjs = dayjs;
  dataMap = new Map<string, any[]>();
  columnTypes: any = {
    numericColumn: {},
  };
  selectedRows: any[] = [];
  editedData: any[] = [];
  private gridApi!: GridApi<any>;
  selectedFlightCell: any;
  currentLogging?: string;

  rowClassRules = {
    'orange-color-light': (params: RowClassParams) => {
      switch (this.type) {
        case TypeBaseEnum.UNIT || TypeBaseEnum.PERSON:
          return params.data.status === EnumOptionStatus.STOPSALE;
        case 'STOCK':
          return (
            params.data.status === EnumStockStatus.STOPSALE ||
            params.data.available === 0 ||
            (dayjs()
              .clone()
              .isSameOrAfter(
                dayjs(params.data.date).subtract(params.data.retro, 'day')
              ) &&
              params.data.statusAfter === EnumStockStatus.STOPSALE)
          );
        case 'PRODUCT':
          return (
            params.data.activated &&
            (params.data.stockStatus === EnumStockStatus.STOPSALE ||
              (dayjs()
                .clone()
                .add(params.data.retrocession, 'day')
                .isAfter(params.data.date) &&
                params.data.retrocessionStockStatus ===
                  EnumStockStatus.STOPSALE))
          );
        default:
          return params.data.stockStatus === EnumStockStatus.STOPSALE;
      }
    },
    'blue-color-light': (params: RowClassParams) => {
      switch (this.type) {
        case TypeBaseEnum.UNIT:
          return (
            params.data.status === EnumOptionStatus.AVAILABLE &&
            params.data.unitPrice === 0
          );
        case 'STOCK':
          return (
            params.data.status === EnumStockStatus.REQUEST ||
            (dayjs()
              .clone()
              .isSameOrAfter(
                dayjs(params.data.date).subtract(params.data.retro, 'day')
              ) &&
              params.data.statusAfter === EnumStockStatus.REQUEST)
          );
        case 'PRODUCT':
          return (
            params.data.activated &&
            (params.data.stockStatus === EnumStockStatus.REQUEST ||
              (dayjs()
                .clone()
                .add(params.data.retrocession, 'day')
                .isAfter(dayjs(params.data.date)) &&
                params.data.retrocessionStockStatus ===
                  EnumStockStatus.REQUEST))
          );
        case TypeBaseEnum.PERSON:
          return (
            params.data.status === EnumOptionStatus.AVAILABLE &&
            (params.data.adultPrice === 0 || params.data.childPrice === 0)
          );
        default:
          return (
            params.data.stockStatus === EnumStockStatus.AVAILABLE &&
            params.data.doublePrice == 0 &&
            params.data.childOnePrice == 0
          );
      }
    },
    'green-color-light': (params: RowClassParams) => {
      switch (this.type) {
        case TypeBaseEnum.UNIT:
          return (
            params.data.status === EnumOptionStatus.AVAILABLE &&
            (params.data.unitPrice === '' || params.data.unitPrice !== 0)
          );
        case 'STOCK':
          return (
            params.data.status === EnumStockStatus.AVAILABLE &&
            params.data.available > 0 &&
            dayjs().isBefore(
              dayjs(params.data.date).subtract(params.data.retro, 'day')
            )
          );
        case 'PRODUCT':
          return params.data.activated;
        case TypeBaseEnum.PERSON:
          return (
            params.data.status === EnumOptionStatus.AVAILABLE &&
            (params.data.adultPrice !== 0 || params.data.childPrice !== 0)
          );
        default:
          return (
            this.type !== 'ACCOMHB' &&
            ((params.data.stockStatus === EnumStockStatus.AVAILABLE &&
              params.data.doublePrice !== 0) ||
              params.data.childOnePrice !== 0 ||
              params.data.babiesPrice !== 0)
          );
      }
    },
    'red-color-light': (params: RowClassParams) => {
      return this.type == 'PRODUCT' && !params.data.activated;
    },
  };

  ngOnInit(): void {
    this.setupColumns();
  }

  reset() {
    this.dataMap = new Map<string, any[]>();
    this.editedData = [];
  }

  saveData() {
    this.save.emit(this.editedData);
    this.editedData = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.columnNames?.currentValue !== changes.columnNames?.previousValue
    ) {
      this.setupColumns(true);
    }
    if (this.type == 'ACCOMHB') {
      this.reset();
    }
    this.formatData(this.data);
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  formatData(data: any[]) {
    let dateField = 'date';
    if (this.type === 'NEW-FLIGHT') {
      dateField = 'startDate';
    }
    if (data && data.length > 0) {
      for (const element of data) {
        const monthYear = dayjs(element[dateField]).format('MM/YYYY');
        if (!this.dataMap.has(monthYear)) {
          this.dataMap.set(monthYear, []);
        }
        if (
          (this.dataMap.get(monthYear)?.length || 0) <
            dayjs(element[dateField]).daysInMonth() &&
          (this.dataMap.get(monthYear) || []).findIndex(
            (e) => e[dateField] === element[dateField]
          ) === -1
        ) {
          (this.dataMap.get(monthYear) || []).push(element);
        }
      }
    }
    for (let [, value] of this.dataMap) {
      value = value.sort((a, b) => {
        return dayjs(a[dateField]).diff(dayjs(b[dateField]));
      });
    }
    this.padMonthWithEmptyData(this.currentDate.format('MM/YYYY'));
  }

  protected showLogging(event: any) {
    if (event.data.logging) {
      this.modalTop = event.event.clientY;
      this.modalLeft = event.event.clientX;
      this.currentLogging = event.data.logging;
    }
  }

  protected hideLogging() {
    this.currentLogging = undefined;
  }

  protected startCellEdit() {
    this.selectedRows = this.gridApi.getSelectedRows();
  }

  protected cellEdit(event: any) {
    if (this.selectedRows && this.selectedRows.length > 0) {
      const editedColumn = event.colDef.field;
      for (const row of this.selectedRows) {
        row[editedColumn] = event.value;
        const date = dayjs(row.date ? row.date : row.startDate)
          .clone()
          .add(4, 'hours');
        const index = this.editedData.findIndex((e) => {
          return (
            dayjs(e.date ? e.date : e.startDate).format('DD/MM/YYYY') ===
            dayjs(date).format('DD/MM/YYYY')
          );
        });
        if (index === -1) {
          this.editedData.push(row);
        } else {
          this.editedData[index] = row;
        }
      }
      this.gridApi.refreshCells();
    }
  }

  protected getLastMonth() {
    this.currentDate = this.currentDate.subtract(1, 'months');
    const monthYear = this.currentDate.format('MM/YYYY');
    if (!this.dataMap.has(monthYear)) {
      if (this.byMonth) {
        this.previous.emit(this.currentDate);
      } else {
        this.padMonthWithEmptyData(monthYear);
      }
    }
  }

  protected getNextMonth() {
    this.currentDate = this.currentDate.add(1, 'months');
    const monthYear = this.currentDate.format('MM/YYYY');
    if (!this.dataMap.has(monthYear)) {
      if (this.byMonth) {
        this.next.emit(this.currentDate);
      } else {
        this.padMonthWithEmptyData(monthYear);
      }
    }
  }

  private setupColumns(forceRefresh = false) {
    if (!this.columns || forceRefresh) {
      switch (this.type) {
        case 'NEW-FLIGHT': {
          const columns: any = [
            {
              headerName: 'Date',
              field: 'startDate',
              flex: 2,
              editable: false,
              cellRenderer: (params: any) => {
                return dayjs(params.value).format('DD/MM/YYYY');
              },
            },
            // ,
            // {
            //   headerName: 'Prix adulte',
            //   field: 'paxPriceReference',
            //   flex: 2,
            //   editable: !this.readonly,
            //   onCellClicked: (event: CellClickedEvent) => {
            //     if (
            //       event.data &&
            //       event.data.paxPriceReference !== undefined &&
            //       event.data.outboundFlightReference
            //     ) {
            //       this.selectedFlightCell = event.data;
            //     }
            //   },
            //   valueSetter: (params: ValueSetterParams) => {
            //     //to make sure user entered number only
            //     const newValInt = parseInt(params.newValue);
            //     if (!isNaN(newValInt)) {
            //       params.data.paxPriceReference = newValInt;
            //       return true;
            //     } else {
            //       return false;
            //     }
            //   },
            //   valueGetter: (params: ValueGetterParams) => {
            //     if (params.data) return params.data.paxPriceReference;
            //     else return null;
            //   },
            //   cellStyle: (params: CellStyle) => {
            //     if (
            //       params.value === undefined ||
            //       params.value === null ||
            //       params.value === ''
            //     ) {
            //       return { backgroundColor: '#F8F8F8' };
            //     } else {
            //       return null;
            //     }
            //   },
            // },
          ];
          for (const duration of this.columnNames) {
            columns.push({
              headerName: duration,
              field: 'duration',
              flex: 1,
              cellRenderer: (params: any) => {
                return params.data[duration]?.paxPriceReference || '-';
              },
              editable: !this.readonly,
              onCellClicked: (event: CellClickedEvent) => {
                if (
                  event.data &&
                  event.data[duration] !== undefined &&
                  event.data[duration].outboundFlightReference
                ) {
                  this.selectedFlightCell = event.data[duration];
                }
              },
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  if (params.data[duration]) {
                    params.data[duration].paxPriceReference = newValInt;
                  } else {
                    params.data[duration] = { paxPriceReference: newValInt };
                  }
                  return true;
                } else {
                  return false;
                }
              },
              valueGetter: (params: ValueGetterParams) => {
                if (params.data && params.data[duration])
                  return params.data[duration].paxPriceReference;
                else return null;
              },
              cellStyle: (params: CellStyle) => {
                if (
                  params.value === undefined ||
                  params.value === null ||
                  params.value === ''
                ) {
                  return { backgroundColor: '#F8F8F8' };
                } else {
                  return null;
                }
              },
            });
          }
          this.columns = columns;
          break;
        }
        case TypeBaseEnum.UNIT:
          this.columns = [
            {
              headerName: 'Date',
              field: 'date',
              flex: 1,
              editable: false,
              cellRenderer: (params: any) => {
                return dayjs(params.value).format('DD/MM/YYYY');
              },
            },
            {
              headerName: 'Prix unitaire',
              field: 'unitPrice',
              flex: 1,
              cellRenderer: (params: any) => {
                return params?.value || '-';
              },
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  params.data.unitPrice = newValInt;
                  return true;
                } else {
                  return false;
                }
              },
              editable: true,
            },
            {
              headerName: 'Statut',
              field: 'status',
              flex: 1,
              editable: true,
              cellRenderer: (params: any) => {
                return StockUtils.getStringFromStatus(params.value);
              },
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [EnumStockStatus.AVAILABLE, EnumStockStatus.STOPSALE],
              },
            },
          ];
          break;
        case TypeBaseEnum.PERSON:
          this.columns = [
            {
              headerName: 'Date',
              field: 'date',
              flex: 1,
              editable: false,
              cellRenderer: (params: any) => {
                return dayjs(params.value).format('DD/MM/YYYY');
              },
            },
            {
              headerName: 'Prix adulte',
              field: 'adultPrice',
              flex: 1,
              editable: true,
              cellRenderer: (params: any) => {
                return params?.value || '-';
              },
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  params.data.adultPrice = newValInt;
                  return true;
                } else {
                  return false;
                }
              },
            },
            {
              headerName: 'Prix enfant',
              field: 'childPrice',
              flex: 1,
              editable: true,
              cellRenderer: (params: any) => {
                return params?.value || '-';
              },
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  params.data.childPrice = newValInt;
                  return true;
                } else {
                  return false;
                }
              },
            },
            {
              headerName: 'Statut',
              field: 'status',
              editable: true,
              flex: 1,
              cellRenderer: (params: any) => {
                return StockUtils.getStringFromStatus(params.value);
              },
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [EnumStockStatus.AVAILABLE, EnumStockStatus.STOPSALE],
              },
            },
          ];
          break;
        case 'STOCK':
          this.columns = [
            {
              headerName: 'Date',
              field: 'date',
              flex: 2,
              editable: false,
              cellRenderer: (params: any) => {
                return dayjs(params.value).format('DD/MM/YYYY');
              },
            },
            {
              headerName: 'Statut-1',
              flex: 1,
              field: 'status',
              cellRenderer: (params: any) => {
                return StockUtils.getStringFromStatus(params.value);
              },
              editable: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [
                  EnumStockStatus.AVAILABLE,
                  EnumStockStatus.STOPSALE,
                  EnumStockStatus.REQUEST,
                ],
              },
            },
            {
              headerName: 'Stock',
              flex: 1,
              field: 'value',
              editable: true,
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  params.data.value = newValInt;
                  return true;
                } else {
                  return false;
                }
              },
            },
            {
              headerName: 'Dispo',
              field: 'available',
              flex: 1,
            },
            {
              headerName: 'Résa en cours',
              field: 'currentlyBooked',
              flex: 2,
            },
            {
              headerName: 'Rétro D-',
              flex: 1,
              field: 'retro',
              editable: true,
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  params.data.retro = newValInt;
                  return true;
                } else {
                  return false;
                }
              },
            },
            {
              headerName: 'Statut-2',
              flex: 1,
              field: 'statusAfter',
              cellRenderer: (params: any) => {
                return StockUtils.getStringFromStatus(params.value);
              },
              editable: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [
                  EnumStockStatus.AVAILABLE,
                  EnumStockStatus.STOPSALE,
                  EnumStockStatus.REQUEST,
                ],
              },
            },
            {
              headerName: 'Résa TTL',
              field: 'booked',
              flex: 1,
            },
            {
              headerName: '',
              flex: 1,
              cellRenderer: (params: any) => {
                return this.getBookings(params.data.date).length > 0
                  ? '👁️'
                  : '';
              },
              onCellClicked: (event: any) => {
                const cbookings = this.getBookings(event.data.date);
                if (cbookings.length > 0) {
                  this.toggleBookings(
                    cbookings,
                    (event.event as any).pageX,
                    (event.event as any).pageY
                  );
                }
              },
            },
          ];

          break;

        case 'PRODUCT':
          this.columns = [
            {
              headerName: 'Actif',
              field: 'activated',
              flex: 1,
              editable: true,
              cellRenderer: (params: any) => {
                return params.value ? 'O' : 'N';
              },
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: ['O', 'N'],
              },
              cellEditorPopup: false,
              valueSetter: (params: ValueSetterParams) => {
                const newVal = params.newValue;
                console.log('newVal', newVal);
                if (newVal === 'O' || newVal === 'N') {
                  params.data.activated = newVal === 'O' ? true : false;
                  return true;
                } else {
                  return false;
                }
              },
              cellDataType: 'object',
            },
            {
              headerName: 'Date',
              field: 'date',
              flex: 1.5,
              cellRenderer: (params: any) => {
                return dayjs(params.value).format('DD/MM/YYYY');
              },
            },
            {
              headerName: '½ DBL',
              flex: 1.25,
              field: 'doublePrice',
              editable: true,
            },
            {
              headerName: 'SGL',
              flex: 1.25,
              field: 'singlePrice',
              editable: true,
            },
            {
              headerName: '3e ADT',
              flex: 1.25,
              field: 'triplePrice',
              editable: true,
            },
            {
              headerName: '4e ADT',
              flex: 1.25,
              field: 'quadruplePrice',
              editable: true,
            },
            {
              headerName: 'CHD 1',
              flex: 1,
              field: 'childOnePrice',
              editable: true,
            },
            {
              headerName: 'CHD 2',
              flex: 1,
              field: 'childrenTwoPrice',
              editable: true,
            },
            {
              headerName: 'BB',
              flex: 1,
              field: 'babiesPrice',
              editable: true,
            },
            {
              headerName: 'P/U Dispo',
              flex: 1.25,
              field: 'maxBooking',
            },
            {
              headerName: 'Statut-1',
              field: 'stockStatus',
              flex: 1.25,
              cellRenderer: (params: any) => {
                return StockUtils.getStringFromStatus(params.value);
              },
            },
            {
              headerName: 'Rétro D-',
              flex: 1.25,
              field: 'retrocession',
            },
            {
              headerName: 'Statut-2',
              field: 'retrocessionStockStatus',
              flex: 1.25,
              cellRenderer: (params: any) => {
                return StockUtils.getStringFromStatus(params.value);
              },
            },
          ];
          break;
        case 'ACCOMHB':
          {
            const columns: ColDef[] = [
              {
                headerName: 'Date',
                field: 'date',
                flex: 1,
                editable: false,
                cellRenderer: (params: any) => {
                  return dayjs(params.value).format('DD/MM/YYYY');
                },
              },
            ];
            for (const categorie of this.columnNames) {
              columns.push({
                headerName: categorie.toString(),
                field: 'categorie',
                flex: 1,
                editable: false,
                cellRenderer: (params: any) => {
                  return params.data[categorie];
                },
              });
            }
            this.columns = columns;
          }
          break;
        case 'NEW-ACCOM':
          {
            const columns: ColDef[] = [
              {
                headerName: 'Date',
                field: 'date',
                flex: 1,
                editable: false,
                cellRenderer: (params: any) => {
                  return dayjs(params.value).format('DD/MM/YYYY');
                },
              },
              {
                headerName: 'STATUT',
                field: 'isEnabled',
                editable: true,
                flex: 1,
                cellRenderer: (params: any) => {
                  return params.value ? 'O' : 'N';
                },
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                  values: ['O', 'N'],
                },
                cellEditorPopup: false,
                valueSetter: (params: ValueSetterParams) => {
                  const newVal = params.newValue;
                  if (newVal === 'O' || newVal === 'N') {
                    params.data.isEnabled = newVal === 'O' ? true : false;
                    return true;
                  } else {
                    return false;
                  }
                },
                cellDataType: 'object',
              },
            ];
            for (const categorie of this.columnNames) {
              columns.push({
                headerName: this.getAccomColumnName(categorie),
                flex: 1,
                editable: true,
                type: 'number',
                field: `pulpoRoomCachePrice.${categorie}`,
                cellRenderer: (params: any) => {
                  return params.data['pulpoRoomCachePrice'][categorie] || '-';
                },
                valueSetter: (params: ValueSetterParams) => {
                  const newVal = params.newValue;
                  if (isNaN(newVal) || newVal < 0) {
                    return false;
                  }
                  params.data['pulpoRoomCachePrice'][categorie] =
                    parseInt(newVal);
                  return true;
                },
              });
            }
            this.columns = columns;
          }
          break;

        case 'NEW-ACCOM-WH':
          {
            const columns: ColDef[] = [
              {
                headerName: 'Date',
                field: 'date',
                flex: 1,
                editable: false,
                cellRenderer: (params: any) => {
                  return dayjs(params.value).format('DD/MM/YYYY');
                },
              },
              {
                headerName: 'Prix',
                flex: 1,
                editable: false,
                type: 'number',
                field: `price`,
                cellRenderer: (params: any) => {
                  return params.value || '-';
                },
              },
              {
                headerName: 'Status',
                flex: 1,
                editable: false,
                type: 'string',
                field: `status`,
                // cellRenderer: (params: any) => {
                //   return params.value
                // },
              },
            ];
            this.columns = columns;
          }
          break;
        default:
          this.columns = [
            {
              headerName: 'Date',
              field: 'date',
              flex: 1,
              editable: false,
              cellRenderer: (params: any) => {
                return dayjs(params.value).format('DD/MM/YYYY');
              },
            },
            {
              headerName: 'Prix adulte',
              field: 'doublePrice',
              flex: 1,
              editable: true,
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  params.data.doublePrice = newValInt;
                  return true;
                } else {
                  return false;
                }
              },
            },
            {
              headerName: 'Prix enfant',
              field: 'childOnePrice',
              flex: 1,
              editable: true,
              valueSetter: (params: ValueSetterParams) => {
                //to make sure user entered number only
                const newValInt = parseInt(params.newValue);
                if (!isNaN(newValInt)) {
                  params.data.childOnePrice = newValInt;
                  return true;
                } else {
                  return false;
                }
              },
            },
            {
              headerName: 'Statut',
              field: 'stockStatus',
              editable: true,
              flex: 1,
              cellRenderer: (params: any) => {
                return StockUtils.getStringFromStatus(params.value);
              },
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [EnumStockStatus.AVAILABLE, EnumStockStatus.STOPSALE],
              },
            },
          ];
      }
    }
  }

  private getAccomColumnName(categorie: string | number) {
    console.log('cat', categorie);
    switch (categorie) {
      case 'Room_BASE':
        return 'BASE';
      case 'Room_SGLE':
        return 'SGLE';
      case 'Room_3E_A':
        return '3e ADT';
      case 'Room_4E_A':
        return '4e A';
      case 'Room_C_A1':
        return 'CHD 1-A';
      case 'Room_C_A2':
        return 'CHD 2-A';
      case 'Room_C_B1':
        return 'CHD 1-B';
      case 'Room_C_B2':
        return 'CHD 2-B';
      case 'BABY':
        return 'BEBE';
      default:
        return categorie.toString();
    }
  }

  private toggleBookings(bookingIds: string[], x: number, y: number) {
    if (!this.currentBookings || this.modalLeft !== x || this.modalTop !== y) {
      this.modalTop = y;
      this.modalLeft = x;
      this.currentBookings = bookingIds;
    } else {
      this.currentBookings = null;
    }
  }

  private getBookings(date: dayjs.Dayjs) {
    return this.bookingsByDate[date.format('YYYY-MM-DD')] || [];
  }

  private padMonthWithEmptyData(monthYear: string) {
    let dateField = 'date';
    if (this.type === 'NEW-FLIGHT') {
      dateField = 'startDate';
    }
    const newMonth = this.dataMap.get(monthYear) || [];
    for (
      let indexDay = 0;
      indexDay < this.currentDate.daysInMonth();
      indexDay++
    ) {
      if (
        !newMonth[indexDay] ||
        !dayjs(newMonth[indexDay][dateField]).isValid() ||
        dayjs(newMonth[indexDay][dateField]).date() !== indexDay + 1
      ) {
        const date = dayjs(this.currentDate).date(indexDay + 1);
        let value: any = {};
        switch (this.type) {
          case 'NEW-FLIGHT':
            value.startDate = date;
            break;
          case TypeBaseEnum.UNIT:
            value.date = date.format('YYYY-MM-DD');
            value.unitPrice = null;
            value.status = EnumOptionStatus.STOPSALE;
            break;
          case TypeBaseEnum.PERSON:
            value.date = date.format('YYYY-MM-DD');
            value.adultPrice = null;
            value.childPrice = null;
            value.status = EnumOptionStatus.STOPSALE;
            break;
          case 'STOCK':
            value.date = date;
            value.status = EnumStockStatus.STOPSALE;
            value.value = 0;
            value.available = 0;
            value.currentlyBooked = 0;
            value.stock = this.stock;
            value.retro = 0;
            value.statusAfter = EnumStockStatus.STOPSALE;
            value.booked = 0;
            break;
          case 'PRODUCT':
            value.activated = false;
            value.date = date;
            value.stockStatus = EnumStockStatus.STOPSALE;
            value.doublePrice = 0;
            value.singlePrice = 0;
            value.triplePrice = 0;
            value.quadruplePrice = 0;
            value.childOnePrice = 0;
            value.childrenTwoPrice = 0;
            value.babiesPrice = 0;
            value.maxBooking = 0;
            value.retrocession = 0;
            value.retrocessionStockStatus = EnumStockStatus.STOPSALE;
            value.discount = this.promo;
            break;
          case 'ACCOMHB':
            value.date = date;
            for (const categorie of this.columnNames) {
              value[categorie] = '-';
            }
            break;
          case 'NEW-ACCOM':
            value.date = date;
            value.isEnabled = false;
            value.pulpoRoomCachePrice = {};
            for (const categorie of this.columnNames) {
              value.pulpoRoomCachePrice[categorie] = null;
            }
            break;
          case 'NEW-ACCOM-WH':
            value.date = date;
            value.price = null;
            value.status = WHRoomAvailabilityStatusEnum.SS;
            break;
          default:
            value = {};
            value.date = date;
            value.stockStatus = EnumStockStatus.STOPSALE;
            break;
        }
        newMonth.splice(indexDay, 0, value);
      }
    }
    this.dataMap.set(monthYear, newMonth);
    this.updateEditedData(newMonth);
  }

  updateEditedData(newMonthData: any[]) {
    for (const data of newMonthData) {
      if (this.type !== 'STOCK' || (this.type === 'STOCK' && !data.id)) {
        let index;
        if (this.type === 'NEW-FLIGHT') {
          index = this.editedData.findIndex(
            (editedData) => editedData.startDate === data.startDate
          );
        } else {
          index = this.editedData.findIndex(
            (editedData) => editedData.date === data.date
          );
        }
        if (index === -1) {
          this.editedData.push(data);
        }
      }
    }
  }
}
