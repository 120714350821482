import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerInfo, TravelerRecord } from '@pulpo/pulpo-models';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import dayjs from 'dayjs';

@Component({
  selector: 'booking-record-traveler',
  templateUrl: './booking-record-traveler.component.html',
  styleUrls: ['./booking-record-traveler.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatIconModule, MatCardModule],
})
export class BookingRecordTravelerComponent {
  dayjs = dayjs;
  @Input() travelerRecord: TravelerRecord;
  @Input() customerInfo: CustomerInfo;
}
