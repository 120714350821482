import { Directive, forwardRef, Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import { ProductService } from '@pulpo/pulpo-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class uniqueRefValidator implements AsyncValidator {
  constructor(private productService: ProductService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.productService
      .getReferences()
      .pipe(
        map((data: any) =>
          data.body.indexOf(control.value) !== -1 ? { existingRef: true } : null
        )
      );
  }
}

@Directive({
  selector: '[uniqueRef]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => UniqueRefValidatorDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class UniqueRefValidatorDirective implements AsyncValidator {
  constructor(private validator: uniqueRefValidator) {}
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.validator.validate(control);
  }
}
