<div class="w-75">

</div>
<h2 mat-dialog-title>{{edit ? 'Modifiez' : 'Ajoutez'}} une image</h2>

<mat-dialog-content [formGroup]="form" style="height: 80%; align-items:center;display: flex;
    flex-direction: column;">
    <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>
        <input matInput class="w-100" type="text" formControlName="description" placeholder="Description" />
    </mat-form-field>
    <div class="w-100 m-2">
        <mat-checkbox formControlName="productCover">Couverture produit</mat-checkbox>
    </div>
    <div class="w-100">
        <mat-label>Tags</mat-label>
        <input matInput class="w-100" type="text" (keypress)="tagKeyPressed($event)" placeholder="Nouveau tag" />
        <mat-chip-set class="m-2" aria-label="picture tags">
            <mat-chip *ngFor="let tag of tags">{{tag}}
                <button matChipRemove [attr.aria-label]="'remove ' + tag" (click)="removeTag(tag)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-set>
    </div>
    <mat-form-field appearance="fill">
        <mat-label>Url</mat-label>
        <input matInput class="w-100" type="text" formControlName="url" placeholder="Url de l'image" />
    </mat-form-field>
    <img *ngIf="form.get('url')?.value && form.get('url')?.valid" class="h-100" [src]="form.get('url')?.value"
        alt="Image non disponible" />
    <div *ngIf="!form.get('url')?.value || form.get('url')?.invalid" class="preview-placeholder">
        <h6>Prévisualisation de l'image</h6>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Annuler</button>
    <button [class.disabled]="form.invalid" [disabled]="form.invalid"
        class="mat-raised-button mat-primary btn-pulpo m-2" (click)="save()">Valider</button>
</mat-dialog-actions>