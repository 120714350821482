import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'version-update-snackbar',
  templateUrl: './version-update-snackbar.component.html',
  styleUrls: ['./version-update-snackbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class VersionUpdateSnackbarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<VersionUpdateSnackbarComponent>
  ) {}

  reload() {
    window.location.reload();
  }
}
