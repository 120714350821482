import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@pulpo/app-config';
import { ProductDescription } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';

@Injectable({ providedIn: 'root' })
export class ProductDescriptionService extends ApiService<ProductDescription> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/product-descriptions');
  }

  findForProduct(id: number): Observable<HttpResponse<ProductDescription[]>> {
    return this.http.get<ProductDescription[]>(
      `${this.url}/${this.endpoint}/product?productId=${id}`,
      { observe: 'response' }
    );
  }

  findOptimisedForProduct(
    id: number
  ): Observable<HttpResponse<ProductDescription[]>> {
    return this.http.get<ProductDescription[]>(
      `${this.url}/${this.endpoint}/product/light?productId=${id}`,
      { observe: 'response' }
    );
  }
}
