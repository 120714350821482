import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AccommodationProviderAccount } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccommodationProviderAccountService extends ApiService<AccommodationProviderAccount> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'accommodation/api/provider-account');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8088';
    }
  }

  public getCredentials(): Observable<
    HttpResponse<AccommodationProviderAccount[]>
  > {
    return this.http.get<AccommodationProviderAccount[]>(
      `${this.url}/${this.endpoint}`,
      {
        observe: 'response',
      }
    );
  }

  public createOrUpdateAll(credentials: AccommodationProviderAccount[]) {
    return this.http.post<AccommodationProviderAccount[]>(
      `${this.url}/${this.endpoint}/all`,
      credentials,
      {
        observe: 'response',
      }
    );
  }
}
