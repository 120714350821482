import { Dayjs } from 'dayjs';
import { Product } from '../product/product.model';
import { BookingStatusEnum } from './enums/bookingStatusEnum.model';

export interface IBooking {
  id?: number;
  status?: BookingStatusEnum;
  creationDate: Dayjs;
  lastUpdateDate?: Dayjs;
  lastUpdateUser?: string;
  openTimeRecord?: Dayjs;
  opened?: boolean;
  categoryName?: string;
  offerJson?: string;
  occupationJson?: string;
  selectionJson?: string;
  travelerJson?: string;
  nbNights?: string;
  startCity?: string;
  product?: Product;
  classification?: string;
}

export class Booking implements IBooking {
  categoryName!: string;
  creationDate!: Dayjs;
  id!: number;
  lastUpdateDate!: Dayjs;
  lastUpdateUser!: string;
  nbNights!: string;
  occupationJson!: string;
  offerJson!: string;
  openTimeRecord!: Dayjs;
  opened!: boolean;
  product!: Product;
  selectionJson!: string;
  startCity!: string;
  status!: BookingStatusEnum;
  travelerJson!: string;
  classification?: string;
}
