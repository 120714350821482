import { NgModule, LOCALE_ID } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import locale from '@angular/common/locales/fr';

import dayjs from 'dayjs';
import {
  // NgbDateAdapter,
  NgbDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  // imports: [NgxWebstorageModule.forRoot({ prefix: 'pulpo', separator: '-' })],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      useValue: 'fr',
    },
    DatePipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class PulpoCoreModule {
  constructor(private dpConfig: NgbDatepickerConfig) {
    registerLocaleData(locale);
    this.dpConfig.minDate = { year: dayjs().year() - 100, month: 1, day: 1 };
  }
}
