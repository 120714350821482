import { CarRentalBookingFilters } from './car-rental-booking-filters.model';
import { CarRentalCachePrice } from './car-rental-cache-price.model';
import { CarRentalCategory } from './car-rental-category.model';
import { CarRentalLocation } from './car-rental-location.model';
import { CarRentalProviderEnum } from './car-rental-provider.enum';

export class CarRental {
  id?: string;
  carRentalId?: number;
  name?: string;
  internalReference?: string;
  renter?: string;
  status?: boolean;
  modifiedAt?: string;
  modifiedBy?: string;
  active?: boolean;
  country?: string;
  sourceLocation?: CarRentalLocation;
  destinationLocation?: CarRentalLocation;
  informations?: string[];
  categories?: CarRentalCategory[];
  cachePrice?: CarRentalCachePrice;
  bookingFilters?: CarRentalBookingFilters;
  bookingProvider?: CarRentalProviderEnum;
  companyId?: string;
  isDeletable?: boolean;
}
