import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { TagService } from '@pulpo/pulpo-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class uniqueTagNameValidator implements AsyncValidator {
  constructor(private productTagService: TagService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.productTagService.exists(control.value).pipe(
      map((response) => {
        return response?.body === true ? { uniqueTagName: true } : null;
      })
    );
  }
}
