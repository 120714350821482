<div class="d-flex flex-column w-100 h-100 justify-content between align-items-center">
    <div class="table-header w-100 d-flex justify-content-between align-items-center">
        <h2 class="p-4 m-0">
            <mat-icon class="scale-2 mr-3">{{data.titleIcon}}</mat-icon> {{data.title}}
        </h2>
    </div>

    <div class="text-justify m-4" [innerHTML]="data.content"></div>
    <div class="d-flex flex-row justify-content-around p-4">
        <button *ngIf="data.cancelButton" (click)="dialogRef.close()" class="btn-pulpo-red btn-sized m-2">Annuler</button>
        <button *ngIf="data.okButton" (click)="dialogRef.close(1)" class="btn-pulpo btn-sized m-2">Ok</button>
    </div>
</div>