import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contract, ProductRoom } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ProductRoomService extends ApiService<ProductRoom> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/product-rooms', routeReuse);
  }

  activate(
    id: number,
    productRoom: ProductRoom,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<ProductRoom>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.put<ProductRoom>(
      `${this.url}/${this.endpoint}/activate/${id}`,
      productRoom,
      { observe: 'response' }
    );
  }

  deactivate(
    id: number,
    productRoom: ProductRoom,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<ProductRoom>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.put<ProductRoom>(
      `${this.url}/${this.endpoint}/deactivate/${id}`,
      productRoom,
      { observe: 'response' }
    );
  }

  moveTop(productRoom: ProductRoom): Observable<HttpResponse<ProductRoom>> {
    return this.http.put<ProductRoom>(
      `${this.url}/${this.endpoint}/move/top`,
      productRoom,
      { observe: 'response' }
    );
  }

  moveBottom(productRoom: ProductRoom): Observable<HttpResponse<ProductRoom>> {
    return this.http.put<ProductRoom>(
      `${this.url}/${this.endpoint}/move/bottom`,
      productRoom,
      { observe: 'response' }
    );
  }

  moveUpward(productRoom: ProductRoom): Observable<HttpResponse<ProductRoom>> {
    return this.http.put<ProductRoom>(
      `${this.url}/${this.endpoint}/move/up`,
      productRoom,
      { observe: 'response' }
    );
  }

  moveBackward(
    productRoom: ProductRoom
  ): Observable<HttpResponse<ProductRoom>> {
    return this.http.put<ProductRoom>(
      `${this.url}/${this.endpoint}/move/down`,
      productRoom,
      { observe: 'response' }
    );
  }

  moveUpwardContract(contract: Contract): Observable<HttpResponse<Contract>> {
    return this.http.put<Contract>(
      `${this.url}/${this.endpoint}/move/contract/up`,
      contract,
      { observe: 'response' }
    );
  }

  moveBackwardContract(contract: Contract): Observable<HttpResponse<Contract>> {
    return this.http.put<Contract>(
      `${this.url}/${this.endpoint}/contract/down`,
      contract,
      { observe: 'response' }
    );
  }

  findForProduct(id: number): Observable<HttpResponse<ProductRoom[]>> {
    return this.http.get<ProductRoom[]>(
      `${this.url}/${this.endpoint}/product?productId=${id}`,
      {
        observe: 'response',
      }
    );
  }

  getProductRoomReference(
    id: any,
    profileId: any,
    date: any
  ): Observable<HttpResponse<ProductRoom>> {
    return this.http.get<ProductRoom>(
      this.url + '/' + this.endpoint + `/occupation`,
      {
        observe: 'response',
        params: { productId: id, profileId: profileId, date: date },
      }
    );
  }
}
