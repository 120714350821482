import { Dayjs } from 'dayjs';
import { HBBoard } from './hotelbeds/hb-board.model';
import { HBRoom } from './hotelbeds/hb-room.model';

export class AccommodationImport {
  selectedBoards!: HBBoard[];
  providerName!: EnumAccomProvider;
  hotelCode!: number;
  userId!: number;
  selectedRooms!: HBRoom[];
  reference!: string;
  companyId!: string;

  startCachePeriod?: Dayjs | string;
  endCachePeriod?: Dayjs | string;
}

export enum EnumAccomProvider {
  HOTELBEDS = 'HOTELBEDS',
}
