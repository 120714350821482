import { Pipe, PipeTransform } from '@angular/core';
import { ProductService } from '@pulpo/pulpo-api';
import { Product } from '@pulpo/pulpo-models';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'ProductPipe',
  standalone: true,
  pure: true,
})
export class ProductPipe implements PipeTransform {
  constructor(private productService: ProductService) {}
  transform(id: number, ...args: string[]): Observable<any | undefined> {
    const field = args[0];
    if (!id) return new Observable<string | undefined>();
    return this.productService
      .find(id)
      .pipe(map((res) => res.body?.[field as keyof Product]));
  }
}
