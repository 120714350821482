import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { StorageService } from './storage.service';
import { User, Role, UserRightEnum } from '@pulpo/pulpo-models';

@Injectable({ providedIn: 'root' })
export class RoleService extends ApiService<Role> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    private storageService: StorageService
  ) {
    super(environment, http, 'api/roles');
  }

  getUsers(roleId: number): Observable<HttpResponse<User[]>> {
    return this.http.get<any>(`${this.url}/${this.endpoint}/${roleId}/users`, {
      observe: 'response',
    });
  }

  hasPermissions(permissions: { name: string; right: UserRightEnum }[]) {
    const role: any = this.storageService.getRole();
    return (
      role &&
      role.activated &&
      permissions.every((permission) => {
        switch (permission.right) {
          case UserRightEnum.READ:
            return (
              role[permission.name] &&
              (role[permission.name] == UserRightEnum.READ ||
                role[permission.name] == UserRightEnum.WRITE)
            );
          case UserRightEnum.WRITE:
            return (
              role[permission.name] &&
              role[permission.name] == UserRightEnum.WRITE
            );
          default:
            return false;
        }
      })
    );
  }
}
