import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
})
export class DeleteDialogComponent {
  content: string;
  id: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; content: string },
    public dialogRef: MatDialogRef<DeleteDialogComponent>
  ) {
    this.content = data.content;
    this.id = data.id;
  }
}
