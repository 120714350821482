import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionRecord } from '@pulpo/pulpo-models';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShortTextPipe } from '../../../../pipes';

@Component({
  selector: 'booking-record-option',
  templateUrl: './booking-record-option.component.html',
  styleUrls: ['./booking-record-option.component.scss'],
  standalone: true,
  imports: [CommonModule, ShortTextPipe, MatTooltipModule],
})
export class BookingRecordOptionComponent {
  @Input() optionRecord: OptionRecord;
}
