import { Component, EventEmitter, Input, Output } from '@angular/core';
import { pulpoEditorConfig } from '../../utils/pulpo-editor-config';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'pulpo-editor',
  templateUrl: './pulpo-editor.component.html',
  styleUrls: ['./pulpo-editor.component.scss'],
  standalone: true,
  imports: [AngularEditorModule, FormsModule],
})
export class PulpoEditorComponent {
  @Input() content!: string;
  @Output() contentChange = new EventEmitter<string>();
  editorConfig = pulpoEditorConfig;
  constructor() {}
}
