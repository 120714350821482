import { BookingStatusEnum } from '../booking';
import { ItineraryRecord } from './itinerary-record.model';

export class FlightBooking {
  id!: number;
  status!: BookingStatusEnum;
  opened!: boolean;
  openTimeRecord!: Date;
  originCountry!: string;
  destinationCountry!: string;
  originCity!: string;
  destinationCity!: string;
  creationDate!: Date;
  lastUpdateDate!: Date;
  lastUpdateUser!: string;
  channel!: string;
  supplierName!: string;
  producerId!: number;
  passengerJson!: string;
  luggageJson!: string;
  source!: string;
  reference!: string;
  priceTtc!: string;
  luggagePrice!: string;
  mode!: string;
  totalHt!: string;
  taxes!: string;
  totalTtc!: string;
  itineraryRecords!: ItineraryRecord[];
  ticketNumber!: string;

  ticketMetadata!: string;

  emissionDate!: Date;
  paymentForm!: string;
  transactionId!: string;
}
