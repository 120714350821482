import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';
import { map } from 'rxjs/operators';
import { DATE_FORMAT, IStockValue } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';

type EntityArrayResponseType = HttpResponse<IStockValue[]>;

@Injectable({ providedIn: 'root' })
export class StockValueService extends ApiService<IStockValue> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/stock-values');
  }

  protected convertDateArrayFromServer(
    res: EntityArrayResponseType
  ): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((stockValue: IStockValue) => {
        stockValue.date = dayjs(stockValue.date);
      });
    }
    return res;
  }

  findAllByStock(id: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IStockValue[]>(`${this.url}/${this.endpoint}/stocks?stockId=${id}`, {
        observe: 'response',
      })
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  public override updateAll(
    items: IStockValue[]
  ): Observable<HttpResponse<IStockValue[]>> {
    const copy = this.convertDateFromClientArray(items);
    return this.http
      .put<IStockValue[]>(`${this.url}/${this.endpoint}/all`, copy, {
        observe: 'response',
      })
      .pipe(
        map((res: EntityArrayResponseType) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  convertDateFromClientArray(stockValues: IStockValue[]): IStockValue[] {
    const copyList: IStockValue[] = [];
    stockValues.forEach((stockValue) => {
      const copy: IStockValue = Object.assign({}, stockValue, {
        date:
          stockValue.date != null && stockValue.date.isValid()
            ? stockValue.date.format(DATE_FORMAT)
            : null,
      });
      copyList.push(copy);
    });

    return copyList;
  }
}
