import { Country } from '../destination';
import { FlightProviderSettings } from '../flight/flight-provider-settings.model';
import { ClientSupplier } from './client-supplier.model';
import { EnumSortingStrategy } from './enums/enum-sorting-strategy.model';
import { EnumTypeCompany } from './enums/enum-type-company.model';
import { Network } from './network.model';

export class Company {
  id!: number;
  name?: string;
  tel?: string;
  email?: string;
  bookingEmail?: string;
  description?: string;
  logoUrl?: string;
  logoContentType?: string;
  logo?: any;
  permit?: string;
  address?: string;
  zipCode?: string;
  city?: string;
  conditionContentType?: string;
  condition?: any;
  conditionUrl?: string;
  typeCompany?: EnumTypeCompany;
  contact?: string;
  information?: any;
  active?: boolean;
  iata?: string;
  sortingStrategy?: EnumSortingStrategy;
  sortingStrategyBis?: EnumSortingStrategy;
  apeCode?: string;
  activity?: string;
  ownerId?: number;
  corporateType?: string;
  codeSiret?: string;
  phone?: string;
  contractDocumentContentType?: string;
  contractDocument?: any;
  codeImmatriculation?: string;
  guaranteeType?: string;
  civilResponsability?: string;
  clients?: ClientSupplier[];
  suppliers?: ClientSupplier[];
  networks?: Network[];
  country?: Country;
  reference?: string;
  insuranceOwner?: string;
  insuranceId?: string;
  insurancePassword?: string;
  flightProviderSettings?: FlightProviderSettings;
}
