import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CarRentalProviderSettings } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CarRentalProviderSettingsService extends ApiService<CarRentalProviderSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'car-rental/api/car-rental-provider-settings');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8083';
    }
  }

  public getByCompanyId(): Observable<HttpResponse<CarRentalProviderSettings>> {
    return this.http.get<CarRentalProviderSettings>(
      `${this.url}/${this.endpoint}/by-company-id`,
      {
        observe: 'response',
      }
    );
  }

  public override updateAll(
    items: CarRentalProviderSettings[]
  ): Observable<HttpResponse<CarRentalProviderSettings[]>> {
    return this.http.put<CarRentalProviderSettings[]>(
      `${this.url}/${this.endpoint}`,
      items,
      {
        observe: 'response',
      }
    );
  }
}
