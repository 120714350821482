import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BookingRecord,
  BookingSubStatusEnum,
  TypeBaseEnum,
} from '@pulpo/pulpo-models';
import { MatDividerModule } from '@angular/material/divider';
import {
  AccommodationRecordRoomCategoryPipe,
  ShortTextPipe,
} from '../../../../pipes';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'booking-record-bill',
  templateUrl: './booking-record-bill.component.html',
  styleUrls: ['./booking-record-bill.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    AccommodationRecordRoomCategoryPipe,
    ShortTextPipe,
    MatTooltipModule,
  ],
})
export class BookingRecordBillComponent {
  @Input() bookingRecord: BookingRecord;
  @Input() producerView = false;

  BookingSubStatusEnum = BookingSubStatusEnum;
  TypeBaseEnum = TypeBaseEnum;
  parseFloat = parseFloat;
  Number = Number;

  get totalPriceBought() {
    if (this.bookingRecord.bookingPrice.toProviderPackagePriceWithMeal) {
      return this.bookingRecord.bookingPrice.toProviderPackagePriceWithMeal.toFixed(
        2
      );
    }
    let price = this.bookingRecord.bookingPrice.toProviderPackagePrice
      ? this.bookingRecord.bookingPrice.toProviderPackagePrice
      : 0;
    if (
      this.bookingRecord.bookingPrice.mealPrice &&
      this.bookingRecord.bookingPrice.mealPrice > 0
    ) {
      price += Number(this.bookingRecord.bookingPrice.mealPrice);
    }
    return price.toFixed(2);
  }

  get contractAndMealPrice() {
    let value = 0;
    if (this.bookingRecord.bookingPrice.totalRoomPriceWithMeal) {
      return this.bookingRecord.bookingPrice.totalRoomPriceWithMeal.toFixed(2);
    }
    value = Number(this.bookingRecord.bookingPrice.totalRoomPrice);
    if (
      this.bookingRecord.bookingPrice &&
      this.bookingRecord.bookingPrice.mealPrice
    ) {
      value += Number(this.bookingRecord.bookingPrice.totalRoomPrice);
      if (this.bookingRecord.bookingPrice.mealPrice) {
        value += Number(this.bookingRecord.bookingPrice.mealPrice);
      }
    }
    return value.toFixed(2);
  }

  getRealFlightPrice(withLuggage: boolean) {
    if (withLuggage) {
      return this.bookingRecord.bookingPrice?.taxedFlightPackage?.toFixed(2);
    } else {
      let luggagePrice = 0;
      if (this.bookingRecord.bookingPrice?.paidLuggagePrice) {
        luggagePrice = this.bookingRecord.bookingPrice.paidLuggagePrice;
      }

      if (this.bookingRecord.flightRecord?.offer?.bagRealPrice) {
        luggagePrice = this.bookingRecord.flightRecord.offer.bagRealPrice;
      }

      let price = this.bookingRecord.bookingPrice?.taxedFlightPackage || 0;
      price -= luggagePrice;
      return price.toFixed(2);
    }
  }

  getOptionCommission(id: string) {
    if (this.bookingRecord.bookingPrice.segmentCommissions) {
      const optionSegment =
        this.bookingRecord.bookingPrice.segmentCommissions.find(
          (sc: any) => sc.relatedId === id
        );
      if (optionSegment) {
        return optionSegment.amount?.toFixed(2);
      }
    }
    return Number(0).toFixed(2);
  }
}
