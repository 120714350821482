import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CacheBucket } from '@ngneat/cashew';
import { Company } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompanyService extends ApiService<Company> {
  companyBucket = new CacheBucket();

  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/companies');
  }

  activate(id: number): Observable<HttpResponse<Company>> {
    return this.http.get<Company>(
      `${this.url}/${this.endpoint}/activate/${id}`,
      { observe: 'response' }
    );
  }
  getRoleInCompany(): Observable<HttpResponse<string>> {
    return this.http.get<string>(`${this.url}/${this.endpoint}/role`, {
      observe: 'response',
    });
  }
}
