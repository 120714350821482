import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import ApiService from './api.service.abstract';
import { IParameter } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class ParameterService extends ApiService<IParameter> {
  parametersChangedSubject: Subject<IParameter[]> = new Subject<IParameter[]>();

  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/parameters');
  }

  search(key: string): Observable<HttpResponse<IParameter>> {
    return this.http.get<IParameter>(
      `${this.url}/${this.endpoint}/search?text=${key}`,
      {
        observe: 'response',
      }
    );
  }
}
