import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ProductCarRental } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class CarRentalProductService extends ApiService<ProductCarRental> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'car-rental/api/product-car-rentals', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8083';
    }
  }

  public getByProductId(
    productId: string | number
  ): Observable<HttpResponse<ProductCarRental[]>> {
    return this.http.get<ProductCarRental[]>(
      `${this.url}/${this.endpoint}?productId=${productId}`,
      {
        observe: 'response',
      }
    );
  }

  public addOrUpdate(
    productCarRental: ProductCarRental,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<ProductCarRental>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.post<ProductCarRental>(
      `${this.url}/${this.endpoint}`,
      { ...productCarRental },
      {
        observe: 'response',
      }
    );
  }
}
