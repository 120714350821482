import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CodemirrorComponent, CodemirrorModule } from '@ctrl/ngx-codemirror';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'text-viewer-dialog',
  templateUrl: './text-viewer-dialog.component.html',
  styleUrls: ['./text-viewer-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, CodemirrorModule, FormsModule, MatSlideToggleModule],
})
export class TextViewerDialogComponent implements AfterViewInit {
  @ViewChild('codeMirror') private codeEditorCmp: CodemirrorComponent;
  content: string;
  options: any;
  showparsed: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { content: string; language: string },
    public dialogRef: MatDialogRef<TextViewerDialogComponent>
  ) {
    this.content = data.content;
    this.options = {
      lineNumbers: false,
      theme: 'material',
      mode: data.language ? data.language : 'markdown',
      readOnly: true,
      lineWrapping: true,
    };
    if (data.language === 'innerHtml') {
      this.showparsed = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.codeEditorCmp.codeMirror?.refresh();
    }, 1000);
  }

  changeView(event: any) {
    this.showparsed = event.checked;
    setTimeout(() => {
      this.codeEditorCmp.codeMirror?.refresh();
    }, 200);
  }
}
