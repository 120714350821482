import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BookingStatusEnum,
  FlightBooking,
  FlightTicketingImpl,
} from '@pulpo/pulpo-models';
import {
  CompanyService,
  FlightBookingService,
  FlightEngineService,
} from '@pulpo/pulpo-api';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonLoadingDirective } from '../../../directives';

@Component({
  selector: 'flight-booking-consult-details',
  templateUrl: './flight-booking-consult-details.component.html',
  styleUrls: ['./flight-booking-consult-details.component.scss'],
  standalone: true,
  imports: [
    MatButtonToggleModule,
    MatCardModule,
    DatePipe,
    MatIconModule,
    MatDividerModule,
    MatTabsModule,
    MatButtonLoadingDirective,
    CommonModule,
  ],
})
export class FlightBookingConsultDetailsComponent implements OnInit {
  segments: any[] = [];
  flightBooking!: FlightBooking;
  travelers: any[] = [];
  luggages: any[] = [];
  producerName?: string = '';
  BookingStatusEnum = BookingStatusEnum;
  ticketingInput: FlightTicketingImpl = new FlightTicketingImpl();
  isTicketingProcess = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private flightBookingService: FlightBookingService,
    private flightEngineService: FlightEngineService,
    private companyService: CompanyService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.subscribe(({ flightBooking }) => {
      this.flightBooking = flightBooking;
      console.log(flightBooking);
      this.luggages = JSON.parse(flightBooking.luggageJson);
      const travelers = JSON.parse(flightBooking.passengerJson);
      if (this.luggages) {
        this.luggages.forEach((luggage: any) => {
          const travelerIndex = travelers.findIndex((traveler: any) => {
            return traveler.relatedId == luggage.passenger.relatedId;
          });
          if (travelerIndex != -1) {
            travelers[
              travelerIndex
            ].luggage = `${luggage.quantity} x ${luggage.bag.weight} kg`;
          }
        });
      }
      this.companyService
        .find(flightBooking.producerId)
        .subscribe((company) => {
          this.producerName = company.body?.name;
        });
      this.travelers = travelers;
      if (!this.flightBooking.opened) {
        this.onConsultChange(true);
      }
    });
  }

  onConsultChange(opened: any) {
    this.flightBooking.opened = opened;
    this.flightBooking.openTimeRecord = new Date();
    this.flightBookingService.update(this.flightBooking).subscribe();
  }

  getTitleFromTraveler(traveler: any): string {
    switch (traveler.type) {
      case 'ADULT':
        return traveler.gender == 'MALE' ? 'Mr.' : 'Ms.';
      case 'CHILD':
        return 'CHD';
      case 'BABY':
        return 'BB';
    }
    return '';
  }

  isTicketingBookingEnabled() {
    let ticketingEnabled = false;
    if (this.flightBooking.source) {
      if (
        this.flightBooking.source.includes('TRAVELPORT_UNIVERSAL_API') &&
        this.flightBooking.source.includes('1G')
      ) {
        ticketingEnabled = true;
      }
    }
    return ticketingEnabled;
  }

  processTicketing() {
    const isTicketingCompliant =
      this.flightBooking.id != null &&
      this.flightBooking.producerId != null &&
      this.flightBooking.transactionId != null;

    if (isTicketingCompliant) {
      this.ticketingInput = new FlightTicketingImpl();
      this.ticketingInput.bookingId = this.flightBooking.id;
      this.ticketingInput.companyId = this.flightBooking.producerId;
      this.ticketingInput.transactionId = this.flightBooking.transactionId;
      this.ticketingInput.reference = this.flightBooking.reference;
      this.isTicketingProcess = true;

      this.flightEngineService.ticketing(this.ticketingInput).subscribe(
        (data: any) => {
          if (data.body) {
            this.flightBooking.ticketNumber = data.body.ticketNumber;
            this.flightBooking.emissionDate = new Date();
          }
          this.isTicketingProcess = false;
        },
        (error: any) => {
          this.isTicketingProcess = false;
          console.error(error);
        }
      );
    }
  }
}
