import { EnumXFTType } from './enums/enum-xft-type.model';

export interface IMeal {
  id?: number;
  sortNumber?: number;
  category?: string;
  adultPrice?: number;
  childPrice?: number;
  type?: string;
  xftType?: EnumXFTType;
  info?: string;
  accomId?: string;
  accomRoomCategoryId?: string;
}

export class Meal implements IMeal {
  constructor(
    public id?: number,
    public sortNumber?: number,
    public category?: string,
    public adultPrice?: number,
    public childPrice?: number,
    public type?: string,
    public xftType?: EnumXFTType,
    public info?: string
  ) {}
}
