import { Pipe, PipeTransform } from '@angular/core';
import dayjs, { Dayjs } from 'dayjs';

@Pipe({
  name: 'StockTagDateListPipe',
  standalone: true,
  pure: true,
})
export class StockTagDateListPipe implements PipeTransform {
  transform(value?: Dayjs[]): string {
    //Group dates by month
    if (!value) {
      return '';
    }
    const months: any = {};
    value.forEach((date) => {
      const month = dayjs(date).locale('fr').format('MMM').toUpperCase();
      if (!months[month]) {
        months[month] = [];
      }
      months[month].push(dayjs(date));
    });
    //Format the string
    let result = '';
    Object.keys(months).forEach((month) => {
      result += `${months[month]
        .map((date: Dayjs) => date.format('DD'))
        .join(', ')} ${month} `;
    });
    return result;
  }
}
