import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ILightProduct, Product } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { CacheBucket, withCache } from '@ngneat/cashew';

@Injectable({ providedIn: 'root' })
export class ProductService extends ApiService<Product> {
  productBucket: CacheBucket = new CacheBucket();

  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/products', routeReuse);
  }

  updateBase(product: Product): Observable<HttpResponse<Product>> {
    return this.http.put<Product>(
      `${this.url}/${this.endpoint}` + '/base',
      product,
      {
        observe: 'response',
      }
    );
  }

  updateInfo(
    product: Product,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Product>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.put<Product>(
      `${this.url}/${this.endpoint}` + '/info',
      product,
      {
        observe: 'response',
      }
    );
  }

  findOneWithCache(id: number): Observable<HttpResponse<Product>> {
    return this.http.get<Product>(`${this.url}/${this.endpoint}/` + id, {
      observe: 'response',
      context: withCache({
        bucket: this.productBucket,
        key: 'products',
        ttl: 15 * 60 * 1000,
      }),
    });
  }

  updateContractInfo(
    product: Product,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Product>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.put<Product>(
      `${this.url}/${this.endpoint}` + '/contract',
      product,
      { observe: 'response' }
    );
  }

  getReferences() {
    return this.http.get<string[]>(`${this.url}/${this.endpoint}/reference`, {
      observe: 'response',
    });
  }

  activate(id: number): Observable<HttpResponse<ILightProduct>> {
    return this.http.get<ILightProduct>(
      `${this.url}/${this.endpoint}/activate/${id}/light`,
      { observe: 'response' }
    );
  }

  changeBestDeal(id: string) {
    return this.http.get(`${this.url}/${this.endpoint}/` + id + '/deal', {
      observe: 'response',
    });
  }

  // PRODUCT ROOM

  //Duplicate Product
  duplicate(infos: any): Observable<HttpResponse<Product>> {
    return this.http.post<Product>(
      `${this.url}/${this.endpoint}/duplicate`,
      infos,
      {
        observe: 'response',
      }
    );
  }
}
