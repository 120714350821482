import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';
import ApiService from './api.service.abstract';
import {
  CachePrice,
  Contract,
  ProductContractResponse,
  Price,
  ProductSnapshot,
  ICountry,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';

type EntityArrayResponseTypePrice = HttpResponse<Price[]>;
type EntityArrayResponseTypeCache = HttpResponse<CachePrice[]>;

@Injectable({ providedIn: 'root' })
export class ContractService extends ApiService<Contract> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/contracts', routeReuse);
  }

  getCountryByContract(id: number): Observable<HttpResponse<ICountry>> {
    return this.http.get<ICountry>(`${this.url}/${this.endpoint}/country`, {
      observe: 'response',
      params: { contractId: id },
    });
  }

  /*
   * method to retrieve room combinations with related price
   */

  queryAllLinkedToAccomByProduct(id: any) {
    return this.http.get<Contract[]>(
      this.url + '/' + this.endpoint + '/product-accom',
      { observe: 'response', params: { productId: id } }
    );
  }

  queryAllForProduct(id: any) {
    return this.http.get<Contract[]>(
      this.url + '/' + this.endpoint + '/product',
      { observe: 'response', params: { productId: id } }
    );
  }

  retrieveBundledContracts(id: number) {
    return this.http.get<ProductContractResponse[]>(
      this.url + '/' + this.endpoint + '/bundle/rooms',
      { observe: 'response', params: { productId: id } }
    );
  }

  extractPricesFromContract(contractId: number) {
    return this.http.get<ProductContractResponse[]>(
      this.url + '/' + this.endpoint + '/extract/price',
      { observe: 'response', params: { contractId: contractId } }
    );
  }

  protected convertDateArrayFromServer(
    res: EntityArrayResponseTypePrice
  ): EntityArrayResponseTypePrice {
    if (res.body) {
      res.body.forEach((price: Price) => {
        price.date = dayjs(price.date);
      });
    }
    return res;
  }

  protected convertCheapestDateArrayFromServer(
    res: HttpResponse<ProductSnapshot[]>
  ): HttpResponse<ProductSnapshot[]> {
    if (res.body) {
      res.body.forEach((price: ProductSnapshot) => {
        price.cheapestDate = dayjs(price.cheapestDate);
      });
    }
    return res;
  }

  protected convertCacheDateArrayFromServer(
    res: EntityArrayResponseTypeCache
  ): EntityArrayResponseTypeCache {
    if (res.body) {
      res.body.forEach((price: CachePrice) => {
        price.date = dayjs(price.date);
      });
    }
    return res;
  }
}
