import { Dayjs } from 'dayjs';
import { TypeBaseEnum } from './enums/type-base-enum.model';
import { TypeOptionEnum } from './enums/type-option-enum.model';

export class Option {
  id?: string;
  name: string;
  reference: string;
  companyId?: number;
  countryId?: number;
  optionType: TypeOptionEnum;
  description?: string;
  base: TypeBaseEnum;
  min?: number;
  max?: number;
  imageUrl?: string;
  startDate?: Dayjs;
  endDate?: Dayjs;
}
