import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from './api.service.abstract';
import { StockTagSettings } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StockTagSettingsService extends ApiService<StockTagSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/stock-tag-settings');
  }

  getStockTagSettingsByCompany(
    companyId: number
  ): Observable<HttpResponse<StockTagSettings>> {
    return this.http.get<StockTagSettings>(
      `${this.url}/${this.endpoint}/createOrUpdate?companyId=${companyId}`,
      {
        observe: 'response',
      }
    );
  }
}
