import { AccommodationBooking } from './accommodation-booking.model';

export class AccommodationWHBooking extends AccommodationBooking {
  minStay: number;
  webHotelierBookingRoomDTOList: {
    id?: string;
    propertyCode: string;
    propertyName: string;
    roomCode: string;
    roomCategory: string;
    rateId: number;
    boardWH: string;
    boardName: string;
    star: number;
    description: string;
    imageUrl: string;
    capacity: {
      minPersons: number;
      maxPersons: number;
      maxAdults: number;
      maxInfants: number;
      childrenAllowed: number;
      countInfant: number;
    };
  }[];
}
