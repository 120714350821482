import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from '../api.service.abstract';
import { NewAccommodation, ProductNewAccommodation } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductNewAccommodationService extends ApiService<ProductNewAccommodation> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(
      environment,
      http,
      'accommodation/api/product-accommodations',
      routeReuse
    );
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:8088';
    }
  }

  public getByProductId(
    productId: number
  ): Observable<HttpResponse<ProductNewAccommodation[]>> {
    return this.http.get<ProductNewAccommodation[]>(
      `${this.url}/${this.endpoint}?productId=${productId}`,
      {
        observe: 'response',
      }
    );
  }

  public getAvailableAccommmodations(
    productId: number,
    countryId: number,
    bookingProvider: string
  ): Observable<HttpResponse<NewAccommodation[]>> {
    return this.http.get<NewAccommodation[]>(
      `${this.url}/${this.endpoint}/accommodation/by-country-and-provider`,
      {
        params: {
          productId: productId.toString(),
          bookingProvider: bookingProvider,
          countryId: countryId.toString(),
        },
        observe: 'response',
      }
    );
  }

  public addOrUpdate(
    productAccommodation: ProductNewAccommodation,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<ProductNewAccommodation>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.post<ProductNewAccommodation>(
      `${this.url}/${this.endpoint}`,
      { ...productAccommodation },
      {
        observe: 'response',
      }
    );
  }
}
