import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarRentalRecord } from '@pulpo/pulpo-models';

@Component({
  selector: 'booking-record-car-rental',
  templateUrl: './booking-record-car-rental.component.html',
  styleUrls: ['./booking-record-car-rental.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class BookingRecordCarRentalComponent {
  @Input() carRentalRecord: CarRentalRecord;
}
