import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'booking-consult-filter',
  templateUrl: './booking-consult-filter.component.html',
  styleUrls: ['./booking-consult-filter.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
  ],
})
export class BookingConsultFilterComponent implements OnInit {
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportResa: EventEmitter<any> = new EventEmitter<any>();
  filterForm!: FormGroup;
  destinations: string[] = [];
  products: string[] = [];
  startCities: string[] = [];
  travelerNames: string[] = [];
  companies: string[] = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initFilterForm();
  }

  private initFilterForm(): void {
    this.filterForm = this.fb.group({
      destination: [],
      product: [],
      startCity: [],
      supplier: [],
      dateBegin: [],
      dateEnd: [],
      canal: [],
      id: [],
      travelerName: [],
    });
  }

  onSelectionChange() {
    this.filterEvent.emit(this.filterForm?.value);
  }

  onResetFilters(): void {
    this.filterForm?.reset();
    this.filterEvent.emit(this.filterForm?.value);
  }
}
