import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private httpClient: HttpClient) {}

  geocode(address: string): Observable<any[]> {
    const encoded = encodeURIComponent(address);

    return this.httpClient
      .get(
        `https://nominatim.openstreetmap.org/search.php?q=${encoded}&format=jsonv2&accept-language=fr,en`
      )
      .pipe(
        map((result: any) => {
          if (result.length === 0) {
            return [];
          }
          return result;
        })
      );
  }
}
