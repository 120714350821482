import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import {
  BookingRecord,
  ContractPriceSummary,
  ILightBooking,
} from '@pulpo/pulpo-models';
import { WorkBook, WorkSheet, utils, write } from 'xlsx-js-style';

@Injectable({ providedIn: 'root' })
export class ExportService {
  public exportBookingsToExcel(
    pulpoCompany: string,
    bookings: ILightBooking[]
  ): Blob {
    const edata: Array<any> = [];
    const udt: any = {
      data: [
        { A: pulpoCompany },
        { A: 'Export dossiers', B: new Date().toLocaleString() }, // title
        {
          A: 'N° Résa',
          B: 'Statut',
          C: 'Résa',
          D: 'Départ',
          E: 'De',
          F: 'Destination',
          G: 'Produit',
          H: '1er nom',
          I: 'Pax',
          J: 'Canal',
          K: 'Distributeur',
          L: 'TTL Dossier',
          M: 'TTL Taxes',
          N: 'TTL HT',
        },
      ],
      skipHeader: true,
    };
    bookings.forEach((booking: ILightBooking) => {
      udt.data.push({
        A: booking.id,
        B: booking.status,
        C: dayjs(booking.bookingDate).toDate().toLocaleDateString(),
        D: dayjs(booking.startDate).toDate().toLocaleDateString(),
        E: booking.startIata,
        F: booking.destination,
        G: booking.productId,
        H: booking.firstPax,
        I: JSON.parse(booking?.travelerJson || '').length,
        J: booking.canal,
        K: booking.supplier,
        L: booking.totalPrice,
        M: Number(booking.totalTaxes),
        N: (booking.totalPrice || 0) - (booking.totalTaxes || 0),
      });
    });

    edata.push(udt);
    return this.exportJsonToExcel(edata);
  }

  public exportBookingRecordsToExcel(
    pulpoCompany: string,
    bookings: BookingRecord[]
  ): Blob {
    const edata: Array<any> = [];
    const udt: any = {
      data: [
        { A: pulpoCompany },
        { A: 'Export dossiers', B: new Date().toLocaleString() }, // title
        {
          A: 'N° Résa',
          B: 'Statut',
          C: 'Résa',
          D: 'Départ',
          E: 'De',
          F: 'Destination',
          G: 'Produit',
          H: '1er nom',
          I: 'Pax',
          J: 'Canal',
          K: 'Distributeur',
          L: 'TTL Dossier',
          M: 'TTL Taxes',
          N: 'TTL HT',
        },
      ],
      skipHeader: true,
    };
    bookings.forEach((booking: BookingRecord) => {
      udt.data.push({
        A: booking.businessId,
        B: booking.status?.toString() == 'VALIDATED' ? 'OK' : 'NO',
        C: dayjs(booking.creationDate).toDate().toLocaleDateString(),
        D: dayjs(booking.startDate).toDate().toLocaleDateString(),
        E: booking.startCityName,
        F: booking.productRecord?.destinationName,
        G: booking.productRecord?.productId,
        H: booking.travelerRecord?.travelerInfos[0]?.lastName,
        I:
          (booking.travelerRecord?.adultNumber || 0) +
          (booking.travelerRecord?.childNumber || 0) +
          (booking.travelerRecord?.babyNumber || 0),
        J: booking.distributionRecord?.distribution,
        K: booking.distributionRecord?.supplierName,
        L: booking.bookingPrice?.totalPrice,
        M: Number(booking.bookingPrice?.totalTaxPrice),
        N:
          (booking.bookingPrice?.totalPrice || 0) -
          (booking.bookingPrice?.totalTaxPrice || 0),
      });
    });

    edata.push(udt);
    return this.exportJsonToExcel(edata);
  }

  public exportContractPricesToExcel(
    contractPriceSummary: ContractPriceSummary
  ): Blob {
    const edata: Array<any> = [];
    const udt: any = {
      data: [
        // { A: 'Export prix du contrat', B: contractPriceSummary.contractName }, // title
        {
          A: 'Date de départ',
          B: 'Prix / pax chambre double',
          C: 'Supplément SGL',
          D: 'Pension',
          E: 'Free Sales / On Request',
          F: 'Stock / pax',
          G: 'Triple Room',
          H: 'Quad Discount',
          I: 'Child Discount',
          J: 'Child Age',
        },
      ],
      skipHeader: true,
    };
    contractPriceSummary.prices.forEach((price) => {
      udt.data.push({
        A: dayjs(price.date).format('DD.MM.YYYY'),
        B: price?.doublePrice,
        C: price?.singlePrice,
        D: price?.boardType,
        E: price?.stockStatus,
        F: price?.stockByPax,
        G: price?.tripleRoom,
        H: price?.quadDiscount,
        I: price?.childDiscount,
        J: price?.childAge,
      });
    });

    edata.push(udt);
    return this.exportJsonToCSV(edata);
  }

  private exportJsonToCSV(json: any): Blob {
    const worksheet: WorkSheet = utils.json_to_sheet(
      json[0].data,
      this.getOptions(json[0])
    );

    for (let i = 1, length = json.length; i < length; i++) {
      utils.sheet_add_json(
        worksheet,
        json[i].data,
        this.getOptions(json[i], -1)
      );
    }
    for (const key in worksheet) {
      if (worksheet[key] && typeof worksheet[key] != typeof '') {
        worksheet[key].s = {
          font: {
            sz: 10,
          },
          ...worksheet[key].s,
        };
      }
      // if (worksheet[key] && key.length == 2 && key.charAt(1) == '3') {
      //   worksheet[key].s = {
      //     fill: { fgColor: { rgb: 'DAEEF3' } },
      //     ...worksheet[key].s,
      //   };
      // }
    }
    worksheet['!cols'] = this.fitColumnWidth(json[0].data);

    const workbook: WorkBook = {
      Sheets: { Sheet1: worksheet },
      SheetNames: ['Sheet1'],
    };
    // save to file
    return new Blob(
      [this.s2ab(write(workbook, { bookType: 'csv', type: 'binary' }))],
      { type: 'application/octet-stream' }
    );
  }

  private exportJsonToExcel(json: any): Blob {
    // inserting first blank row
    const worksheet: WorkSheet = utils.json_to_sheet(
      json[0].data,
      this.getOptions(json[0])
    );

    for (let i = 1, length = json.length; i < length; i++) {
      utils.sheet_add_json(
        worksheet,
        json[i].data,
        this.getOptions(json[i], -1)
      );
    }
    for (const key in worksheet) {
      if (worksheet[key] && typeof worksheet[key] != typeof '') {
        worksheet[key].s = {
          font: {
            sz: 10,
          },
          ...worksheet[key].s,
        };
      }
      if (worksheet[key] && key.length == 2 && key.charAt(1) == '3') {
        worksheet[key].s = {
          fill: { fgColor: { rgb: 'DAEEF3' } },
          ...worksheet[key].s,
        };
      }
    }
    worksheet['!cols'] = this.fitColumnWidth(json[0].data);

    const workbook: WorkBook = {
      Sheets: { Sheet1: worksheet },
      SheetNames: ['Sheet1'],
    };
    // save to file
    return new Blob(
      [this.s2ab(write(workbook, { bookType: 'xlsx', type: 'binary' }))],
      { type: 'application/octet-stream' }
    );
  }

  private s2ab(s: string) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf); //create uint8array as viewer
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }

  private fitColumnWidth(data: string | any[]) {
    const resultMap: any = {};
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      for (const key in row) {
        if (!resultMap[key]) {
          resultMap[key] = { width: row[key].toString().length + 2 };
        }
        if (row[key] && row[key].toString().length + 2 > resultMap[key].width) {
          resultMap[key].width = row[key].toString().length + 2;
        }
      }
    }
    const result = [];
    for (const key in resultMap) {
      result.push({ width: resultMap[key].width });
    }
    return result;
  }

  private getOptions(json: any, origin?: number): any {
    // adding actual data
    const options: {
      skipHeader: boolean;
      origin: number;
      header: any[];
    } = {
      skipHeader: true,
      origin: -1,
      header: [],
    };
    options.skipHeader = json.skipHeader ? json.skipHeader : false;
    if (!options.skipHeader && json.header && json.header.length) {
      options.header = json.header;
    }
    if (origin) {
      options.origin = origin ? origin : -1;
    }
    return options;
  }
}
