import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './customRouteReuse.strategy';

export default abstract class ApiService<T> {
  url: any;
  constructor(
    environment: any,
    private httpClient: HttpClient,
    protected endpoint: string,
    private routeReuseStrategy: RouteReuseStrategy | undefined = undefined
  ) {
    this.url = environment.apiUrl;
    if (this.url.endsWith('/')) {
      this.url = this.url.substring(0, this.url.length - 1);
    }
  }

  public create(
    item: T,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<T>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.httpClient.post<T>(`${this.url}/${this.endpoint}`, item, {
      observe: 'response',
    });
  }

  public update(
    item: T,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<T>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.httpClient.put<T>(
      `${this.url}/${this.endpoint}/${(item as any).id}`,
      item,
      {
        observe: 'response',
      }
    );
  }

  public updateAll(
    items: T[],
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<T[]>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.httpClient.put<T[]>(`${this.url}/${this.endpoint}/all`, items, {
      observe: 'response',
    });
  }

  public updateFromKey(
    item: T,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<T>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.httpClient.put<T>(
      `${this.url}/${this.endpoint}/${(item as any).key}`,
      item,
      {
        observe: 'response',
      }
    );
  }

  public createMine(
    item: T,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<T>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.httpClient.post<T>(`${this.url}/${this.endpoint}/mine`, item, {
      observe: 'response',
    });
  }

  public updateMine(
    item: T,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<T>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.httpClient.put<T>(
      `${this.url}/${this.endpoint}/mine/${(item as any).id}`,
      item,
      {
        observe: 'response',
      }
    );
  }

  public get(id: number | string): Observable<HttpResponse<T>> {
    return this.find(id);
  }

  public find(id: number | string): Observable<HttpResponse<T>> {
    return this.httpClient.get<T>(`${this.url}/${this.endpoint}/${id}`, {
      observe: 'response',
    });
  }

  findAll(): Observable<HttpResponse<T[]>> {
    return this.httpClient.get<T[]>(`${this.url}/${this.endpoint}`, {
      observe: 'response',
    });
  }

  public query(req?: any): Observable<HttpResponse<T[]>> {
    const options = this.createRequestOption(req);
    return this.httpClient.get<T[]>(`${this.url}/${this.endpoint}`, {
      params: options,
      observe: 'response',
    });
  }

  public queryPage(
    req?: any
  ): Observable<HttpResponse<{ content: T[]; totalElements: number }>> {
    const options = this.createRequestOption(req);
    return this.httpClient.get<any>(`${this.url}/${this.endpoint}/page`, {
      params: options,
      observe: 'response',
    });
  }
  public queryMinePage(req?: any): Observable<HttpResponse<any>> {
    const options = this.createRequestOption(req);
    return this.httpClient.get<any>(`${this.url}/${this.endpoint}/mine/page`, {
      params: options,
      observe: 'response',
    });
  }

  public queryAll(): Observable<HttpResponse<T[]>> {
    return this.httpClient.get<T[]>(`${this.url}/${this.endpoint}/mine`, {
      observe: 'response',
    });
  }

  public delete(
    id: number | string,
    routesToClear: string[] | undefined = undefined
  ) {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.httpClient.delete(`${this.url}/${this.endpoint}/${id}`, {
      observe: 'response',
    });
  }

  public createRequestOption(req?: any): HttpParams {
    let options: HttpParams = new HttpParams();
    if (req) {
      Object.keys(req).forEach((key) => {
        options = options.set(key, req[key]);
      });
    }
    return options;
  }

  protected clearRouteReuseStrategy(keys: string[]) {
    keys.forEach((key) => {
      (this.routeReuseStrategy as CustomReuseStrategy)?.clearSavedHandle(key);
    });
  }
}
