export class CarRentalLocation {
  name!: string;
  latitude!: number;
  longitude!: number;
  sourceId!: number;
  type!: CarRentalLocationType;
}

export enum CarRentalLocationType {
  AIRPORT = 'AIRPORT',
  CITY = 'CITY',
}
