import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ValidationErrors,
} from '@angular/forms';
import { FlightReferenceService } from '@pulpo/pulpo-api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class uniqueFlightReferenceRefValidator implements AsyncValidator {
  constructor(private flightReferenceService: FlightReferenceService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.flightReferenceService.getByReference(control.value).pipe(
      map((response) => {
        return response?.body ? { existing: true } : null;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
