<div class="mat-elevation-z2 corners m-4">
    <div class="table-header row">
        <div class="col-sm-4">
            <h2 class="m-4">
                Réservation {{flightBooking.id}}
            </h2>
        </div>
        <div class="col-sm-3">

        </div>

        <div class="col-sm-3 d-flex flex-column align-items-center">
            <mat-button-toggle-group class="white mt-3 text-center" [value]="flightBooking.opened"
                (change)="onConsultChange($event.value)">
                <mat-button-toggle [value]="true">Consulté</mat-button-toggle>
                <mat-button-toggle [value]="false">Non Consulté</mat-button-toggle>
            </mat-button-toggle-group>
            <span class=" font-weight-bold white">Dernière consultation : {{flightBooking.openTimeRecord | date:
                'dd/MM/yyyy HH:mm'}}</span>
        </div>
        <div class="col-sm-2">
            <mat-card class="mt-3 text-center">
                <h4 class="font-weight-bold">{{flightBooking.status === BookingStatusEnum.PENDING ? 'En attente' :
                    (flightBooking.status
                    ===
                    BookingStatusEnum.CANCELLED ? 'Annulée': 'Confirmée')}}</h4>
            </mat-card>
        </div>
    </div>
    <div class="row white">
        <mat-divider></mat-divider>
    </div>
    <div class="p-4 mx-4">
        <table class="table table-striped ptable">
            <thead>
                <tr>
                    <th>Produit</th>
                    <th>Pays départ</th>
                    <th>Pays arrivée</th>
                    <th>Création</th>
                    <th>Modification</th>
                    <th>Canal</th>
                    <th>Agent</th>
                    <th>Distributeur</th>
                    <th>Producteur</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>Réservation vol sec</td>
                    <td>{{flightBooking.originCountry}}</td>
                    <td>{{flightBooking.destinationCountry}}</td>
                    <td>{{flightBooking.creationDate | date: 'dd/MM/YYYY'}}</td>
                    <td>{{flightBooking.lastUpdateDate | date: 'dd/MM/YYYY'}}</td>
                    <td>{{flightBooking.channel}}</td>
                    <td>{{flightBooking.lastUpdateUser}}</td>
                    <td>{{flightBooking.supplierName}}</td>
                    <td>{{producerName}}</td>
                </tr>
            </tbody>
        </table>

        <table class="table table-striped ptable" style="margin-top:5%">
            <thead>
                <tr class="row">
                    <th class="col-sm-3">
                        <mat-icon>flight_takeoff</mat-icon>
                    </th>
                    <th class="col-sm-3">
                        <mat-icon>flight_land</mat-icon>
                    </th>
                    <th class="col-sm-1">
                        <mat-icon>flight</mat-icon>
                    </th>
                    <th class="col-sm-1">
                        <mat-icon>person</mat-icon>
                    </th>
                    <th class="col-sm-1">
                        <mat-icon>child_care</mat-icon>
                    </th>
                    <th class="col-sm-1">
                        <mat-icon>work</mat-icon>
                    </th>
                    <th class="col-sm-1">
                        <mat-icon>folder</mat-icon>
                    </th>
                    <th class="col-sm-1"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let segment of flightBooking.itineraryRecords; let i = index">

                    <tr class="row"
                        [class.border-bottom]="flightBooking.itineraryRecords[i]?.reference === 'WAY' && flightBooking.itineraryRecords[i+1]?.reference === 'BACK'">
                        <td class="col-sm-3">
                            {{segment.departureTime | date: 'dd/MM/yyyy'}} {{segment.origin}} {{segment.departureTime |
                            date: 'HH:mm'}}
                        </td>
                        <td class="col-sm-3">
                            {{segment.arrivalTime | date: 'dd/MM/yyyy'}} {{segment.arrivalTime | date: 'HH:mm'}}
                            {{segment.destination}}
                        </td>
                        <td class="col-sm-1">
                            {{segment.airline}} {{segment.flightNumber}}
                        </td>
                        <td class="col-sm-1">
                            {{segment.adultNumber}}
                        </td>
                        <td class="col-sm-1">
                            {{segment.childNumber + segment.babyNumber}}
                        </td>
                        <td class="col-sm-1">
                            {{segment.luggageNumber}}
                        </td>
                        <td class="col-sm-1">
                            {{segment.booking.reference}}
                        </td>
                        <td class="col-sm-1">
                            {{segment.status}}
                        </td>
                    </tr>

                </ng-container>
            </tbody>
        </table>

        <mat-tab-group mat-stretch-tabs="false" dynamicHeight style="margin-top:10%">
            <mat-tab label="Passagers">
                <table class="table table-striped ptable m-4" style="min-height: 200px">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Prenom</th>
                            <th>Date de naissance</th>
                            <th>Titre</th>
                            <th>Bagages</th>
                        </tr>
                    </thead>
                    <ng-container *ngFor="let traveler of travelers">
                        <tbody>
                            <tr>
                                <td>{{traveler.lastName}}</td>
                                <td>{{traveler.firstName}}</td>
                                <td>{{traveler.birthDate | date: 'dd/MM/YYYY'}}</td>
                                <td>{{getTitleFromTraveler(traveler)}}</td>
                                <td>{{traveler.luggage}}</td>
                            </tr>
                        </tbody>
                    </ng-container>
                </table>
            </mat-tab>
            <mat-tab label="Finances">
                <table class="table table-striped ptable m-4" style="min-height: 200px">
                    <thead>
                        <tr>
                            <th>Source</th>
                            <th>Référence</th>
                            <th>Statut</th>
                            <th>Vol TTC</th>
                            <th>Bagages</th>

                            <th>Réservation</th>
                            <th>Mode</th>
                            <th>TTL HT</th>
                            <th>Taxes</th>
                            <th>TTL TTC</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{flightBooking.source}}</td>
                            <td>{{flightBooking.reference}}</td>
                            <td>{{flightBooking.status}}</td>
                            <td>{{flightBooking.priceTtc}} €</td>
                            <td>{{flightBooking.luggagePrice}} €</td>
                            <td>{{flightBooking.emissionDate | date: 'dd/MM/YYYY'}}</td>
                            <td>{{flightBooking.paymentForm}}</td>
                            <td>{{flightBooking.totalHt}} €</td>
                            <td>{{flightBooking.taxes}} €</td>
                            <td>{{flightBooking.totalTtc}} €</td>
                        </tr>
                    </tbody>
                </table>
                <ng-container
                    *ngIf="this.flightBooking && this.flightBooking.source.toLowerCase().includes('travelport')">
                    <ng-container *ngIf="flightBooking.ticketNumber; else ticketButton">
                        <table class=" table table-striped ptable m-4">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Ticket</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{flightBooking.emissionDate | date: 'dd/MM/YYYY HH:mm'}}</td>
                                    <td>{{flightBooking.ticketNumber}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>
                    <ng-template #ticketButton>
                        <table class=" table table-striped ptable m-4">
                            <thead>
                                <tr>
                                    <th>Procéder au ticketing</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <button mat-raised-button
                                            [class.btn-disabled]="this.isTicketingBookingEnabled()"
                                            [loading]="isTicketingProcess"
                                            [class.btn-pulpo]="!this.isTicketingBookingEnabled()" matTooltip="Ticketing"
                                            (click)="processTicketing()">
                                            <mat-icon class="colored scale-2 m-3">confirmation_number</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </ng-template>
                </ng-container>

            </mat-tab>
        </mat-tab-group>
    </div>
</div>