import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRightEnum } from '@pulpo/pulpo-models';
import { AuthService, RoleService } from '@pulpo/pulpo-api';

@Directive({
  selector: `[hasPermissions]`,
  standalone: true,
})
export class HasPermissionsDirective {
  private permissions!: { name: string; right: UserRightEnum }[];
  constructor(
    private roleService: RoleService,
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input('hasPermissions')
  set hasPermission(value: { name: string; right: UserRightEnum }[]) {
    this.permissions = value;
    this.updateView();
    // Get notified each time authentication state changes.
    this.authService.onLogged$.subscribe(() => this.updateView());
  }

  private updateView(): void {
    this.viewContainerRef.clear();
    if (this.roleService.hasPermissions(this.permissions)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
