import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { InsuranceProviderAccount } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InsuranceProviderAccountService extends ApiService<InsuranceProviderAccount> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'insurance/api/provider-account');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8084';
    }
  }

  public getCredentialsByCompanyId(): Observable<
    HttpResponse<InsuranceProviderAccount[]>
  > {
    return this.http.get<InsuranceProviderAccount[]>(
      `${this.url}/${this.endpoint}`,
      {
        observe: 'response',
      }
    );
  }

  public override updateAll(credentials: InsuranceProviderAccount[]) {
    return this.http.post<InsuranceProviderAccount[]>(
      `${this.url}/${this.endpoint}/all`,
      credentials,
      {
        observe: 'response',
      }
    );
  }
}
