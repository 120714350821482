import { Company } from '../company';
import { CommissionProfile } from './commissionProfile.model';

export class Supplier {
  id!: number;
  name!: string;
  reference!: string;
  owner!: Company;
  supplierOrchestra!: string;
  supplierPulpo!: string;
  supplierSpeedmedia!: string;
  supplierPulpoId!: number;
  isB2b2c!: boolean;
  active!: boolean;
  profile!: CommissionProfile;
  agencyCount!: number;
  supplyFlight!: boolean;
  removable!: boolean;
  refDistri!: string;
}


