<table class="table w-100 h-100">
    <thead>
        <tr *ngIf="isCompatibleFormat()">
            <th>Détails</th>
            <th></th>
            <th>Qté</th>
            <th>ADT</th>
            <th>CHD</th>
            <th>BB</th>
            <th></th>
            <th>Com</th>
            <th>Montant</th>
        </tr>
        <tr *ngIf="!isCompatibleFormat()">
            <th>Détails</th>
            <th></th>
            <th>Qté</th>
            <th>ADT</th>
            <th>CHD</th>
            <th>BB</th>
            <th></th>
            <th></th>
            <th>Montant</th>
        </tr>
    </thead>
    <tbody *ngIf="rooms" style="border-radius: 10px;">
        <tr class="background">
            <td><span style="font-weight: bold"> FORFAIT </span></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> <span *ngIf="isCompatibleFormat()" style="font-size: small">
                    {{newSelection?.prices?.packageCommission?.toFixed(2)}}&nbsp;€ </span></td>
            <td> <span> {{getPackagePrice()}}&nbsp;€ </span> </td>
        </tr>
    </tbody>
    <tbody>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    <tbody *ngIf="rooms">
        <tr class="background corners">
            <td><span class="font-italic"> Hébergement </span> </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    <tbody *ngFor="let room of rooms? rooms : [];  let i = index">
        <tr>
            <td><span style="font-size: small"
                    [matTooltip]="room.type?.length! > 10 ? room.type || '' : ''">{{room.type?.length!
                    > 10 ? room.type?.substring(0,9)+"..." : room.type}}</span></td>
            <td><span style="font-size: small">{{parseCategory(room.category || '')}}</span></td>
            <td>{{1}}</td>
            <td>{{room.adult}}</td>
            <td>{{room.child}}</td>
            <td>{{room.baby}}</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    <tbody *ngIf="totalBoard && totalBoard > 0 && rooms">
        <tr>
            <td><span style="font-size: small">{{selectedBoard}}</span></td>
            <td></td>
            <td>{{totalPerson}}</td>
            <td>{{adult}}</td>
            <td>{{child}}</td>
            <td>{{baby}}</td>
            <td></td>
            <td> </td>
            <td> <span>{{getMealPrice()}}</span></td>
        </tr>
    </tbody>
    <tbody *ngIf="flightOffer">
        <tr class="background corners">
            <td> <span class="font-italic"> Transport </span></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    <tbody *ngIf="flightOffer">
        <tr>
            <td><span style="font-size: small"> Vols </span></td>
            <td></td>
            <td>{{totalPerson}}</td>
            <td>{{adult}}</td>
            <td>{{child}}</td>
            <td>{{baby}}</td>
            <td></td>
            <td></td>
            <td> <span>Inclus </span> </td>
        </tr>
    </tbody>
    <tbody *ngIf="flightOffer">
        <tr *ngIf="!isCompatibleFormat()">
            <td><span style="font-size: small"> Taxes </span></td>
            <td></td>
            <td>{{totalPerson}}</td>
            <td>{{adult}}</td>
            <td>{{child}}</td>
            <td>{{baby}}</td>
            <td></td>
            <td><span> {{parseFlightTax()}}</span></td>
            <td> <span>Inclus </span> </td>
        </tr>

        <tr *ngIf="isCompatibleFormat()">
            <td><span style="font-size: small"> Taxes </span></td>
            <td></td>
            <td>{{totalPerson}}</td>
            <td>{{adult}}</td>
            <td>{{child}}</td>
            <td>{{baby}}</td>
            <td></td>
            <td></td>
            <td><span>{{parseFlightTax()}}</span></td>
        </tr>
    </tbody>
    <tbody
        *ngIf="!isCompatibleFormat() && getSelectionPrices()?.flightPrice && extraFlightCosts && extraFlightCosts > 0">
        <tr>
            <td><span style="font-size: small"> Supplément vol </span></td>
            <td></td>
            <td>{{1}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><span> {{parsePrice(extraFlightCosts)}} € </span></td>
        </tr>
    </tbody>
    <tbody *ngIf="flightOffer && numberOfLuggage && numberOfLuggage> 0">
        <tr>
            <td><span style="font-size: small"> Bagages </span></td>
            <td></td>
            <td>{{numberOfLuggage}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><span *ngIf="isCompatibleFormat()" style="font-size: small">
                    {{newSelection?.prices?.totalLuggageCommission ?
                    newSelection?.prices?.totalLuggageCommission?.toFixed(2) : '0.00'}} € </span></td>
            <td><span> {{parsePrice(getSelectionPrices()?.luggagePrice)}} € </span></td>
        </tr>
    </tbody>
    <tbody *ngIf="getSelection && getSelection.options && getSelection.options.length > 0">
        <tr class="background corners">
            <td> <span class="font-italic"> Options </span></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    <tbody *ngFor="let option of getSelection.options? getSelection.options : [];  let i = index">
        <tr *ngIf="option.occupationType === 'OPTION'">
            <td><span style="font-size: small"
                    [matTooltip]="option.name.length! > 10 ? option.name || '' : ''">{{option.name.length! > 10 ?
                    option.name.substring(0,9)+'...' : option.name}}</span></td>
            <td></td>
            <td>{{getSelection && getSelection.optionInfoList && getSelection.optionInfoList.length! > i &&
                getSelection.optionInfoList[i] ?
                getSelection.optionInfoList[i] : ''}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><span *ngIf="isCompatibleFormat()" style="font-size: small">
                    {{getOptionCommission(option.id)}} € </span></td>
            <td><span> {{getOptionPrice(option.id,i)}}</span></td>
        </tr>
        <tr *ngIf="option.occupationType === 'PERSON'">
            <td><span style="font-size: small"
                    [matTooltip]="option.name.length > 10 ? option.name : ''">{{option.name.length > 10 ?
                    option.name.substring(0,9)+'...' : option.name}}</span></td>
            <td></td>
            <td></td>
            <td>{{getSelection && getSelection.optionInfoList && getSelection.optionInfoList.length > i ?
                parseOptionNumber(getSelection.optionInfoList[i],'adult') : 'N/A'}}</td>
            <td>{{getSelection && getSelection.optionInfoList && getSelection.optionInfoList.length > i ?
                parseOptionNumber(getSelection.optionInfoList[i],'child') : 'N/A'}}</td>
            <td>{{getSelection && getSelection.optionInfoList && getSelection.optionInfoList.length > i ?
                parseOptionNumber(getSelection.optionInfoList[i],'baby') : 'N/A'}}</td>
            <td></td>
            <td><span *ngIf="isCompatibleFormat()" style="font-size: small">
                    {{getOptionCommission(option.id || 0)}} € </span></td>
            <td><span> {{getOptionPrice(option.id || 0,i)}}</span></td>
        </tr>
    </tbody>
    <tbody *ngIf="getSelection && getSelection.insurance">
        <tr class="background corners">
            <td><span class="font-italic">Assurances </span></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    <tbody *ngIf="getSelection?.insurance && getSelection.insurance.insuranceTypeId">
        <tr>
            <td><span style="font-size: small">{{getSelection.insurance.insuranceName}}</span></td>
            <td><span style="font-size: small"
                    [matTooltip]="getSelection.insurance.insuranceTypeName?.length! > 10 ? getSelection.insurance.insuranceTypeName || '' : ''">{{getSelection.insurance.insuranceTypeName?.length!
                    > 10 ? getSelection.insurance.insuranceTypeName?.substring(0,9)+"..." :
                    getSelection.insurance.insuranceTypeName}}</span></td>
            <td></td>
            <td>{{adult}}</td>
            <td>{{child}}</td>
            <td>{{baby}}</td>
            <td></td>
            <td><span *ngIf="isCompatibleFormat()" style="font-size: small">
                    {{newSelection?.prices?.totalInsuranceCommission ?
                    newSelection?.prices?.totalInsuranceCommission?.toFixed(2) : '0.00'}} € </span></td>
            <td><span>{{parsePrice(getSelectionPrices()?.insurancePrice)}} €</span></td>
        </tr>
    </tbody>

    <tbody *ngIf="getSelection && getSelection.carRentalName">
        <tr class="background corners">
            <td><span class="font-italic">Locations de voiture </span></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
    <tbody *ngIf="getSelection?.carRentalName">
        <tr>
            <td><span style="font-size: small">{{getSelection.carRentalProvider}}</span></td>
            <td><span style="font-size: small"
                    [matTooltip]="getSelection.carRentalName.length! > 10 ? getSelection.carRentalName || '' : ''">{{getSelection.carRentalName.length!
                    > 10 ? getSelection.carRentalName?.substring(0,9)+"..." :
                    getSelection.carRentalName}}</span></td>
            <td></td>
            <td>{{adult}}</td>
            <td>{{child}}</td>
            <td>{{baby}}</td>
            <td></td>
            <td><span *ngIf="isCompatibleFormat() && newSelection?.carRentalMandatory === false"
                    style="font-size: small">
                    {{newSelection?.carRentalCommission ?
                    newSelection?.carRentalCommission?.toFixed(2) : '0.00'}} € </span></td>
            <td><span *ngIf="newSelection?.carRentalMandatory === false">{{
                    getSelection.carRentalTotalPrice
                    ? getSelection.carRentalTotalPrice?.toFixed(2): ''
                    }} €</span></td>
        </tr>
    </tbody>
    <tbody>
        <tr class="background corners">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    </tbody>
</table>
<div class="row w-100">
    <div class="col-sm-6">

    </div>
    <div class="col-sm-6">
        <table class="w-100">
            <tbody class="w-100 background corners">
                <tr>
                    <td>
                        <h5>TOTAL</h5>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <h5 class="font-weight-bold">{{parsePrice(getSelectionPrices()?.totalPrice)}}&nbsp;€</h5>
                    </td>
                </tr>
            </tbody>
            <tbody class="w-100">
                <tr *ngIf="commissionAmount && commissionRealPrice">
                    <td><span class="font-italic">Commission</span></td>
                    <td><span class="font-italic"></span></td>
                    <td><span class="font-italic"></span></td>
                    <td><span>{{parsePrice(commissionRealPrice)}}&nbsp;€</span></td>
                </tr>
                <tr *ngIf="commissionAmount && !commissionRealPrice">
                    <td><span class="font-italic">Commission</span></td>
                    <td><span class="font-italic"></span></td>
                    <td><span class="font-italic"></span></td>
                    <td><span>{{getComissionPrice()}}&nbsp;€</span></td>
                </tr>
            </tbody>
            <tbody class="w-100 background corners">
                <tr *ngIf="commissionAmount && commissionPrice">
                    <td>
                        <h5>A PAYER</h5>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                        <h5 class="font-weight-bold">{{displayPrice()}}&nbsp;€</h5>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>