import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { InsuranceProviderSettings } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InsuranceProviderSettingsService extends ApiService<InsuranceProviderSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'insurance/api/insurance-provider-settings');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8084';
    }
  }

  public getByCompanyId(): Observable<HttpResponse<InsuranceProviderSettings>> {
    return this.http.get<InsuranceProviderSettings>(
      `${this.url}/${this.endpoint}/by-company-id`,
      {
        observe: 'response',
      }
    );
  }

  public override updateAll(
    items: InsuranceProviderSettings[]
  ): Observable<HttpResponse<InsuranceProviderSettings[]>> {
    return this.http.post<InsuranceProviderSettings[]>(
      `${this.url}/${this.endpoint}`,
      items,
      {
        observe: 'response',
      }
    );
  }
}
