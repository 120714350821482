import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BatchContentService extends ApiService<any> {
  constructor(
    protected http: HttpClient,
    @Inject(APP_CONFIG) environment: any
  ) {
    super(environment, http, 'api/batch/content');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8082';
    }
  }

  public getPreview(inputHtml: string): Observable<HttpResponse<any>> {
    return this.http.post<string>(
      `${this.url}/${this.endpoint}/preview`,
      inputHtml,
      {
        observe: 'response',
      }
    );
  }
}
