<form name="editForm" class="w-100" role="form" novalidate [formGroup]="filterForm">
    <div class="row background justify-content-center filters w-100 h-25">
        <div class="col-sm-3">
            <mat-form-field appearance="fill" class="white w-75">
                <mat-label>N° Réservation</mat-label>
                <input formControlName="businessId" matInput placeholder="Chercher une réservation..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Destination</mat-label>
                <input formControlName="destinationName" matInput placeholder="Chercher par destination..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-2">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Produit</mat-label>
                <input formControlName="productReference" matInput placeholder="Chercher par produit..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-2">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Ville de départ</mat-label>
                <input formControlName="startCityName" matInput placeholder="Chercher par ville de départ..."
                    type="text" (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-2">
            <button mat-flat-button color="warn" class="ml-2 mt-2" (click)="onResetFilters()"
                matTooltip="Réinitialiser les filtres">
                <mat-icon>clear_all</mat-icon>
            </button>
            <button mat-flat-button class="btn-pulpo ml-2 mt-2" matTooltip=" Exporter les réservations"
                (click)="exportResa.emit()" data-testid="booking-export-button">
                <mat-icon>table_chart</mat-icon>
            </button>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Distributeur</mat-label>
                <input formControlName="supplierName" matInput placeholder="Chercher par distributeur..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Passager</mat-label>
                <input formControlName="travelerName" matInput placeholder="Chercher par passager..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-form-field class="w-75" appearance="fill">
                <mat-label>Canal</mat-label>
                <input formControlName="canal" matInput placeholder="Chercher par canal..." type="text"
                    (keyup)="onSelectionChange()" />
            </mat-form-field>
        </div>
        <div class="col-sm-3">
            <mat-slide-toggle class="ml-2 mt-2" formControlName="draft" color="primary" (change)="onSelectionChange()">
                Afficher les dossiers draft
            </mat-slide-toggle>
            <mat-slide-toggle class="ml-2 mt-2" formControlName="failed" color="primary" (change)="onSelectionChange()">
                Afficher les dossiers en echec
            </mat-slide-toggle>
        </div>

    </div>
</form>