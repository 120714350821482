import { Price } from './price.model';

export interface IUpdatePrices {
  prices?: Price[];
  contractId?: number;
  manual?: boolean;

  discount?: boolean;
}

export class UpdatePrices implements IUpdatePrices {
  contractId!: number;
  manual!: boolean;
  prices!: Price[];

  discount!: boolean;
}


