import { CommissionProfile } from './commissionProfile.model';
import { CommissionCancellationEnumModel } from './enums/commission-cancellation-enum.model';

export interface ICommissionCancellation {
  id?: number;
  startPeriod?: number;
  endPeriod?: number;
  base?: number;
  discount?: number;
  cancellationType?: CommissionCancellationEnumModel;
  addFlight?: boolean;
  profile?: CommissionProfile;
}

export class CommissionCancellation implements ICommissionCancellation {
  addFlight!: boolean;
  base!: number;
  cancellationType!: CommissionCancellationEnumModel;
  discount!: number;
  endPeriod!: number;
  id!: number;
  profile!: CommissionProfile;
  startPeriod!: number;
}
