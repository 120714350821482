import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommissionProfile } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from '../api.service.abstract';

@Injectable({ providedIn: 'root' })
export class CommissionProfileService extends ApiService<CommissionProfile> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/commission-profiles');
  }

  findProfileByCompany(
    id: number
  ): Observable<HttpResponse<CommissionProfile[]>> {
    return this.http.get<CommissionProfile[]>(
      `${this.url}/${this.endpoint}/company`,
      {
        params: {
          companyId: id,
        },
        observe: 'response',
      }
    );
  }
}
