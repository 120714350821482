import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentRecord } from '@pulpo/pulpo-models';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'booking-record-payment',
  templateUrl: './booking-record-payment.component.html',
  styleUrls: ['./booking-record-payment.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule],
})
export class BookingRecordPaymentComponent {
  @Input() paymentRecords: PaymentRecord[];
}
