import { Pipe, PipeTransform } from '@angular/core';
import { TypeBaseEnum } from '@pulpo/pulpo-models';

@Pipe({
  name: 'OptionBasePipe',
  standalone: true,
  pure: true,
})
export class OptionBasePipe implements PipeTransform {
  transform(value: TypeBaseEnum): string {
    switch (value) {
      case TypeBaseEnum.PERSON:
        return 'Par personne';
      case TypeBaseEnum.UNIT:
        return 'Par unité';
      default:
        return 'Inconnu';
    }
  }
}
