import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { StockTag } from '@pulpo/pulpo-models';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StockTagService extends ApiService<StockTag> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/stock-tags/report', routeReuse);
  }

  getLastStockTags(): Observable<HttpResponse<StockTag[]>> {
    return this.http.get<StockTag[]>(`${this.url}/${this.endpoint}/last`, {
      observe: 'response',
    });
  }
}
