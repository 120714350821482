import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMeal } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class MealService extends ApiService<IMeal> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/meals', routeReuse);
  }

  findAllByProduct(id: number): Observable<HttpResponse<IMeal[]>> {
    return this.http.get<IMeal[]>(
      `${this.url}/${this.endpoint}?productId=${id}`,
      {
        observe: 'response',
      }
    );
  }

  updateAllByProduct(
    id: number,
    meals: IMeal[],
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<IMeal[]>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.post<IMeal[]>(
      `${this.url}/${this.endpoint}/all?productId=${id}`,
      meals,
      {
        observe: 'response',
      }
    );
  }
}
