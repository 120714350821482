<div class="sticky-button-info">
    <button mat-icon-button class="colored btn-info-custom mb-4" matTooltip="Afficher/Cacher la légende"
        (click)="this.hideInfo = !this.hideInfo">
        <mat-icon class="scale-2">info</mat-icon>
    </button>
    <mat-card *ngIf="!this.hideInfo" class="corners mat-elevation-z2">
        <div class="row justify-content-center m-2">
            <h5> Legende </h5>
        </div>
        <div class="row justify-content-center m-2">
            <mat-divider></mat-divider>
        </div>
        <div *ngFor="let line of legend" class="row justify-content-center fixed-height mt-2">
            <div class="col-sm-2">
                <mat-card [class]="line.colorClass" [class.w-75]="true" [class.mat-card]="true">
                </mat-card>
            </div>
            <div class="col-sm-10" [innerHTML]="line.innerHTML">
            </div>
        </div>
    </mat-card>
</div>