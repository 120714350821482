import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import ApiService from './api.service.abstract';
import { APP_CONFIG } from '../../../app-config';
import { IAirline } from '@pulpo/pulpo-models';

@Injectable({ providedIn: 'root' })
export class AirlineService extends ApiService<IAirline> {
  airlinesChangedSubject = new Subject<IAirline[]>();
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/airlines');
  }
}
