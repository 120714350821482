import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PulpoImageAddComponent } from '../pulpo-image-add/pulpo-image-add.component';
import { Picture } from '@pulpo/pulpo-models';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'pulpo-image-slider',
  templateUrl: './pulpo-image-slider.component.html',
  styleUrls: ['./pulpo-image-slider.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    DragDropModule,
    MatButtonModule,
  ],
})
export class PulpoImageSliderComponent {
  @Input() images: Picture[] | undefined;
  @Output() imagesChange = new EventEmitter<Picture[]>();
  @Output() deleteImage = new EventEmitter<Picture>();

  constructor(private dialog: MatDialog) {}

  onAddButtonClicked(event?: Picture | undefined) {
    this.dialog
      .open(PulpoImageAddComponent, {
        width: '60%',
        height: '80%',
        data: {
          picture: event,
        },
      })
      .afterClosed()
      .subscribe((newPicture) => {
        if (newPicture) {
          if (event) {
            const index = this.images?.findIndex((i) => i.id === event.id);
            if (index !== undefined && index !== -1) {
              newPicture.id = event.id;
              this.images?.splice(index, 1, newPicture);
            }
          } else {
            this.images?.push(newPicture);
          }
          this.imagesChange.emit(this.images);
        }
      });
  }

  onDeleteButtonClicked(event: any, picture: Picture) {
    event.stopPropagation();
    if (this.images) {
      this.images = this.images.filter((i) => i.id !== picture.id);
      this.deleteImage.emit(picture);
    }
  }

  onSlideButtonClicked(direction: string) {
    if (direction === 'left') {
      document.getElementById('slider')?.scrollBy(-750, 0);
    } else {
      document.getElementById('slider')?.scrollBy(750, 0);
    }
  }

  dropImage(event: CdkDragDrop<Picture[]>) {
    moveItemInArray(this.images || [], event.previousIndex, event.currentIndex);
    this.imagesChange.emit(this.images);
  }

  changeImageValue(event: any, picture: Picture) {
    event.stopPropagation();
    console.log(picture.productCover);
    this.imagesChange.emit(this.images);
  }
}
