import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import ApiService from './api.service.abstract';
import { IDestination } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class DestinationService extends ApiService<IDestination> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/destinations');
  }

  queryMine(req?: any): Observable<HttpResponse<IDestination[]>> {
    const options = this.createRequestOption(req);
    return this.http.get<IDestination[]>(
      `${this.url}/${this.endpoint}` + '/mine',
      {
        params: options,
        observe: 'response',
      }
    );
  }

  queryStartDestination(): Observable<HttpResponse<IDestination[]>> {
    return this.http.get<IDestination[]>(
      `${this.url}/${this.endpoint}` + '/IsStartDestination',
      { observe: 'response' }
    );
  }
}
