import {
  Directive,
  Inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';

@Directive({
  selector: `[hideForEnvs]`,
  standalone: true,
})
export class HideForEnvsDirective {
  constructor(
    @Inject(APP_CONFIG) private environment: any,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  @Input()
  set hideForEnvs(envs: string[]) {
    this.updateView(envs);
  }

  private updateView(envs: string[]): void {
    this.viewContainerRef.clear();
    if (!envs.includes(this.environment.name)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
