import { Pipe, PipeTransform } from '@angular/core';
import { AirportService } from '@pulpo/pulpo-api';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'AirportIataToCityNamePipe',
  standalone: true,
  pure: true,
})
export class AirportIataToCityNamePipe implements PipeTransform {
  constructor(private airportService: AirportService) {}
  transform(iata?: string): Observable<string | undefined> {
    if (!iata) return new Observable<string | undefined>();
    return this.airportService.findAllWithCache().pipe(
      map((res) => {
        const airport = res.body?.find((a) => a.iata === iata);
        return airport?.city?.name;
      })
    );
  }
}
