import { AccommodationCache } from './accommodation-cache.model';
import { AccommodationPulpoBoardPrice } from './accommodation-pulpo-board-price.model';
import { AccommodationPulpoBoard } from './accommodation-pulpo-board.model';
import { PulpoCacheRoom } from './pulpo-cache-room.model';

export class AccommodationPulpoCache extends AccommodationCache {
  destinationId: number;
  pulpoCacheRoomList: PulpoCacheRoom[];
  boardList: AccommodationPulpoBoard[];
  boardPriceDurationDTOList: AccommodationPulpoBoardPrice[];
  cityId: number;
}
