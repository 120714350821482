import { EnumTypeProduct } from '@pulpo/pulpo-models';

export class ProductUtils {
  static translateFromType(value: EnumTypeProduct): string {
    switch (value) {
      case 'COMBINED':
        return 'Combiné';
      case 'CIRCUIT':
        return 'Circuit';
      case 'HOTEL':
        return 'Séjour';
      case 'AUTOTOUR':
        return 'Autotour';
      case 'RESIDENCE':
        return 'Locatif';
      default:
        return 'Sans type';
    }
  }

  static getType(type: EnumTypeProduct): string {
    if (type === EnumTypeProduct.CIRCUIT) {
      return 'Circuit';
    }
    if (type === EnumTypeProduct.HOTEL) {
      return 'Séjour';
    }
    if (type === EnumTypeProduct.AUTOTOUR) {
      return 'Autotour';
    }
    if (type === EnumTypeProduct.COMBINED) {
      return 'Combiné';
    }
    if (type === EnumTypeProduct.RESIDENCE) {
      return 'Locatif';
    }
    return 'Sans type';
  }

  static translateFromCategory(category: string) {
    if (!category) {
      return '';
    }
    const cat = Number(category);
    if (!isNaN(cat)) {
      switch (cat) {
        case 2:
          return '\u2605' + '\u2605';
        case 3:
          return '\u2605' + '\u2605' + '\u2605';
        case 4:
          return '\u2605' + '\u2605' + '\u2605' + '\u2605';
        case 5:
          return '\u2605' + '\u2605' + '\u2605' + '\u2605' + '\u2605';
        default:
          return 'Sans catégorie';
      }
    } else {
      if (category.includes('*')) {
        const newCat = category.replace('*', '');
        const newCatNb = Number(newCat);
        if (!isNaN(newCatNb)) {
          switch (newCatNb) {
            case 2:
              return '\u2605' + '\u2605';
            case 3:
              return '\u2605' + '\u2605' + '\u2605';
            case 4:
              return '\u2605' + '\u2605' + '\u2605' + '\u2605';
            case 5:
              return '\u2605' + '\u2605' + '\u2605' + '\u2605' + '\u2605';
            default:
              return 'Sans catégorie';
          }
        } else {
          return category;
        }
      }
      return category;
    }
  }
}
