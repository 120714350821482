import { Dayjs } from 'dayjs';

export interface IExcelImport {
  id?: number;
  contratId?: number;
  fileName?: string;
  uploadDate?: Dayjs | string;
  fileUploadUser?: string;
}

export class ExcelImport {
  id?: number;
  contratId?: number;
  fileNameBase?: string;
  uploadDateBase?: Dayjs | string;
  fileUploadUserBase?: string;
  fileNamePromo?: string;
  uploadDatePromo?: Dayjs | string;
  fileUploadUserPromo?: string;
}
