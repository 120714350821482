import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ShortTextPipe',
  standalone: true,
  pure: true,
})
export class ShortTextPipe implements PipeTransform {
  transform(value?: string, ...args: string[]): string {
    const length = args[0] ? parseInt(args[0]) : 10;
    return (value?.length || 0) > length
      ? value?.substring(0, length) + '...'
      : value || '';
  }
}
