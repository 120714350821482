import { Pipe, PipeTransform } from '@angular/core';
import xmlFormat from 'xml-formatter';

@Pipe({
  name: 'XmlFormatPipe',
  standalone: true,
  pure: true,
})
export class XmlFormatPipe implements PipeTransform {
  transform(value?: string): string {
    return value && value !== '' ? xmlFormat(value) : '';
  }
}
