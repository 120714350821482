import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IExcelImport } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';

type EntityResponseType = HttpResponse<IExcelImport>;

@Injectable({ providedIn: 'root' })
export class ExcelImportService extends ApiService<IExcelImport> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/excel-imports');
  }

  findByContratId(contratId: number): Observable<EntityResponseType> {
    return this.http.get<IExcelImport>(
      `${this.url}/${this.endpoint}?contractId=${contratId}`,
      {
        observe: 'response',
      }
    );
  }

  save(excelImport: IExcelImport): Observable<EntityResponseType> {
    return this.http.post<IExcelImport>(
      `${this.url}/${this.endpoint}` +
        (excelImport.id ? `/${excelImport.id}` : ''),
      excelImport,
      {
        observe: 'response',
      }
    );
  }
}
