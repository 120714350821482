import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';
import { ILightProduct } from '@pulpo/pulpo-models';
import { Observable } from 'rxjs';
import ApiService from './api.service.abstract';

@Injectable({ providedIn: 'root' })
export class LightProductService extends ApiService<ILightProduct> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/products/light');
  }

  getBySupplierId(
    supplierId: number
  ): Observable<HttpResponse<ILightProduct[]>> {
    return this.http.get<ILightProduct[]>(`${this.url}/${this.endpoint}`, {
      params: {
        supplierCompanyId: supplierId,
      },
      observe: 'response',
    });
  }
}
