import { BookingInterval } from "./bookingInterval.model";
import { Passenger } from "./passenger.model";

export class BookingOption {
    id!: string;
    code?: string;
    description?: string;
    details?: string;
    included?: boolean; // included in package
    combination?: BookingEnumCombination; // XOR or OR group
    travellers?: Passenger[]; // if per person (included)
    type?: BookingEnumType;
    price?: number;
    reducedPrice?: number; // if per person (child price)
    adult?: BookingInterval;
    child?: BookingInterval;
    quantity?: BookingInterval;
}

export enum BookingEnumCombination {
    XOR, OR
}

export enum BookingEnumType {
    PER_PERSON = "PER_PERSON", PER_UNIT = "PER_UNIT"
}