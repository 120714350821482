import { Dayjs } from 'dayjs';
import { ICity } from '../admin';
import { Contract } from '../booking';
// import { IPeriode } from '../flight';
// import { IMeal } from '../prestation';
import { EnumStockStatus } from '../stock';
import { Occupation } from './enums/occupation.model';

export interface IPrice {
  id?: number;
  activated?: boolean;
  price?: number;
  basePrice?: Occupation;
  singlePrice?: number;
  doublePrice?: number;
  triplePrice?: number;
  quadruplePrice?: number;
  childOnePrice?: number;
  childrenTwoPrice?: number;
  babiesPrice?: number;
  date?: Dayjs;
  stockStatus?: EnumStockStatus;
  maxBooking?: number;
  retrocession?: number;
  retrocessionStockStatus?: EnumStockStatus;
  contract?: Contract;
  // periode?: IPeriode;
  startCity?: ICity;
  possibleStartCity?: ICity[];
  possibleCategory?: string[];
  possibleRoom?: string[];
  accompte?: number;
  // possibleMeal?: IMeal[];
  possibleNight?: string[];
  discount?: boolean;
}

export class Price implements IPrice {
  constructor(
    public id?: number,
    public activated?: boolean,
    public price?: number,
    public basePrice?: Occupation,
    public singlePrice?: number,
    public doublePrice?: number,
    public triplePrice?: number,
    public quadruplePrice?: number,
    public childOnePrice?: number,
    public childrenTwoPrice?: number,
    public babiesPrice?: number,
    public date?: Dayjs,
    public stockStatus?: EnumStockStatus,
    public maxBooking?: number,
    public retrocession?: number,
    public retrocessionStockStatus?: EnumStockStatus,
    public contract?: Contract,
    // public periode?: IPeriode,
    public startCity?: ICity,
    public accompte?: number,
    public possibleStartCity?: ICity[],
    public possibleCategory?: string[],
    public possibleRoom?: string[],
    // public possibleMeal?: IMeal[],
    public possibleNight?: string[],
    public discount?: boolean
  ) {}
}
