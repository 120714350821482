import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import ApiService from './api.service.abstract';
import { FlightBookingSettings } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FlightBookingSettingsService extends ApiService<FlightBookingSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/flight-booking-settings');
  }

  public getOrCreate(
    companyId: number
  ): Observable<HttpResponse<FlightBookingSettings>> {
    return this.http.get<FlightBookingSettings>(
      `${this.url}/${this.endpoint}/getOrCreate`,
      { params: { companyId: companyId.toString() }, observe: 'response' }
    );
  }
}
