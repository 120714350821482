import { Inject, Injectable } from '@angular/core';
import { GroupOption, Option } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupOptionService extends ApiService<GroupOption> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'option/api/group', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8087';
    }
  }

  getOptionsByCountryNotInGroup(
    groupId: string
  ): Observable<HttpResponse<Option[]>> {
    return this.http.get<Option[]>(`${this.url}/${this.endpoint}/${groupId}`, {
      observe: 'response',
    });
  }

  getOptionsByCountryNotInProduct(
    countryId: number,
    productId: number
  ): Observable<HttpResponse<Option[]>> {
    return this.http.get<Option[]>(`${this.url}/${this.endpoint}/country`, {
      params: {
        productId: productId.toString(),
        countryId: countryId.toString(),
      },
      observe: 'response',
    });
  }

  getByProduct(productId: number): Observable<HttpResponse<GroupOption[]>> {
    return this.http.get<GroupOption[]>(
      `${this.url}/${this.endpoint}/product/${productId}`,
      {
        observe: 'response',
      }
    );
  }

  getAllProductIdsForOption(
    optionId: number
  ): Observable<HttpResponse<number[]>> {
    return this.http.get<number[]>(
      `${this.url}/${this.endpoint}/products/${optionId}`,
      {
        observe: 'response',
      }
    );
  }
}
