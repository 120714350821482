<div class="row col-12 fixed-height-300">
  <table class="col-8 table table-striped" aria-describedby="page-heading">
    <thead>
      <tr>
        <th>Nom</th>
        <th>Prénom</th>
        <th>Date de naissance</th>
        <th>Titre</th>
      </tr>
    </thead>
    <ng-container *ngFor="let traveler of travelerRecord.travelerInfos">
      <tbody>
        <tr>
          <td>{{ traveler.lastName }}</td>
          <td>{{ traveler.firstName }}</td>
          <td>{{ dayjs(traveler.birthDate).format('DD/MM/YYYY') }}</td>
          <td>{{ traveler.sex === 'FEMALE' ? 'Mrs' : 'Mr' }}</td>
        </tr>
      </tbody>
    </ng-container>
  </table>
  <div class="col-4"></div>
  <mat-divider> </mat-divider>
  <div class="row w-100 mt-4 mb-2" *ngIf="customerInfo">
    <div class="col-sm-12 background corners">
      <h5><mat-icon>person</mat-icon>Coordonnées de contact :</h5>
    </div>
  </div>
  <div class="row m-2 w-100 mt-2 mb-4" *ngIf="customerInfo">
    <div class="col-sm-1"></div>
    <div class="col-sm-11">
      <mat-card class="w-85 background corners mat-elevation-z4">
        <div class="row w-100 m-2" *ngIf="customerInfo.lastName">
          <div class="col-sm-12">
            <span class="font-weight-bold">Passager associé :</span>
            <br />
            <span>{{ customerInfo.title }} {{ customerInfo.firstName }} {{ customerInfo.lastName }}</span>
          </div>
        </div>
        <div class="row w-100 m-2" *ngIf="!customerInfo.lastName">
          <div class="col-sm-12">
            <span class="font-weight-bold">Information non disponible</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="row w-100 mt-4" *ngIf="customerInfo.address || customerInfo.phone || customerInfo.email">
          <div class="col-sm-6" *ngIf="customerInfo.address">
            <span class="font-weight-bold">Addresse :</span>
            <br />
            <span style="white-space: pre-line">
              {{ customerInfo.address.street }} {{ customerInfo.address.zipCode }} {{ customerInfo.address.city }}
            </span>
          </div>
          <div class="col-sm-3" *ngIf="customerInfo.phone">
            <span class="font-weight-bold">
              <mat-icon>call</mat-icon>Téléphone :
            </span>
            <br />
            <span style="white-space: pre-line">
              {{ customerInfo.phone.telephone || 'NC' }}
            </span>
          </div>
          <div class="col-sm-3" *ngIf="customerInfo.email">
            <span class="font-weight-bold">
              <mat-icon>mail_outline</mat-icon>Email :
            </span>
            <br />
            <span style="white-space: pre-line">{{ customerInfo.email || 'NC'}}
            </span>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-sm-1"></div>
  </div>
</div>