import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from '../api.service.abstract';
import {
  NewAccommodation,
  NewAccommodationRewired,
  rewireNewAccommodation,
  unwireAccommodationRewired,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NewAccommodationService extends ApiService<NewAccommodation> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'accommodation/api/accommodations', routeReuse);
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:8088';
    }
  }

  //TODO remove when backedn changed
  public getRewired(
    id: number | string
  ): Observable<HttpResponse<NewAccommodationRewired>> {
    return this.http
      .get<NewAccommodation>(`${this.url}/${this.endpoint}/${id}`, {
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const res = new HttpResponse<NewAccommodationRewired>({
            body: rewireNewAccommodation(response.body),
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
            url: response.url || undefined,
          });
          return res;
        })
      );
  }

  public updateRewired(
    id: number | string,
    data: NewAccommodationRewired,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<NewAccommodationRewired>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    const newAccommodation = unwireAccommodationRewired(data);
    return this.http
      .put<NewAccommodation>(
        `${this.url}/${this.endpoint}/${id}`,
        newAccommodation,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((response) => {
          const res = new HttpResponse<NewAccommodationRewired>({
            body: rewireNewAccommodation(response.body),
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
            url: response.url || undefined,
          });
          return res;
        })
      );
  }

  public createRewired(
    data: NewAccommodationRewired,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<NewAccommodationRewired>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    const newAccommodation = unwireAccommodationRewired(data);
    return this.http
      .post<NewAccommodation>(
        `${this.url}/${this.endpoint}`,
        newAccommodation,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((response) => {
          const res = new HttpResponse<NewAccommodationRewired>({
            body: rewireNewAccommodation(response.body),
            headers: response.headers,
            status: response.status,
            statusText: response.statusText,
            url: response.url || undefined,
          });
          return res;
        })
      );
  }
}
