import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Tag } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TagService extends ApiService<Tag> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/product-tags', routeReuse);
  }

  search(keyword: string): Observable<HttpResponse<Tag[]>> {
    return this.http.get<Tag[]>(
      `${this.url}/${this.endpoint}/search?keyword=${keyword}`,
      {
        observe: 'response',
      }
    );
  }

  getByProduct(productId: number): Observable<HttpResponse<Tag[]>> {
    return this.http.get<Tag[]>(
      `${this.url}/${this.endpoint}/product/${productId}`,
      {
        observe: 'response',
      }
    );
  }

  exists(name: string): Observable<HttpResponse<boolean>> {
    return this.http.get<boolean>(
      `${this.url}/${this.endpoint}/exists-by-name?product-tag-name=${name}`,
      {
        observe: 'response',
      }
    );
  }

  updateByProduct(
    productId: number,
    tagIds: number[],
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Tag[]>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.put<Tag[]>(
      `${this.url}/${this.endpoint}/product/${productId}`,
      tagIds,
      {
        observe: 'response',
      }
    );
  }

  createByProduct(
    productId: number,
    tagId: number,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Tag[]>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.patch<Tag[]>(
      `${this.url}/${this.endpoint}/product/add-product-tag/${productId}?tag-id=${tagId}`,
      {},
      {
        observe: 'response',
      }
    );
  }

  deleteByProduct(
    productId: number,
    tagId: number,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Tag[]>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.patch<Tag[]>(
      `${this.url}/${this.endpoint}/product/delete-product-tag/${productId}?tag-id=${tagId}`,
      {},
      {
        observe: 'response',
      }
    );
  }
}
