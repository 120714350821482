import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';
import { Picture, PictureTypeEnum } from '@pulpo/pulpo-models';

@Injectable({ providedIn: 'root' })
export class PictureService extends ApiService<Picture> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/pictures');
  }

  getFirstPicture(id: number): Observable<HttpResponse<Picture>> {
    return this.http.get<Picture>(
      `${this.url}/${this.endpoint}/first?productId=${id}`,
      { observe: 'response' }
    );
  }

  getAllPictures(id: number): Observable<HttpResponse<Picture[]>> {
    return this.http.get<Picture[]>(
      `${this.url}/${this.endpoint}?productId=${id}`,
      { observe: 'response' }
    );
  }

  override updateAll(pictures: Picture[]): Observable<HttpResponse<Picture[]>> {
    return this.http.put<Picture[]>(
      `${this.url}/${this.endpoint}/all`,
      pictures,
      {
        observe: 'response',
      }
    );
  }

  getPicturesByTypeAndId(
    type: PictureTypeEnum,
    id: number
  ): Observable<HttpResponse<Picture[]>> {
    return this.http.get<Picture[]>(
      `${this.url}/${this.endpoint}/element?elementId=${id}&type=${type}`,
      { observe: 'response' }
    );
  }

  uploadUserPicture(
    id: number,
    file: File
  ): Observable<HttpResponse<Picture[]>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Picture[]>(
      `${this.url}/${this.endpoint}/upload/user?userId=${id}`,
      formData,
      { observe: 'response' }
    );
  }

  getAllOptimisedPictures(id: number): Observable<HttpResponse<Picture[]>> {
    return this.http.get<Picture[]>(
      `${this.url}/${this.endpoint}/light?productId=${id}`,
      { observe: 'response' }
    );
  }

  uploadCompanyPicture(
    id: number,
    file: File
  ): Observable<HttpResponse<Picture[]>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<Picture[]>(
      `${this.url}/${this.endpoint}/upload/company?companyId=${id}`,
      formData,
      { observe: 'response' }
    );
  }

  findUserPicture(id: number): Observable<HttpResponse<Picture>> {
    return this.http.get<Picture>(
      `${this.url}/${this.endpoint}/user?userId=${id}`,
      {
        observe: 'response',
      }
    );
  }
}
