import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import jwt_decode from 'jwt-decode';
import { Company, Role, User } from '@pulpo/pulpo-models';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor(private sessionStorage: SessionStorageService) {}

  clear() {
    console.log('clean');
    this.sessionStorage.clear();
  }

  public getCompany(): Company {
    const user = this.getUser();
    return user ? user.company : null;
  }

  public setCompany(company: Company) {
    const user = this.getUser();
    if (user) {
      user.company = company;
      this.setUser(user);
    }
  }

  public setToken(token: string) {
    this.sessionStorage.store('authenticationToken', token);
  }

  public getRole(): Role | null {
    const user: User = this.getUser();
    return user ? user.role : null;
  }

  public getToken() {
    return this.sessionStorage.retrieve('authenticationToken');
  }

  public getRefreshToken() {
    return this.sessionStorage.retrieve('refreshToken');
  }

  public setRefreshToken(refreshToken: string) {
    this.sessionStorage.store('refreshToken', refreshToken);
  }

  public setUser(user: User) {
    this.sessionStorage.store('user', user);
  }

  public getUser(): User {
    return this.sessionStorage.retrieve('user');
  }

  public getTokenExpirationTime() {
    const token = this.getToken();
    if (token) {
      const decodedToken: any = jwt_decode(token);
      return decodedToken?.exp;
    } else {
      return null;
    }
  }

  public getRefreshTokenExpirationTime() {
    const refreshToken = this.getRefreshToken();
    if (refreshToken) {
      const decodedToken: any = jwt_decode(refreshToken);
      return decodedToken?.exp;
    } else {
      return null;
    }
  }

  public setFlightSearch(search: any) {
    this.sessionStorage.store('flightSearch', search);
  }

  public getFlightSearch(): any {
    return this.sessionStorage.retrieve('flightSearch');
  }
}
