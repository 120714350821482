import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@pulpo/app-config';
import {
  DailyReport,
  ProducerWeeklyReport,
  StepReport,
  StepReportContent,
} from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StepReportService extends ApiService<StepReport> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'booking/api/step-reports');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8086';
    }
  }

  getWeekReportByProducer(): Observable<ProducerWeeklyReport> {
    return this.http.get<ProducerWeeklyReport>(
      `${this.url}/${this.endpoint}/producer`
    );
  }

  getWeekReport(): Observable<HttpResponse<{ [key: string]: DailyReport }[]>> {
    return this.http.get<{ [key: string]: DailyReport }[]>(
      `${this.url}/${this.endpoint}/daily`,
      { observe: 'response' }
    );
  }

  getContent(id: string): Observable<HttpResponse<StepReportContent>> {
    return this.http.get<StepReportContent>(
      `${this.url}/${this.endpoint}/content/${id}`,
      { observe: 'response' }
    );
  }
}
