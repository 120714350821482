import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import ApiService from '../api.service.abstract';
import { FlightProviderSettings } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class FlightProviderSettingsService extends ApiService<FlightProviderSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'flight/api/flight-provider-settings');
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:4300';
    }
  }

  findByCompanyId(
    companyId: number
  ): Observable<HttpResponse<FlightProviderSettings>> {
    return this.http.get<FlightProviderSettings>(
      `${this.url}/${this.endpoint}/getOrCreate?companyId=${companyId}`,
      {
        observe: 'response',
      }
    );
  }
}
