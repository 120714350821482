<table mat-table [dataSource]="dataSource" class="w-100 h-100 table-wrapper" data-testid="stockTag-list">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let stockTag">{{ stockTag.date | date: 'dd/MM/YYYY' }}</td>
  </ng-container>
  <ng-container matColumnDef="stockReference">
    <th mat-header-cell *matHeaderCellDef>Référence du stock</th>
    <td mat-cell *matCellDef="let stockTag">{{ stockTag.stockReference}}</td>
  </ng-container>
  <ng-container matColumnDef="productReferences">
    <th mat-header-cell *matHeaderCellDef>Références des produits concernés</th>
    <td mat-cell *matCellDef="let stockTag">{{ stockTag.productReferences }}</td>
  </ng-container>
  <ng-container matColumnDef="relatedDateList">
    <th mat-header-cell *matHeaderCellDef>Dates concernées</th>
    <td mat-cell *matCellDef="let stockTag">{{ stockTag.relatedDateList | StockTagDateListPipe }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr mat-row *matNoDataRow>
    <td *ngIf="dataSource.data?.length === 0" class="w-100">Aucune réservation à afficher</td>
  </tr>
</table>