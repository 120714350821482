import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AccommodationProviderSettings } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccommodationProviderSettingsService extends ApiService<AccommodationProviderSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(
      environment,
      http,
      'accommodation/api/accommodation-provider-settings'
    );
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8088';
    }
  }

  public getByCompany(): Observable<
    HttpResponse<AccommodationProviderSettings>
  > {
    return this.http.get<AccommodationProviderSettings>(
      `${this.url}/${this.endpoint}/by-company`,
      {
        observe: 'response',
      }
    );
  }

  public override updateAll(settings: AccommodationProviderSettings[]) {
    return this.http.post<AccommodationProviderSettings[]>(
      `${this.url}/${this.endpoint}`,
      settings,
      {
        observe: 'response',
      }
    );
  }
}
