import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { PulpoLocation } from '@pulpo/pulpo-models';
import { Icon, Map, Marker, latLng, marker, tileLayer } from 'leaflet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationService } from '../../../services';

Icon.Default.imagePath = 'assets/';
@Component({
  selector: 'pulpo-map',
  templateUrl: './pulpo-map.component.html',
  styleUrls: ['./pulpo-map.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LeafletModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
  ],
})
export class PulpoMapComponent {
  search = '';
  searchTimeout: any;
  map!: Map;
  noResult = false;
  foundLocations: PulpoLocation[] = [];
  private locations: PulpoLocation[] = [];
  @Input() addMode = false;
  @Input() deleteMode = false;
  @Output() newLocation: EventEmitter<PulpoLocation> =
    new EventEmitter<PulpoLocation>();
  @Output() deleteLocation: EventEmitter<PulpoLocation> =
    new EventEmitter<PulpoLocation>();
  @Input() set pulpoLocations(locations: PulpoLocation[]) {
    console.log('New locations : ', locations);
    this.locations = locations || [];
    if (this.map) {
      console.log('Refreshing markers');
      this.refreshMarkers();
    }
  }
  @Input() set forceRefresh(_forceRefresh: boolean) {
    console.log('Force refreshing markers');
    this.refreshMarkers();
  }
  @Input()
  countryName!: string;
  markers: Marker[] = [];
  options = {
    layers: [
      tileLayer(
        'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
        {
          maxZoom: 20,
          attribution:
            '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        }
      ),
    ],
    zoom: 14,
    center: latLng(46.778186, 6.641524),
  };

  constructor(
    private httpClient: HttpClient,
    private locationService: LocationService
  ) {}

  refreshMarkers() {
    this.markers.forEach((marker) => marker.remove());
    this.locations.forEach((location) => {
      const marker = this.generateMarker({
        position: { lat: location.latitude, lng: location.longitude },
        draggable: false,
      });
      const addedMarker = marker.addTo(this.map);

      if (this.deleteMode) {
        addedMarker
          .bindPopup(
            `<b>${location.address}</b><br><br><div style="display:flex; justify-content: end"><button class="marker-add-button btn-red">Supprimer</button></div>`
          )
          .on('popupopen', (a: any) => {
            console.log(a);
            const popUp = a.target.getPopup();
            console.log(popUp.getElement().querySelector('.btn-red'));
            popUp
              .getElement()
              .querySelector('.btn-red')
              .addEventListener('click', (e: any) => {
                console.log('delete', e, location);
                this.deleteLocation.emit(location);
              });
          });
      } else {
        addedMarker.bindPopup(`<b>${location.address}</b>`);
      }
      this.markers.push(marker);
    });
    if (this.markers.length > 0) {
      this.map.panTo(this.markers[this.markers.length - 1].getLatLng());
      setTimeout(() => {
        this.markers[this.markers.length - 1].openPopup();
      }, 1000);
    }
  }

  generateMarker(data: any) {
    return marker(data.position, { draggable: data.draggable });
  }

  onMapReady($event: Map) {
    this.map = $event;
    this.refreshMarkers();
  }

  mapClicked($event: any) {
    if (this.addMode) {
      this.reverseGeocode($event.latlng.lat, $event.latlng.lng).subscribe(
        (pulpoLocation: PulpoLocation) => {
          this.map.panTo($event.latlng);
          this.addMarker(pulpoLocation);
          this.markers[this.markers.length - 1].openPopup();
        }
      );
    }
  }

  searchAddress(address: string) {
    if (this.foundLocations?.length > 0) {
      this.foundLocations = [];
    }
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.geocode(address).subscribe((locations: PulpoLocation[]) => {
        this.foundLocations = locations;
      });
    }, 500);
  }

  selectLocation(event: any) {
    const location = event.option.value;
    this.search = '';
    this.refreshMarkers();
    this.map.panTo(
      latLng(parseFloat(location.latitude), parseFloat(location.longitude))
    );
    this.addMarker(location);

    //this.markers[this.markers.length - 1].openPopup();
  }

  addMarker(location: PulpoLocation) {
    const marker = this.generateMarker({
      position: { lat: location.latitude, lng: location.longitude },
      draggable: false,
    });
    marker
      .addTo(this.map)
      .bindPopup(
        `<b>${location.address}</b><br><br><div style="display:flex; justify-content: end"><button class="marker-add-button btn-pulpo">Ajouter</button></div>`
      )
      .on('popupopen', (a) => {
        console.log(a);
        const popUp = a.target.getPopup();
        console.log(popUp.getElement().querySelector('.btn-pulpo'));
        popUp
          .getElement()
          .querySelector('.btn-pulpo')
          .addEventListener('click', (e: any) => {
            console.log('add', e, location);
            this.saveLocation(location, marker);
          });
      });
    this.markers.push(marker);
  }

  saveLocation($event: any, marker: Marker) {
    this.newLocation.emit($event);
    marker.closePopup();
  }

  geocode(address: string): Observable<PulpoLocation[]> {
    if (this.countryName && this.countryName != '') {
      address = address + ', ' + this.countryName;
    }
    return this.locationService.geocode(address).pipe(
      map((result: any) => {
        if (result.length === 0) {
          this.noResult = true;
          setTimeout(() => {
            this.noResult = false;
          }, 3000);
          //throw new Error(`Unable to geocode address: ${address}`);
          return [];
        }
        return result.map((r: any) => {
          const pulpoLocation: PulpoLocation = {
            address: r.display_name,
            latitude: r.lat,
            longitude: r.lon,
          };
          return pulpoLocation;
        });
      })
    );
  }

  reverseGeocode(lat: any, long: any) {
    return this.httpClient
      .get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=jsonv2`
      )
      .pipe(
        map((result: any) => {
          if (!result || result.error) {
            this.noResult = true;
            setTimeout(() => {
              this.noResult = false;
            }, 3000);
            //throw new Error(`Unable to geocode address: ${lat}, ${long}`);
            return {
              address: 'Adresse inconnue',
              latitude: lat,
              longitude: long,
            };
          }

          const pulpoLocation: PulpoLocation = {
            address: result.display_name,
            latitude: result.lat,
            longitude: result.lon,
          };

          return pulpoLocation;
        })
      );
  }
}
