import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { BookingService } from '@pulpo/pulpo-api';
import { Booking, BookingStatusEnum } from '@pulpo/pulpo-models';
import dayjs from 'dayjs';

@Component({
  selector: 'booking-consult-status-dialog',
  templateUrl: './booking-consult-status-dialog.component.html',
  styleUrls: ['./booking-consult-status-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule],
})
export class BookingConsultStatusDialogComponent implements OnInit {
  booking?: Booking;
  message?: string;
  constructor(
    public dialogRef: MatDialogRef<BookingConsultStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { booking: Booking },
    protected bookingService: BookingService
  ) {}

  ngOnInit() {
    this.booking = this.data.booking;
    if (this.booking.status === BookingStatusEnum.VALIDATED) {
      this.message = 'Souhaitez vous annuler cette réservation ?';
    }
    if (this.booking.status === BookingStatusEnum.PENDING) {
      this.message = 'Souhaitez vous confirmer cette réservation ?';
    }
    if (this.booking.status === BookingStatusEnum.CANCELLED) {
      this.message = 'Souhaitez vous réactiver cette réservation ?';
    }
  }

  changeStatus() {
    if (this.booking) {
      if (this.booking.status === BookingStatusEnum.VALIDATED) {
        this.booking.status = BookingStatusEnum.CANCELLED;
      } else if (this.booking.status === BookingStatusEnum.PENDING) {
        this.booking.status = BookingStatusEnum.VALIDATED;
      } else if (this.booking.status === BookingStatusEnum.CANCELLED) {
        this.booking.status = BookingStatusEnum.VALIDATED;
      }
      this.booking.lastUpdateDate = dayjs();
      this.bookingService
        .update(this.booking, ['booking'])
        .subscribe((bookingRes) => {
          if (bookingRes.status === 200) {
            this.dialogRef.close(1);
          } else {
            this.dialogRef.close(-1);
          }
        });
    }
  }

  dismissDialog() {
    this.dialogRef.close(0);
  }
}
