import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationError } from '@angular/router';
import { UpdateService } from '@pulpo/pulpo-commons';
import { LoadingService } from 'libs/pulpo-commons/src/lib/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    public loadingService: LoadingService,
    private renderer: Renderer2,
    private updateService: UpdateService
  ) {}

  ngOnInit() {
    this.updateService.setup();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationError && event.error.status === 404) {
        this.router.navigate(['/404']);
      }
    });

    this.loadingService.isLoading.subscribe((isLoading) => {
      if (isLoading) {
        this.renderer.addClass(document.body, 'no-scroll');
      } else {
        this.renderer.removeClass(document.body, 'no-scroll');
      }
    });
  }
}
