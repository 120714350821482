import { Company } from '../company';

export class ProducerSettings {
  id!: number;
  company!: Company;
  timezoneJson!: string;
  message!: string;
  timezoneStore!: TimezoneStore;
}

export class TimezoneStore {
  timezones!: any[];
}

export class TimezoneBinding {
  cityId!: number;
  countryId!: number;
  countryName!: string;
  cityName!: string;
  timezone!: string;
  timezoneName!: string;
}
