<div class="wrapper">
    <div class="position">
        <div class="clock-ctn position">
            <div class="markers position cardinal" id="m-12-6"></div>
            <div class="markers position" id="m-1-7"></div>
            <div class="markers position" id="m-2-8"></div>
            <div class="markers position cardinal" id="m-3-9"></div>
            <div class="markers position" id="m-4-10"></div>
            <div class="markers position" id="m-5-11"></div>
            <div class="clock-face position">
                <div class="clock-hand position hour" [style.transform]="transformHours"></div>
                <div class="clock-hand position minute" [style.transform]="transformMins"></div>
                <!-- <div class="clock-hand position second" [style.transform]="transformSecs"></div> -->
                <div class="dot position"></div>
            </div>
        </div>
    </div>
</div>
<h6 class="text-center" *ngIf="currentDate && timezone">{{timezone.cityName}} : {{currentDate | date: 'dd MMM'}}</h6>