<table class="table table-striped">
  <thead>
    <tr>
      <th>Source</th>
      <th>Référence</th>
      <th></th>
      <th>Prix de base</th>
      <th>Supplément</th>
      <th>Total</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{ carRentalRecord.carRentalProvider }}
      </td>
      <td>
        {{ carRentalRecord.carRentalBookingReference }}
      </td>
      <td>
      </td>
      <td>
        {{
        carRentalRecord.carRentalReferencePrice
        ? (!carRentalRecord.carRentalMandatory
        ? (carRentalRecord.carRentalReferencePrice.toFixed(2)+ ' €'): ' inclus '): ' - '
        }}
      </td>
      <td>
        {{
        carRentalRecord.carRentalSupplement
        ? (!carRentalRecord.carRentalMandatory
        ? (carRentalRecord.carRentalSupplement.toFixed(2)+ ' €'): ' inclus '): ' - '
        }}
      </td>
      <td>
        {{
        carRentalRecord.carRentalTotalPrice
        ? (!carRentalRecord.carRentalMandatory
        ? (carRentalRecord.carRentalTotalPrice.toFixed(2)+ ' €'): ' inclus '): ''
        }}
      </td>
    </tr>
  </tbody>
</table>