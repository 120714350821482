import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterModule } from '@angular/router';
import { FlightBooking } from '@pulpo/pulpo-models';
import { FlightBookingService } from '@pulpo/pulpo-api';
import { FlightBookingConsultFilterComponent } from '../flight-booking-consult-filter/flight-booking-consult-filter.component';
import { CommonModule, DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
@Component({
  selector: 'flight-booking-consult-list',
  templateUrl: './flight-booking-consult-list.component.html',
  styleUrls: ['./flight-booking-consult-list.component.scss'],
  standalone: true,
  imports: [
    FlightBookingConsultFilterComponent,
    MatTableModule,
    DatePipe,
    MatProgressSpinnerModule,
    MatIconModule,
    MatPaginatorModule,
    RouterModule,
    MatFormFieldModule,
    CommonModule,
  ],
})
export class FlightBookingConsultListComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'reference',
    'originCity',
    'destinationCity',
    'pax',
    'firstPassenger',
    'creationDate',
    'channel',
    'supplierName',
    'source',
    'totalTtc',
    'options',
    'consult',
  ];
  dataSource!: MatTableDataSource<FlightBooking>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  filter: any = {};
  @Input() itemsPerPage = 20;
  page!: number;
  loading = true;
  nbFlightBooking!: number;

  constructor(
    private flightBookingService: FlightBookingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource();
    this.page = 0;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.refreshFlightBookings();
  }

  private refreshFlightBookings() {
    this.loading = true;
    const sort = this.sort && this.sort.active ? this.sort.active : 'id';
    const order =
      this.sort && this.sort.direction ? this.sort.direction : 'desc';
    this.flightBookingService
      .queryPage({
        page: this.page,
        size: this.itemsPerPage,
        sort: sort,
        direction: order,
        ...this.filter,
      })
      .subscribe(
        (res) => {
          this.dataSource = new MatTableDataSource(res.body?.content);
          this.nbFlightBooking = res.body?.totalElements || 0;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
  }

  changePage() {
    this.page = this.paginator.pageIndex;
    this.refreshFlightBookings();
  }

  sortChanged() {
    this.page = 0;
    this.paginator.pageIndex = 0;
    this.refreshFlightBookings();
  }

  applyFilter(filter: any) {
    this.page = 0;
    this.paginator.pageIndex = 0;
    this.filter = {};

    for (let key in filter) {
      if (filter[key]) {
        if (key == 'past' && filter[key] != null) {
          this.filter[key] = filter[key];
        } else {
          this.filter[key] = filter[key];
        }
      }
    }
    this.refreshFlightBookings();
  }

  openFlightBookingDetails(id: string) {
    this.router.navigate(['/flight-booking-consult', id]);
  }

  getFirstPassengerName(flightBooking: FlightBooking) {
    const passengers = JSON.parse(flightBooking.passengerJson);
    return passengers[0]?.lastName ? passengers[0]?.lastName : '';
  }

  getPax(flightBooking: FlightBooking) {
    const passengers = JSON.parse(flightBooking.passengerJson);
    return passengers.length;
  }
}
