<div class="table-header row">
    <div class="col-sm-9">
        <h4 class="m-4">
            Ajouter une vente spéciale
        </h4>
    </div>

    <div class="col-sm-3 d-flex justify-content-end">
        <button [disabled]="editForm.invalid" mat-raised-button class="m-4 btn-pulpo" (click)="save()">
            <mat-icon>save</mat-icon> Valider
        </button>
    </div>
</div>
<form name="productAccommodationForm" role="form" [formGroup]="editForm"
    class="d-flex flex-column justify-content-between align-items-center">
    <div class="w-100 d-flex flex-row justify-content-center">
        <mat-form-field appearance="outline" class="m-4 w-50">
            <mat-label>Adresse mail du passager</mat-label>
            <input matInput placeholder="mail" type="text" class="ps-2" formControlName="customerMail" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="m-4 w-20">
            <mat-label>Du</mat-label>
            <input matInput [matDatepicker]="picker" class="ps-2" formControlName="startDate" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="m-4 w-20">
            <mat-label>Au</mat-label>
            <input matInput [matDatepicker]="picker2" class="ps-2" formControlName="endDate" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="w-100 d-flex flex-row justify-content-center">
        <mat-form-field appearance="outline" class="m-4 w-30">
            <mat-label>Acompte</mat-label>
            <input matInput placeholder="Acompte" type="number" class="ps-2" formControlName="deposit" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="m-4 w-30">
            <mat-label>Jours avant départ</mat-label>
            <input matInput placeholder="Nombre de jour" type="number" class="ps-2"
                formControlName="daysBeforeDeparture" />
        </mat-form-field>
    </div>
</form>