import { Inject, Injectable } from '@angular/core';
import { EnumOptionStatus, Option, OptionPrice } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OptionPriceService extends ApiService<OptionPrice> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'option/api/price', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8087';
    }
  }

  getMonthlyPrices(request: {
    month: number;
    year: number;
    id: string;
  }): Observable<HttpResponse<OptionPrice[]>> {
    return this.http.get<OptionPrice[]>(
      `${this.url}/${this.endpoint}/monthly`,
      { params: request, observe: 'response' }
    );
  }

  updateDateRange(
    id: string,
    request: {
      startDate: string;
      endDate: string;
      status: EnumOptionStatus;
      unitPrice?: number;
      adultPrice?: number;
      childPrice?: number;
      babyPrice?: number;
    }
  ): Observable<HttpResponse<Option>> {
    return this.http.post<Option>(
      `${this.url}/${this.endpoint}/updateRange/${id}`,
      request,
      { observe: 'response' }
    );
  }

  updateDates(
    id: string,
    prices: OptionPrice[]
  ): Observable<HttpResponse<Option>> {
    return this.http.post<Option>(
      `${this.url}/${this.endpoint}/updateDates/${id}`,
      prices,
      { observe: 'response' }
    );
  }
}
