import { AccommodationCache } from './accommodation-cache.model';

export class AccommodationWHCache extends AccommodationCache {
  propertyCode: string;
  roomCode: string;
  roomCategory: string;
  rateId: number;
  boardWH: string;
  boardName: string;
}
