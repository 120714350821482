import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import ApiService from './api.service.abstract';
import { ProducerSettings, TimezoneStore } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';

@Injectable({ providedIn: 'root' })
export class ProducerSettingsService extends ApiService<ProducerSettings> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/producer-settings');
  }

  findByCompanyId(
    companyId: number
  ): Observable<HttpResponse<ProducerSettings>> {
    return this.http.get<ProducerSettings>(
      `${this.url}/${this.endpoint}/getOrCreate?companyId=${companyId}`,
      { observe: 'response' }
    );
  }

  getAllTimezones(): Observable<HttpResponse<TimezoneStore>> {
    return this.http.get<TimezoneStore>(
      `${this.url}/${this.endpoint}/timezones`,
      { observe: 'response' }
    );
  }
}
