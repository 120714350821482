import { Inject, Injectable } from '@angular/core';
import { Option } from '@pulpo/pulpo-models';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OptionService extends ApiService<Option> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'option/api', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8087';
    }
  }

  getByReference(reference: string): Observable<HttpResponse<Option | null>> {
    return this.http.get<Option>(`${this.url}/${this.endpoint}`, {
      params: { reference },
      observe: 'response',
    });
  }

  duplicate(
    id: string,
    option: Option,
    keepPrice: boolean
  ): Observable<HttpResponse<Option>> {
    return this.http.post<Option>(
      `${this.url}/${this.endpoint}/duplicate/${id}?keepPrice=${keepPrice}`,
      { ...option },
      { observe: 'response' }
    );
  }
}
