import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ApiService from '../api.service.abstract';
import {
  Accommodation,
  AccommodationImport,
  AccommodationMeal,
  AccommodationRoomCategory,
} from '@pulpo/pulpo-models';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '@pulpo/app-config';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AccommodationService extends ApiService<Accommodation> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'accom/api/accommodation', routeReuse);
    if (environment.name == 'localDev') {
      this.url = 'http://localhost:9090';
    }
  }

  public getAllRefs(companyId: number): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(
      `${this.url}/${this.endpoint}/ref?companyId=${companyId}`,
      {
        observe: 'response',
      }
    );
  }

  public import(
    importItem: AccommodationImport,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Accommodation>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.post<Accommodation>(
      `${this.url}/${this.endpoint}/import`,
      importItem,
      {
        observe: 'response',
      }
    );
  }

  override update(
    item: Accommodation,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Accommodation>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.put<Accommodation>(
      `${this.url}/${this.endpoint}/${item.id}`,
      item,
      {
        observe: 'response',
      }
    );
  }

  queryHotelPage(
    req?: any
  ): Observable<HttpResponse<{ content: any[]; totalElements: number }>> {
    const options = this.createRequestOption({
      ...req,
    });
    return this.http.get<any>(`${this.url}/${this.endpoint}/hotelbeds`, {
      params: options,
      observe: 'response',
    });
  }

  getRemainingRoomCategories(
    id: string
  ): Observable<HttpResponse<AccommodationRoomCategory[]>> {
    return this.http.get<AccommodationRoomCategory[]>(
      `${this.url}/${this.endpoint}/hotelbeds/rooms/${id}`,
      {
        observe: 'response',
      }
    );
  }

  getRemainingMealPlans(
    id: string
  ): Observable<HttpResponse<AccommodationMeal[]>> {
    return this.http.get<AccommodationMeal[]>(
      `${this.url}/${this.endpoint}/hotelbeds/meals/${id}`,
      {
        observe: 'response',
      }
    );
  }
}
