import { Product } from './product.model';
import {DescriptionType} from "./enums/description-type.model";

export interface ProductDescription {
  productId?: number;
  id?: number;
  title?: string;
  text?: any;
  rank?: number;
  type?: DescriptionType;
  product?: Product;
}

export class ProductDescription implements ProductDescription {
  constructor(
    public rank?: number,
    public id?: number,
    public title?: string,
    public productId?: number,
    public text?: any,
    public product?: Product
  ) {}
}
