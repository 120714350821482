export interface IExcelImportMapping {
  id?: number;
  idCompany?: number;
  activated?: string;
  firstRowNumber?: number;
  date?: string;
  doublePrice?: string;
  singlePrice?: string;
  triplePrice?: string;
  quadruplePrice?: string;
  childOnePrice?: string;
  childrenTwoPrice?: string;
  babiesPrice?: string;
  toutActiver?: boolean;
  formatDate?: Date | string;
  excelImportPolicy?: ExcelImportPolicyEnum;

  autoActivateColumn?: boolean
}

export class ExcelColumns {
  public activated: boolean = true;
  public date: string = '';
  public doublePrice: string = '';
  public singlePrice: string = '';
  public triplePrice: string = '';
  public quadruplePrice: string = '';
  public childOnePrice: string = '';
  public childrenTwoPrice: string = '';
  public babiesPrice: string = '';
}

export enum ExcelImportPolicyEnum {
  REPLACE = 'REPLACE',
  INSERT_WITHOUT_DELETE = 'INSERT_WITHOUT_DELETE',
  INSERT_MODIFY_DATA = 'INSERT_MODIFY_DATA',
  REPLACE_TARIFF_ONLY = 'REPLACE_TARIFF_ONLY',
  REPLACE_AVAILABILITY_ONLY = 'REPLACE_AVAILABILITY_ONLY',
  REPLACE_AVAILABILITY_TARIFF = 'REPLACE_AVAILABILITY_TARIFF',
}

export class ExcelImportMapping implements IExcelImportMapping {
  constructor(
    public id?: number,
    public idCompany?: number,
    public toutActiver?: boolean,
    public activated?: string,
    public firstRowNumber?: number,
    public date?: string,
    public doublePrice?: string,
    public singlePrice?: string,
    public triplePrice?: string,
    public quadruplePrice?: string,
    public childOnePrice?: string,
    public childrenTwoPrice?: string,
    public babiesPrice?: string,
    public formatDate?: Date | string,
    public excelImportPolicy?: ExcelImportPolicyEnum,

    public autoActivateColumn?: boolean
  ) {}
}
