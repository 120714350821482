import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { CacheBucket } from '@ngneat/cashew';
import ApiService from './api.service.abstract';
import {
  Booking,
  ILightBooking,
  IBooking,
  DATE_FORMAT,
} from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import dayjs from 'dayjs';
import { RouteReuseStrategy } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BookingService extends ApiService<Booking> {
  bookingBucket = new CacheBucket();

  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'api/bookings', routeReuse);
  }

  consult(
    id: number,
    routesToClear: string[] | undefined = undefined
  ): Observable<HttpResponse<Booking>> {
    if (routesToClear) {
      this.clearRouteReuseStrategy(routesToClear);
    }
    return this.http.get<Booking>(
      `${this.url}/${this.endpoint}/consult/${id}`,
      { observe: 'response' }
    );
  }

  resendConfirmation(id: number): Observable<HttpResponse<Booking>> {
    return this.http.get<Booking>(
      `${this.url}/${this.endpoint}/confirm/${id}`,
      { observe: 'response' }
    );
  }

  querySupplierPage(req: any): Observable<HttpResponse<any>> {
    const options = this.createRequestOption(req);
    return this.http.get<any>(`${this.url}/${this.endpoint}/pageBySupplier`, {
      params: options,
      observe: 'response',
    });
  }

  findAllForStock(stockId: number): Observable<HttpResponse<Booking[]>> {
    return this.http
      .get<Booking[]>(`${this.url}/${this.endpoint}/stock`, {
        params: {
          stockId: stockId,
        },
        observe: 'response',
      })
      .pipe(
        map((res: HttpResponse<Booking[]>) =>
          this.convertDateArrayFromServer(res)
        )
      );
  }

  protected convertDateFromClient(booking: Booking): Booking {
    const copy: Booking = Object.assign({}, booking, {
      creationDate:
        booking.creationDate != null && booking.creationDate.isValid()
          ? booking.creationDate.format(DATE_FORMAT)
          : null,
      lastUpdateDate:
        booking.lastUpdateDate != null && booking.lastUpdateDate.isValid()
          ? booking.lastUpdateDate.format(DATE_FORMAT)
          : null,
    });
    return copy;
  }

  protected convertDateFromServer(
    res: HttpResponse<Booking>
  ): HttpResponse<Booking> {
    if (res.body) {
      res.body.creationDate = dayjs(res.body.creationDate);
      res.body.lastUpdateDate = dayjs(res.body.lastUpdateDate);
    }
    return res;
  }

  protected convertDateArrayFromServerLight(
    res: HttpResponse<ILightBooking[]>
  ): HttpResponse<ILightBooking[]> {
    if (res.body) {
      res.body.forEach((booking: ILightBooking) => {
        booking.startDate = dayjs(booking.startDate);
        booking.bookingDate = dayjs(booking.bookingDate);
      });
    }
    return res;
  }

  protected convertDateArrayFromServer(
    res: HttpResponse<Booking[]>
  ): HttpResponse<Booking[]> {
    if (res.body) {
      res.body.forEach((booking: IBooking) => {
        booking.creationDate = dayjs(booking.creationDate);
        booking.lastUpdateDate = dayjs(booking.lastUpdateDate);
      });
    }
    return res;
  }
}
