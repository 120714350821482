import { Pipe, PipeTransform } from '@angular/core';
import { TypeOptionEnum } from '@pulpo/pulpo-models';

@Pipe({
  name: 'OptionTypePipe',
  standalone: true,
  pure: true,
})
export class OptionTypePipe implements PipeTransform {
  transform(value: TypeOptionEnum): string {
    switch (value) {
      case TypeOptionEnum.VISIT:
        return 'Visite/Objet';
      case TypeOptionEnum.TRANSFER:
        return 'Transfert';
      case TypeOptionEnum.OTHER:
        return 'Autre';
      default:
        return 'Inconnu';
    }
  }
}
