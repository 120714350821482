<div class="d-flex flex-row justify-content-evenly w-100 h-100">
    <div class="d-flex flex-column w-40 h-75">
        <h6 class="p-2 bold">{{firstTitle}}</h6>
        <mat-selection-list class="bordered" [(ngModel)]="firstSelected" [multiple]="false">
            <mat-list-option *ngFor="let item of firstList" [value]="item">{{item[fieldToShow]}}</mat-list-option>
        </mat-selection-list>
    </div>
    <div class="d-flex flex-column w-10 mt-5 align-items-center">
        <button mat-icon-button matTooltip="Ajouter" (click)="add()" class="btn-pulpo m-4" [disabled]="!firstSelected">
            <mat-icon>chevron_right</mat-icon>
        </button>
        <button mat-icon-button matTooltip="Supprimer" (click)="remove()" class="btn-pulpo m-4" [disabled]="!secondSelected">
            <mat-icon>chevron_left</mat-icon>
        </button>
    </div>
    <div class="d-flex flex-column w-40 ">
        <h6 class="p-2 bold">{{secondTitle}}</h6>
        <mat-selection-list class="bordered" [(ngModel)]="secondSelected" [multiple]="false">
            <mat-list-option *ngFor="let item of secondList" [value]="item">{{item[fieldToShow]}}</mat-list-option>
        </mat-selection-list>
    </div>
</div>