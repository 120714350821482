import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Supplier, SupplierProduct } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';

@Injectable({ providedIn: 'root' })
export class SupplierService extends ApiService<Supplier> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient
  ) {
    super(environment, http, 'api/suppliers');
  }
  findSupplierByCompany(id: number): Observable<HttpResponse<Supplier[]>> {
    return this.http.get<Supplier[]>(
      `${this.url}/${this.endpoint}/findByOwnerCompany/${id}`,
      {
        observe: 'response',
      }
    );
  }

  findSupplierByPulpoId(id: number): Observable<HttpResponse<Supplier[]>> {
    return this.http.get<Supplier[]>(
      `${this.url}/${this.endpoint}/findBySupplierCompany/${id}`,
      { observe: 'response' }
    );
  }

  findSupplierProductBySupplier(
    id: number
  ): Observable<HttpResponse<SupplierProduct[]>> {
    return this.http.get<SupplierProduct[]>(
      `${this.url}/${this.endpoint}/productBySupplier/${id}`,
      { observe: 'response' }
    );
  }
}
