import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { UserRightEnum } from '@pulpo/pulpo-models';
import { RoleService } from '@pulpo/pulpo-api';

export class RoleGuard {
  static forPermissions(
    ...permissions: { name: string; right: UserRightEnum }[]
  ) {
    @Injectable({
      providedIn: 'root',
    })
    class RoleCheck implements CanLoad {
      constructor(public roleService: RoleService) {}
      canLoad(): boolean {
        return this.roleService.hasPermissions(permissions);
      }
    }
    return RoleCheck;
  }
}
