import { AngularEditorConfig } from '@kolkov/angular-editor';
export const pulpoEditorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '200px',
  maxHeight: 'auto',
  width: 'auto',
  minWidth: 'auto',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Veuillez écrire votre texte ici...',
  defaultParagraphSeparator: '',
  defaultFontName: 'Arial',
  defaultFontSize: '2',
  fonts: [
    { class: 'arial', name: 'Arial' },
    { class: 'times-new-roman', name: 'Times New Roman' },
    { class: 'calibri', name: 'Calibri' },
    { class: 'comic-sans-ms', name: 'Comic Sans MS' },
  ],
  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    [
      'underline',
      'strikeThrough',
      'subscript',
      'superscript',
      'justifyLeft',
      'justifyCenter',
      'justifyRight',
      'justifyFull',
      'indent',
      'outdent',
      'heading',
      'customClasses',
      'link',
      'unlink',
      'insertImage',
      'insertVideo',
      'toggleEditorMode',
      'backgroundColor',
      'insertHorizontalRule',
    ],
  ],
};
