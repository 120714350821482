import { Component, Input, OnInit } from '@angular/core';
import { CommissionService } from '@pulpo/pulpo-api';
import {
  Commission,
  IBooking,
  IOldSelection,
  IPulpoFlightOffer,
  IRoom,
  ISegmentPrice,
  ISelection,
} from '@pulpo/pulpo-models';
import { ProductUtils } from '../../../utils/productUtils';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'booking-consult-bill',
  templateUrl: './booking-consult-bill.component.html',
  styleUrls: ['./booking-consult-bill.component.scss'],
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
})
export class BookingConsultBillComponent implements OnInit {
  @Input()
  selection?: IOldSelection;
  @Input()
  newSelection?: ISelection;
  @Input()
  booking?: IBooking;
  optionNumbers: number[] = [];
  optionPrices: ISegmentPrice[] = [];
  adult!: number;
  child!: number;
  baby!: number;
  commissionPrice?: number;
  commissionRealPrice?: string;
  commissionAmount?: number;
  commissionType?: string;
  commission?: Commission;
  totalFlightPrice?: number;
  totalPerson?: number;
  extraFlightCosts?: number;
  rooms?: IRoom[];
  flightOffer?: IPulpoFlightOffer;
  totalBoard?: number;
  selectedBoard?: string;
  numberOfLuggage?: number;
  constructor(protected commissionService: CommissionService) {}

  ngOnInit() {
    if (this.selection) {
      if (this.selection && this.selection.optionInfoList) {
        this.optionNumbers = this.selection.optionInfoList;
      }

      if (this.selection && this.selection.optionPriceList) {
        this.optionNumbers = this.selection.optionPriceList;
      }

      if (this.selection && this.selection.flightPrice) {
        this.totalFlightPrice = Number(this.selection.flightPrice);
        if (this.selection.luggagePrice) {
          this.totalFlightPrice += Number(this.selection.luggagePrice);
        }
      }

      if (
        this.selection &&
        this.selection.commissionPrice &&
        this.selection.commissionAmount
      ) {
        this.commissionPrice = Number(this.selection.commissionPrice);
        this.commissionRealPrice = this.selection.commissionRealPrice || '';
        this.commissionAmount = Number(this.selection.commissionAmount);
        this.commissionType = this.selection.commissionType || '';
      }

      this.adult = 0;
      this.child = 0;
      this.baby = 0;
      this.selection.rooms = JSON.parse(this.booking?.occupationJson || '');
      if (!this.selection.rooms) {
        this.selection.rooms = JSON.parse(
          this.booking?.occupationJson || ''
        ).rooms;
      }

      this.selection.rooms?.forEach((room) => {
        this.adult += room.adult;
        this.child += room.child;
        this.baby += room.baby;
      });

      this.rooms = this.selection.rooms;
      this.totalBoard = this.selection.totalBoard;
      this.selectedBoard = this.selection.board;
      this.totalPerson =
        Number(this.adult) + Number(this.child) + Number(this.baby);
      this.flightOffer = this.selection.flightOffer;
      this.numberOfLuggage = this.selection.numberOfLuggage;

      if (this.selection.flightPrice) {
        this.extraFlightCosts = this.selection.flightPrice;
        if (this.selection.luggagePrice) {
          this.extraFlightCosts -= this.selection.luggagePrice;
        }
      } else {
        this.extraFlightCosts = 0;
      }
    } else {
      if (this.newSelection && this.newSelection?.optionInfoList) {
        this.optionNumbers = this.newSelection?.optionInfoList;
      }

      if (this.newSelection && this.newSelection?.prices?.optionPrices) {
        this.optionNumbers = this.newSelection?.prices?.optionPrices.map(
          (option) => option.price || 0
        );
      }

      if (this.newSelection && this.newSelection?.prices?.flightPrice) {
        this.totalFlightPrice = Number(this.newSelection?.prices?.flightPrice);
        if (this.newSelection?.prices?.luggagePrice) {
          this.totalFlightPrice += Number(
            this.newSelection?.prices?.luggagePrice
          );
        }
      }

      if (
        this.newSelection &&
        this.newSelection?.prices?.totalCommission &&
        this.newSelection?.prices?.commissionPercent
      ) {
        this.commissionPrice = Number(
          this.newSelection?.prices?.totalCommission
        );
        this.commissionRealPrice =
          this.newSelection?.prices?.totalCommission.toFixed(2);
        this.commissionAmount = Number(
          this.newSelection?.prices?.commissionPercent
        );
        this.commissionType = this.newSelection?.prices?.commissionType;
      }

      if (
        this.newSelection &&
        this.newSelection?.prices?.totalCommissionPrice &&
        this.newSelection?.prices?.commissionPercent
      ) {
        this.commissionPrice = Number(
          this.newSelection?.prices?.totalCommissionPrice
        );
        this.commissionRealPrice =
          this.newSelection?.prices?.totalCommissionPrice.toFixed(2);
        this.commissionAmount = Number(
          this.newSelection?.prices?.commissionPercent
        );
        this.commissionType = this.newSelection?.prices?.commissionType;
      }

      this.adult = this.newSelection?.adultNumber || 0;
      this.child = this.newSelection?.childNumber || 0;
      this.baby = this.newSelection?.babyNumber || 0;

      this.rooms = this.newSelection?.rooms;
      this.flightOffer = this.newSelection?.flightOffer;
      this.totalPerson =
        Number(this.newSelection?.adultNumber) +
        Number(this.newSelection?.childNumber) +
        Number(this.newSelection?.babyNumber);
      this.totalBoard = this.newSelection?.prices?.mealPrice;
      this.selectedBoard = this.newSelection?.board;
      this.numberOfLuggage = this.newSelection?.luggageNumber;
      if (this.newSelection?.prices?.flightPrice) {
        this.extraFlightCosts = this.newSelection?.prices?.flightPrice;
      } else {
        this.extraFlightCosts = 0;
      }
    }
  }

  getName(flightOffer: IPulpoFlightOffer) {
    return flightOffer?.way?.segments
      ? flightOffer?.way?.segments[0].origin +
          ' - ' +
          flightOffer.way.segments[0].destination
      : '';
  }

  roundPrice(grandTotal: string) {
    return Math.round(Number(grandTotal));
  }

  parseOptionNumber(optionNumber: any, type: string) {
    if (optionNumber) {
      if (type === 'adult') {
        return Number(optionNumber.toString().charAt(0));
      }
      if (type === 'child') {
        return Number(optionNumber.toString().charAt(1));
      }
      if (type === 'baby') {
        return Number(optionNumber.toString().charAt(2));
      }
    }
    return 0;
  }

  displayPrice() {
    if (this.commissionRealPrice) {
      return Number(
        Number(this.getSelectionPrices()?.totalPrice) -
          Number(this.commissionRealPrice)
      ).toFixed(2);
    } else {
      return Number(
        Number(this.getSelectionPrices()?.totalPrice) -
          Number(this.commissionPrice)
      ).toFixed(2);
    }
  }

  parsePrice(content: any) {
    return Number(content).toFixed(2);
  }

  parseFlightPriceWithoutTax() {
    let price = 0;
    return this.parsePrice(price);
  }

  parseFlightTax() {
    let price = 0;
    if (this.newSelection) {
      if (this.newSelection?.prices?.taxPrice == null) {
        let price =
          Number(this.newSelection?.flightOffer?.total) -
          Number(this.newSelection?.flightOffer?.base);
        if (this.newSelection?.flightOffer?.bagRealPrice) {
          price -= Number(this.newSelection?.flightOffer.bagRealPrice);
        }
        return price.toFixed(2) + ' €';
      } else {
        let price = Number(this.newSelection?.prices?.taxPrice);
        return price.toFixed(2) + ' €';
      }
      return '';
    } else {
      if (this.flightOffer && this.flightOffer.total && this.flightOffer.base) {
        if (this.flightOffer.bagRealPrice) {
          price -= Number(this.flightOffer.bagRealPrice);
        }
        price = Number(this.flightOffer.total) - Number(this.flightOffer.base);
        return price.toFixed(2) + ' €';
      } else {
        return 'N/A';
      }
    }
  }

  parseCategory(category: string) {
    return ProductUtils.translateFromCategory(category);
  }

  getPackagePrice() {
    if (this.isCompatibleFormat()) {
      return this.newSelection?.prices?.packagePrice?.toFixed(2);
    } else {
      if (this.selection) {
        return (
          Number(this.selection.roomPrice) +
          Number(this.selection.referenceFlightPrice)
        ).toFixed(2);
      } else {
        return (
          Number(this.newSelection?.prices?.totalRoomPrice) +
          Number(this.newSelection?.prices?.referenceFlightPrice)
        ).toFixed(2);
      }
    }
  }

  get getSelection(): ISelection | IOldSelection {
    if (this.selection) {
      return this.selection as IOldSelection;
    } else {
      return this.newSelection as ISelection;
    }
  }

  getSelectionPrices() {
    if (this.selection) {
      return this.selection;
    } else {
      return this.newSelection?.prices;
    }
  }

  getRoomPrice(room: IRoom) {
    if (this.selection) {
      return room.total?.toFixed(2) + ' €';
    } else {
      const relatedPrice = this.newSelection?.prices?.roomPrices?.find((rp) =>
        rp.description?.includes(room.type || '')
      );
      if (relatedPrice) {
        return relatedPrice.price?.toFixed(2) + ' €';
      }
    }
    return 'N/A';
  }

  getOptionPrice(id: number, idx: number) {
    if (this.newSelection) {
      const relatedPrice = this.newSelection?.prices?.optionPrices?.find(
        (op) => op.id === id
      );
      if (relatedPrice) {
        return relatedPrice.price?.toFixed(2) + ' €';
      }
    } else if (this.selection?.optionPriceList) {
      return Number(this.selection?.optionPriceList[idx]).toFixed(2) + ' €';
    }
    return 'N/A';
  }

  isCompatibleFormat() {
    return (
      this.newSelection &&
      this.newSelection?.prices?.packagePrice !== null &&
      this.newSelection?.prices?.packagePrice !== undefined
    );
  }

  getOptionCommission(id: number) {
    if (
      this.newSelection &&
      this.newSelection?.prices &&
      this.newSelection?.prices?.segmentCommissions
    ) {
      const optionSegment = this.newSelection?.prices?.segmentCommissions.find(
        (sc) => Number(sc.relatedId) === Number(id)
      );
      if (optionSegment) {
        return optionSegment.amount?.toFixed(2);
      }
    }
    return Number(0).toFixed(2);
  }

  getComissionPrice() {
    if (this.selection) {
      return this.selection.commissionPrice?.toFixed(2);
    }
    if (this.newSelection) {
      if (this.newSelection?.prices?.totalCommissionPrice) {
        return this.newSelection?.prices?.totalCommissionPrice.toFixed(2);
      } else {
        return this.newSelection?.prices?.totalCommission?.toFixed(2);
      }
    }
  }

  getMealPrice() {
    if (this.selection) {
      return this.selection.totalBoard;
    }
    return this.newSelection?.prices.mealPrice
      ? this.newSelection?.prices.mealPrice.toFixed(2) + ' €'
      : 'Inclus';
  }
}
